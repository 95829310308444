import { ColumnFlex, RowFlex, RowGrid, Wrap } from '@ifca-ui/core'
import { PxHumanArrow } from '@ifca-ui/icons/dist/index.esm'
import {
  AccountBalance,
  CalendarMonthRounded,
  MonetizationOnRounded,
  Person,
} from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Tooltip } from '@mui/material'
import IconX from 'components/General/IconX'
import TextX from 'components/General/TextX'
import useAppData from 'components/General/useAppData'
import moment from 'moment'
import PBnumberSquare from './PBnumberSquare'

interface PBexpansionSummaryProps {
  item: any
  haveSubData: boolean
  isPA: boolean
  handleDelete: any
  lastIndex: number
  index: number
}

const PBexpansionSummary: React.FC<PBexpansionSummaryProps> = ({
  item,
  haveSubData,
  isPA,
  handleDelete,
  lastIndex,
  index,
}: PBexpansionSummaryProps) => {
  return (
    <RowFlex fullWidth crossAxisAlignment="center" gap="5px">
      <ColumnFlex gap={'4px'} fullWidth className="text-desc text-icon">
        <RowFlex key="firstLine" mainAxisAlignment="space-between" fullWidth>
          <RowFlex key="left" gap={'4px'}>
            <PBnumberSquare data={item} />
            <TextX className="pbTitle" data={item.paymentPlanDetailTitle} />
          </RowFlex>
          <RowFlex key="right" className="c-orange">
            {item.details[0].isStakeholder && <IconX icon={PxHumanArrow} />}
            <IconX icon={MonetizationOnRounded} />
            <TextX data={item.totalDocumentAmt} dataType="Number" />
          </RowFlex>
        </RowFlex>
        <RowFlex key="secondLine" mainAxisAlignment="space-between" fullWidth>
          {haveSubData ? (
            <div></div>
          ) : (
            <RowFlex key="left">
              <IconX icon={CalendarMonthRounded} />
              <RowFlex gap={'4px'}>
                {item.details[0].documentDate ? (
                  <div>
                    {moment(item.details[0].documentDate).format('DD MMM yyyy')}
                  </div>
                ) : (
                  <div>-</div>
                )}
                <div>|</div>
                <TextX data={item.details[0].documentNo} />
              </RowFlex>
            </RowFlex>
          )}
          <div style={{ color: 'red' }}>
            <RowFlex key="right">
              <IconX icon={MonetizationOnRounded} />
              {!item?.details.every(x => x?.documentNo || x?.documentDate) ||
              item?.totalOutstanding === 0 ? (
                <span>-</span>
              ) : (
                <TextX data={item?.totalOutstanding} dataType="Number" />
              )}
            </RowFlex>
          </div>
        </RowFlex>
        {haveSubData ? (
          <RowFlex mainAxisAlignment="space-between" fullWidth>
            <div></div>
            <RowFlex gap="4px">
              <div>No. of installment :</div>
              <div className="underline c-orange">{item.details.length}</div>
            </RowFlex>
          </RowFlex>
        ) : (
          <RowFlex key="thirdLine" mainAxisAlignment="space-between" fullWidth>
            {item.details[0].paymentParty === 'Buyer' ? (
              <RowGrid gridTemplateColumns={'1fr 0.3fr'}>
                <RowFlex>
                  <IconX icon={Person} />
                  <Wrap rowGap="4px">
                    {item.details[0].creditDocumentNo ? (
                      item.details[0].creditDocumentNo
                        .split(',')
                        .map((no, index) => (
                          <RowFlex key={index}>
                            <TextX data={no} />
                            {index <
                              item.details[0].creditDocumentNo.split(',')
                                .length -
                                1 && <span>,</span>}
                          </RowFlex>
                        ))
                    ) : (
                      <span>-</span>
                    )}
                  </Wrap>
                </RowFlex>
                <div></div>
              </RowGrid>
            ) : (
              <Tooltip
                title={`${item.details[0].paymentParty ?? '-'} (${
                  item.details[0].paymentPartyCode ?? '-'
                })`}
                id="pbTooltip"
                placement="top-start"
              >
                <div>
                  <RowGrid gridTemplateColumns={'1fr 0.3fr'}>
                    <RowFlex>
                      <IconX icon={AccountBalance} />
                      <Wrap rowGap="4px">
                        {item.details[0].creditDocumentNo ? (
                          item.details[0].creditDocumentNo
                            .split(',')
                            .map((no, index) => (
                              <RowFlex key={index}>
                                <TextX data={no} />
                                {index <
                                  item.details[0].creditDocumentNo.split(',')
                                    .length -
                                    1 && <span>,</span>}
                              </RowFlex>
                            ))
                        ) : (
                          <span>-</span>
                        )}
                      </Wrap>
                    </RowFlex>
                    <div></div>
                  </RowGrid>
                </div>
              </Tooltip>
            )}
          </RowFlex>
        )}
      </ColumnFlex>
      {item.allow_reversal &&
        !item.isReversal &&
        isPA &&
        item.details[0].documentNo &&
        index == lastIndex &&
        !item.system_use && (
          <IconX icon={DeleteIcon} color="red" onClick={handleDelete} />
        )}
    </RowFlex>
  )
}

export default PBexpansionSummary
