import { Wrap } from '@ifca-ui/core'
import Typography from '@mui/material/Typography'
import React from 'react'

interface GridViewStaggeredProps {
  gridLayout?: number[]
  children?: React.ReactNode
}

const GridViewStaggered: React.FC<GridViewStaggeredProps> = ({
  gridLayout,
  children,
}) => {
  const uiBuilder = () => {
    return (
      <Wrap>
        {gridLayout?.map((element, index) => (
          <div key={index} style={{ width: `${100 / element}%` }}>
            {/* {children[index]} */}
          </div>
        ))}
      </Wrap>
    )
  }

  return <>{uiBuilder}</>
}

export default GridViewStaggered
