import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const AreaVarianceListingIndo = lazy(() => import('./AreaVarianceListingIndo'))
const AreaVarianceFormIndo = lazy(() => import('./AreaVarianceFormIndo'))
const AreaVarianceDetailIndo = lazy(() => import('./AreaVarianceDetailIndo'))

const AreaVarianceIndoRoutes: RouteObject[] = [
  {
    path: 'area-variance-indo',
    children: [
      {
        path: '',
        element: <AreaVarianceListingIndo />,
      },
      {
        path: 'detail',
        element: <AreaVarianceDetailIndo />,
      },
      {
        path: 'add',
        element: <AreaVarianceFormIndo mode="add" />,
      },
    ],
  },
]

export default AreaVarianceIndoRoutes
