type ArrayItem = Record<string, any>
type NumericKeys<T> = {
  [K in keyof T]: T[K] extends number ? K : never
}[keyof T]

export const sum = <T extends ArrayItem>(
  array: Array<T>,
  key: keyof T | (string & {})
) => {
  if (!array || array?.length == 0) return 0
  return array?.reduce((a, b) => a + (Number(b[key]) || 0), 0)
}

export const customFormatNumber = amount => {
  if (amount) {
    return parseFloat(amount?.toString().replace(/,/g, ''))
  } else {
    return 0
  }
}
