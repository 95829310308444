import React, { FunctionComponent, Props } from 'react'
import { Autocomplete, TextField, TextFieldProps } from '@mui/material'

interface AddressProps {
  sections?: {
    address?: {
      component?: FunctionComponent
      props?: TextFieldProps
    }
    country?: {
      component?: FunctionComponent
      props?: any
    }
    state?: {
      component?: FunctionComponent
      props?: any
    }
    city?: {
      component?: FunctionComponent
      props?: any
    }
    postCode?: {
      component?: FunctionComponent
      props?: any
    }
  }
}
export const AddressAutocomplete = (props: AddressProps) => {
  return (
    <>
      {props?.sections?.address.component?.({}) || (
        <TextField {...props.sections.address.props} fullWidth />
      )}
      {props?.sections?.country?.component?.({}) || (
        // <Autocomplete {...props.sections.country.props} freeSolo fullWidth />
        <Autocomplete {...props.sections.country.props} freeSolo fullWidth />
      )}
      {props?.sections?.postCode?.component?.({}) || (
        // <Autocomplete {...props.sections.postCode.props} />
        <Autocomplete {...props.sections.postCode.props} />
      )}
      {props?.sections?.state?.component?.({}) || (
        // <Autocomplete {...props.sections.state.props} fullWidth />
        <Autocomplete {...props.sections.state.props} fullWidth />
      )}
      {props?.sections?.city?.component?.({}) || (
        // <Autocomplete {...props.sections.city.props} />
        <Autocomplete {...props.sections.city.props} />
      )}
    </>
  )
}

export const AddressAutocompleteDialog = (props: AddressProps) => {
  return (
    <>
      {props?.sections?.address.component?.({}) || (
        <TextField {...props.sections.address.props} fullWidth />
      )}
      {props?.sections?.country?.component?.({}) || (
        <Autocomplete {...props.sections.country.props} freeSolo fullWidth />
      )}
      {props?.sections?.state?.component?.({}) || (
        <Autocomplete {...props.sections.state.props} fullWidth />
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: 1, paddingRight: '4px' }}>
          {props?.sections?.city?.component?.({}) || (
            <Autocomplete {...props.sections.city.props} fullWidth />
          )}
        </div>
        <div style={{ flex: 1, paddingLeft: '4px' }}>
          {props?.sections?.postCode?.component?.({}) || (
            <Autocomplete {...props.sections.postCode.props} fullWidth />
          )}
        </div>
      </div>
    </>
  )
}
