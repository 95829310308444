import { TextField } from '@mui/material'
import { customFormatNumber } from 'helpers/arraySum'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'

interface props {
  control: any
  errors?: any
  isRequired?: boolean
  label: string
  name: string
  defaultValue?: any
}

export const TextFormField = ({
  control,
  errors,
  isRequired = true,
  label,
  name,
  defaultValue,
}: props) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    if (defaultValue) {
      setData(defaultValue)
    }
  }, [defaultValue])

  return (
    <Controller
      control={control}
      key={name}
      name={name}
      defaultValue={data}
      render={({ field: { onChange, onBlur, value } }) => (
        <TextField
          autoComplete="off"
          error={errors?.[name] ? true : false}
          fullWidth
          helperText={errors?.[name]?.message}
          label={`${label} ${isRequired ? '*' : ''}`}
          margin="normal"
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          variant="standard"
        />
      )}
    />
  )
}

export const NumberFormField = ({
  control,
  errors,
  isRequired = true,
  label,
  name,
}: props) => {
  const formatNumber = amount => {
    if (amount) {
      return parseFloat(amount?.toString().replace(/,/g, ''))
    } else {
      return 0
    }
  }

  return (
    <Controller
      control={control}
      key={name}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <NumberFormat
          allowNegative={false}
          autoComplete="off"
          customInput={TextField}
          decimalScale={2}
          error={errors?.[name] ? true : false}
          fixedDecimalScale={true}
          fullWidth
          helperText={errors?.[name]?.message}
          label={`${label} ${isRequired ? '*' : ''}`}
          margin="normal"
          multiline={true}
          name={name}
          onBlur={onBlur}
          onChange={event => {
            const newValue = customFormatNumber(event.target.value)
            onChange(newValue)
          }}
          thousandSeparator
          value={formatNumber(value)}
          variant="standard"
        />
      )}
    />
  )
}
