import React, { lazy } from 'react'
import { RouteObject } from 'react-router'
import CollectionActivityRoutes from './CollectionActivity/Routes'
import PaymentRemindersIndoRoutes from './PaymentRemindersIndo/PaymentReminderExemption/Routes'
import PaymentReminderGenerationIndoRoutes from './PaymentRemindersIndo/PaymentReminderGeneration/Routes'
import LatePaymentInterestExceptionIndoRoutes from './LatePaymentInterestExceptionIndo/LatePaymentInterestException/Routes'
import LatePaymentInterestGenerationIndoRoutes from './LatePaymentInterestExceptionIndo/LatePaymentInterestGeneration/Routes'
// import CollectionActivitiesIndoRoutes from './InstallmentRevenueProjection/Routes'
import AuthorizedSaleStaffIndoRoute from './AuthorizedSaleStaff/Routes'
import OverduedPurchaserAccountsIndonesiaRoutes from './OverduePurchaserAccountsIndonesia/Routes'
const CreditControlIndoSubmenu = lazy(
  () => import('./CreditControlIndoSubmenu')
)

export const CREDIT_CONTROL_INDO_PATH = {
  SUBMENU: '/:projectID/credit-control-indo',
}

const CreditControlIndoRoutes: RouteObject[] = [
  {
    path: 'credit-control-indo',
    children: [
      {
        path: '',
        element: <CreditControlIndoSubmenu />,
      },
      // ...OverduedPurchaserAccountsIndoRoutes,
      ...OverduedPurchaserAccountsIndonesiaRoutes,
      ...PaymentRemindersIndoRoutes,
      ...CollectionActivityRoutes,
      ...PaymentReminderGenerationIndoRoutes,
      ...LatePaymentInterestExceptionIndoRoutes,
      ...LatePaymentInterestGenerationIndoRoutes,
      ...AuthorizedSaleStaffIndoRoute
    ],
  },
]

export default CreditControlIndoRoutes
