import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  Container,
  Dialog,
  DialogButton,
  FileAttachment,
  FilePreviewCard,
  FileUploadTextField,
  Footer,
  ListItemText,
  Menu,
  Subheader,
  SubheaderText,
  useMenuOption,
  useUploadAttachment,
} from '@ifca-ui/core'
import {
  AddBox,
  MoreVert,
  PictureAsPdfSharp,
  QrCodeScannerTwoTone,
} from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { createFilterOptions } from '@mui/material/useAutocomplete'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import { AddressAutocomplete } from 'components/Inputs/AddressAutocomplete'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useGetAllMasterDataQuery,
  useProjectDetailQuery,
  useGetAllProfessionQuery,
  useGetAllReligionQuery,
  useBookingContactListQuery,
  useGetPurchaserInfoLazyQuery,
  useGetAddressListByContactQuery,
} from 'generated/graphql'
import { useAddressAutoComplete } from 'helpers/Hooks/useAddressAutoComplete'
import { CommonYupValidation } from 'helpers/yup'
import MuiPhoneNumber from 'material-ui-phone-number'
import { useContext, useEffect, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useLocation, useNavigate, useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import {
  PurchaserInfoAddressDialog,
  addressProps,
} from './PurchaserTransferAddressDialog'
import React from 'react'
import { CardListHeader, CardListItem } from 'components/General/CardListItem'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import useNav from 'components/RouteService/useNav'

export interface PurchaserInfoProps {
  Nationality: any
  IdentityType: string
  IdentityNo: string
  Name: any
  NameTax: string
  Salutation: any
  MaritalStatus: any
  Gender: string
  Profession: any
  Religion: any
  Email: string
  HomeTelNo: string
  MobileNo: string
  OfficeTelNo: string
  GstRegNo: string
  TaxAddress: string
  Remark: string
  address: any
  addressList: addressProps[]
}

const PurchaserTransferBuyerForm = (props: any) => {
  const subHeaderRef = useRef(null)
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  let navigate = useNavigate()
  let location = useLocation()
  const { projectID, detailId }: any = useParams()
  const { formMode, backtrack } = props
  const [ogAddr, setOGAddr] = useState(null)
  const [addrValue, setAddress] = useState(null)
  const { navBack, navTo } = useNav()

  const mode = !detailId ? 'add' : 'edit'

  let submitData = location?.state as any

  const purchaserList = location?.state?.purchaseBuyerList
  let findDetailId = purchaserList?.find(x => x?.detailId == detailId)

  const [CompanyPurchaseCheck, setCompanyPurchaseCheck] =
    useState<boolean>(false)
  const [PrincipalBuyerCheck, setPrincipalBuyerCheck] = useState<boolean>(
    purchaserList?.length < 1
  )
  const [SMSCheck, setSMSCheck] = useState<boolean>(false)
  const [WhatsappCheck, setWhatsappCheck] = useState<boolean>(false)
  const [EmailBlastCheck, setEmailBlastCheck] = useState<boolean>(false)
  const [PurchaserAppCheck, setPurchaserAppCheck] = useState<boolean>(false)
  const [SubscribeMarketingCheck, setSubscribeMarketingCheck] =
    useState<boolean>(false)
  const [EmailCheck, setEmailCheck] = useState<boolean>(false)
  const [LetterCheck, setLetterCheck] = useState<boolean>(false)
  const [VIPCheck, setVIPCheck] = useState<boolean>(false)
  const maxCharacters = 20
  const [tempCache, setTempCache] = useState(
    location?.state?.purchaseBuyerList || []
  )
  const [isPrincipalHide, setIsPrincipalHide] = useState(false)

  const [addressDialog, setAddressDialog] = React.useState(false)
  const [dialogMode, setDialogMode] = useState<'Add' | 'Edit' | ''>('')
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const [contactId, setContactId] = useState()
  //upload attachment
  const {
    files,
    handleUploadChange,
    handleRemove,
    previewFiles,
    setFiles,
    getExistingFileRemovedAttachmentIDs,
    setExistingFile,
    uploadedFiles,
  } = useUploadAttachment()

  const handleEditUpload = async (file: any) => {
    const existingFile: FileAttachment[] = []
    const prevFile = []
    //start (new)
    const newFiles = await Promise.all(
      file.map(async (x, i) => {
        const data = await fetch(x.resourceUrl, { mode: 'no-cors' })
          .then(res => res.blob())
          .then(file => new File([file], x.name, { type: x?.type }))
        let temp: FileAttachment = {
          File: data,
          AttachmentID: x.id,
          ResourceUrl: x.resourceUrl,
        }
        prevFile.push(data)
        existingFile.push(temp)
      })
    )
    //end
    setExistingFile([...existingFile])
    setFiles([...prevFile])
  }

  //require field
  const FormTemplateSchema = yup.object().shape({
    Nationality: CommonYupValidation.requireArray('Nationality is required'),
    IdentityType: CommonYupValidation.requireField('Identity Type is required'),
    IdentityNo: yup.string().when('IdentityType', value => {
      switch (value) {
        case 'IDENTITY_CARD':
          return CommonYupValidation.requireField('Identity No is required')
          break
        case 'PASSPORT':
          return CommonYupValidation.requireField('Passport is required')
          break
        case 'MILITARY_CARD':
          return CommonYupValidation.requireField('Military Card is required')
          break
        case 'BUSINESS_LICENSE':
          return CommonYupValidation.requireField(
            'Business License is required'
          )
          break
        case undefined:
          return CommonYupValidation.requireField('Identity No is required')
          break
      }
    }),
    Name: CommonYupValidation.requireField('Name as per ID is required'),
    Salutation: CommonYupValidation.requireArray('Salutation is required'),
    MaritalStatus: CommonYupValidation.requireArray(
      'Marital Status is required'
    ),
    Gender:
      CommonYupValidation.requireField('Gender is required').nullable(true),
    Profession: CommonYupValidation.requireArray('Profession is required'),
    Religion: CommonYupValidation.requireArray('Religion is required'),
    Email: CommonYupValidation.email(),
    MobileNo: CommonYupValidation.requireField('MobileNo is required'),
    // address: CommonYupValidation.requireArray(),
    // GstRegNo: CommonYupValidation.requireField(
    //   'Tax Registration No. is required'
    // ),
    // NameTax: CommonYupValidation.requireField(
    //   'Name on Tax Registration is required'
    // ),
    // TaxAddress: CommonYupValidation.requireField(
    //   'Tax Registration Address is required'
    // ),
  })

  // -- auto address start
  const {
    getCountry, // country
    getStatebyCountry, // State
    getCitybyState, // City
    getPostcodebyCity, // Postcode
    handleCountryOnChange,
    handleStateOnChange,
    handleCityOnChange,
    handlePostCodeOnChange,
    handleGoogleOnChange,
    query,
    autoCompleteRef,
    state,
    setQuery,
    initializeEditValue,
  } = useAddressAutoComplete()
  // -- auto address end

  //form
  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitted },
    clearErrors,
  } = useForm<PurchaserInfoProps>({
    mode: 'all',
    resolver: yupResolver(FormTemplateSchema),
    defaultValues: {
      Nationality: null,
      IdentityType: '',
      IdentityNo: '',
      Name: null,
      Salutation: null,
      MaritalStatus: null,
      Gender: '',
      Profession: null,
      Religion: null,
      Email: '',
      HomeTelNo: '',
      MobileNo: '',
      OfficeTelNo: '',
      Remark: '',
      TaxAddress: '',
      NameTax: '',
      GstRegNo: '',
      addressList: [],
    },
  })

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'addressList',
  })
  //end form

  //#region project detail API
  const {
    loading: curProjectLoading,
    error: curProjectError,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })

  const {
    loading: BookingContactListLoading,
    error: BookingContactListError,
    data: { BookingContactList } = { BookingContactList: [] },
  } = useBookingContactListQuery()
  //#endregion

  //--Get master data start--
  const {
    loading: getMasterDataLoading,
    data: { GetAllMasterInfo } = { GetAllMasterInfo: {} },
  } = useGetAllMasterDataQuery({})

  const {
    loading: getProfessionLoading,
    data: { getAllProfession } = { getAllProfession: null },
  } = useGetAllProfessionQuery()

  const {
    loading: getAllReligionLoading,
    data: { getAllReligion } = { getAllReligion: null },
  } = useGetAllReligionQuery()

  const {
    loading: getAddressListByContactLoading,
    error: getAddressListByContactError,
    data: { getAddressListByContact } = { getAddressListByContact: [] },
  } = useGetAddressListByContactQuery({
    variables: {
      contactId: contactId ?? '',
    },
  })

  //end

  const [
    getPurchaser,
    {
      loading: loadingPurchaserInfo,
      refetch: refetchPurchaserInfo,
      data: { getPurchaserInfo } = {
        getPurchaserInfo: null,
        // getAttachment: null,
      },
    },
  ] = useGetPurchaserInfoLazyQuery({
    onCompleted: data => {
      let purchaser = data.getPurchaserInfo
      setPrincipalBuyerCheck(purchaser.contactExt?.isPrincipalBuyer)
      if (data.getAttachment) handleEditUpload(data.getAttachment)
      setValue('Name', purchaser.fullName)
      setValue('IdentityType', purchaser.contactExt?.identityType)
      setValue('IdentityNo', purchaser.icNo)
      setValue(
        'Nationality',
        Nationalities?.find(x => x.id == purchaser.nationality.id)
      )
      setValue(
        'Salutation',
        Salutations?.find(x => x.id == purchaser.salutation?.id)
      )
      setVIPCheck(purchaser.contactExt?.isVip ?? false)
      //   setValue('Race', race)
      setValue(
        'MaritalStatus',
        MaritalStatus?.find(x => x.id == purchaser.maritalStatus?.id)
      )
      setValue(
        'Profession',
        getAllProfession?.find(x => x.id == purchaser.contactExt?.professionId)
      )
      setValue(
        'Religion',
        getAllReligion?.find(x => x.id == purchaser.contactExt?.religionId)
      )
      setValue('Gender', purchaser.gender)
      // setValue('CustomerOrigin', purchaser.contactExt?.customerOrigin)
      setValue('Email', purchaser.email)
      setValue('HomeTelNo', purchaser.homeTel)
      setValue('MobileNo', purchaser.phoneNo)
      setValue('OfficeTelNo', purchaser.officeTel)
      if (purchaser.contactAddress.length > 0) {
        initializeEditValue({
          address: purchaser.contactAddress[0].address,
          country: purchaser.contactAddress[0].country,
          state: purchaser.contactAddress[0].state,
          city: purchaser.contactAddress[0].city,
          postCode: purchaser.contactAddress[0].postCode,
        })
      }

      setSMSCheck(purchaser.contactExt?.subscribeMaterialSms ?? false)
      setWhatsappCheck(purchaser.contactExt?.subscribeMaterialWhatsapp ?? false)
      setEmailBlastCheck(purchaser.contactExt?.subscribeMaterialEmail ?? false)
      setPurchaserAppCheck(
        purchaser.contactExt?.subscribeMaterialPaNews ?? false
      )

      if (purchaser.contactExt?.mailingMethod == 'Email') {
        setEmailCheck(true)
        setLetterCheck(false)
      }
      if (purchaser.contactExt?.mailingMethod == 'Letter') {
        setLetterCheck(true)
        setEmailCheck(false)
      }
      setValue('NameTax', purchaser.contactExt?.nameOnTax)
      setValue('TaxAddress', purchaser.contactExt?.taxRegistrationAddress)
      // setValue('TaxRegNo', purchaser.contactExt?.taxRegistrationNo)
      setValue('Remark', purchaser.contactExt?.remark)
      setCompanyPurchaseCheck(
        purchaser.contactExt?.customerOrigin == 'COMPANY_PURCHASE'
      )
    },
  })

  useEffect(() => {
    if (watch('addressList')?.length > 0 && contactId) {
      setValue('addressList', [])
    }

    if (contactId) {
      const addressListing = getAddressListByContact?.map(x => {
        return {
          address: {
            AddressType: x?.addressList?.AddressType,
            address: x?.addressList?.address,
            city: x?.addressList?.city,
            country: x?.addressList?.country,
            postCode: x?.addressList?.postCode,
            state: x?.addressList?.state,
          },
          addressType: x?.addressList?.AddressType,
          isPrimary: false,
        }
      })
      if (addressListing?.length > 0) {
        setValue('addressList', addressListing || [])
      }
      // setValue('addressList', addressList)
    } // if (getAddressListByContact?.length <= 1) {
  }, [getAddressListByContact])

  useEffect(() => {
    if (findDetailId && !getMasterDataLoading) {
      // handleEditUpload(findDetailId?.unitBookingAttachment)
      // setFiles(findDetailId?.unitBookingAttachment)

      const nationality = Nationalities?.find(
        x => x.id == findDetailId?.nationality
      )
      const name = findDetailId?.name ?? ''
      const IsPrincipalBuyer = findDetailId?.IsPrincipalBuyer ?? ''
      const email = findDetailId?.email ?? ''
      const gender = findDetailId?.gender ?? ''
      const homeTelNo = findDetailId?.homeTelNo ?? ''
      const identityNo = findDetailId?.identityNo ?? ''
      const mobileNo = findDetailId?.mobileNo ?? ''
      const officeTelNo = findDetailId?.officeTelNo ?? ''
      const religion = getAllReligion?.find(x => x.id == findDetailId?.religion)
      const salutation = Salutations?.find(
        x => x.id == findDetailId?.salutation
      )
      const maritalStatus = MaritalStatus?.find(
        x => x.id == findDetailId?.maritalStatus
      )
      const profession = getAllProfession?.find(
        x => x.id == findDetailId?.profession
      )
      const vip = findDetailId?.contactExt?.IsVip ?? false
      const identityType = findDetailId?.contactExt?.identityType ?? ''
      const mailingMethod = findDetailId?.contactExt?.mailingMethod ?? false
      const remark = findDetailId?.contactExt?.remark ?? ''
      const subscribeMaterialEmail =
        findDetailId?.contactExt?.subscribeMaterialEmail ?? false
      const subscribeMaterialPaNews =
        findDetailId?.contactExt?.subscribeMaterialPaNews ?? false
      const subscribeMaterialSms =
        findDetailId?.contactExt?.subscribeMaterialSms ?? false
      const subscribeMaterialWhatsapp =
        findDetailId?.contactExt?.subscribeMaterialWhatsapp ?? false

      // Address
      const address = findDetailId?.address?.address
      const city = findDetailId?.address?.city
      const country = getCountry?.find(
        x => x?.name == findDetailId?.address?.country
      )
      const postCode = findDetailId?.address?.postCode
      const state = getCountry?.find(
        x => x?.name == findDetailId?.address?.state
      )

      // Tax
      const nameTax = findDetailId?.tax?.nameTax ?? ''
      const taxAddress = findDetailId?.tax?.taxAddress ?? ''
      const taxRegNo = findDetailId?.tax?.taxRegNo ?? ''
      const addressList = findDetailId?.addressList ?? []

      //set
      setValue('Nationality', nationality)
      setPrincipalBuyerCheck(IsPrincipalBuyer)
      setValue('Name', name)
      setValue('Email', email)
      setValue('Gender', gender)
      setValue('HomeTelNo', homeTelNo)
      setValue('IdentityNo', identityNo)
      setValue('MaritalStatus', maritalStatus)
      setValue('MobileNo', mobileNo)
      setValue('OfficeTelNo', officeTelNo)
      setValue('Profession', profession)
      setValue('Religion', religion)
      setValue('Salutation', salutation)
      setVIPCheck(vip)
      setValue('IdentityType', identityType)
      if (mailingMethod == 'Email') {
        setEmailCheck(true)
      } else {
        setLetterCheck(true)
      }
      setValue('Remark', remark)
      setEmailBlastCheck(subscribeMaterialEmail)
      setPurchaserAppCheck(subscribeMaterialPaNews)
      setSMSCheck(subscribeMaterialSms)
      setEmailBlastCheck(subscribeMaterialEmail)
      setWhatsappCheck(subscribeMaterialWhatsapp)
      setValue('NameTax', nameTax)
      setValue('TaxAddress', taxAddress)
      setValue('GstRegNo', taxRegNo)

      if (address) {
        let array_of_address = [address] || []
        setQuery(array_of_address.join(', '))
        setOGAddr(array_of_address)
        setAddress(array_of_address?.slice(0))
        initializeEditValue({
          address: findDetailId?.address?.address,
          country: findDetailId?.address?.country,
          state: findDetailId?.address?.state,
          city: findDetailId?.address?.city,
          postCode: findDetailId?.address?.postCode,
        })
      }

      setValue('addressList', addressList)
    } else if (!findDetailId && detailId) {
      getPurchaser({
        variables: {
          contactId: detailId,
        },
      })
    } else {
      const nationality = Nationalities?.find(x => x.name == 'Indonesian')

      setValue('Nationality', nationality)
    }
  }, [getAllReligion, getMasterDataLoading])

  useEffect(() => {
    const arrPurchaser = location?.state?.purchaseBuyerList

    // if (arrPurchaser == null) {
    //   setIsPrincipalHide(true)
    //   setPrincipalBuyerCheck(false)
    // }

    // if (isPrincipalHide == true){
    //   setPrincipalBuyerCheck(false)
    // }

    // const principalBuyerTrue = arrPurchaser?.find(x => x.detailId == detailId)

    const principalBuyerTrueAll =
      arrPurchaser?.find(x => x?.IsPrincipalBuyer == true) ?? null

    let filterPrinciple = arrPurchaser.filter(x => x.IsPrincipalBuyer === true)

    if (filterPrinciple?.length > 0) {
      if (filterPrinciple[0]?.detailId == detailId) {
        setIsPrincipalHide(false)
        setPrincipalBuyerCheck(true)
      } else {
        setIsPrincipalHide(true)
        setPrincipalBuyerCheck(false)
      }
    } else {
      setIsPrincipalHide(false)
      setPrincipalBuyerCheck(true)
    }
    // if (mode == 'edit') {
    //   arrPurchaser.forEach(data => {
    //     if (
    //       principalBuyerTrueAll != null &&
    //       principalBuyerTrue?.IsPrincipalBuyer == false
    //     ) {
    //       setIsPrincipalHide(true)
    //       // setPrincipalBuyerCheck(false)
    //     } else if (
    //       principalBuyerTrueAll == null &&
    //       principalBuyerTrue?.IsPrincipalBuyer == false
    //     ) {
    //       setIsPrincipalHide(false)
    //       // setPrincipalBuyerCheck(true)
    //     } else {
    //       setIsPrincipalHide(false)
    //       // setPrincipalBuyerCheck(true)
    //     }
    //   })
    // } else {
    //   arrPurchaser.forEach(data => {
    //     if (data?.IsPrincipalBuyer == true) {
    //       setIsPrincipalHide(true)
    //       setPrincipalBuyerCheck(false)
    //     }
    //   })
    // }
  }, [])

  //#region header/subheader
  useEffect(() => {
    if (subHeaderRef.current?.clientHeight) {
      rootDispatch({
        type: 'subHeaderHeight',
        payload: subHeaderRef.current?.clientHeight,
      })
    }
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'close',
          props: {
            onClick: () =>
              navTo(
                `/${projectID}/purchaser-name-transfer/add`,
                location?.state
              ),
            // {backtrack != 'PurchaserTransfer'  ? (
            //   navTo(`/${projectID}/purchaser-name-transfer/add`, location?.state)
            // ):(
            //   navTo(`/${projectID}/purchaser-name-transfer/${detailId}/edit`, location?.state)
            // )}
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Purchaser Form',
          },
          rightText: mode === 'add' ? 'New' : 'Edit',
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])
  //#endregion

  const checkSubscribe = check => {
    setSubscribeMarketingCheck(check)
    if (check) {
      setSMSCheck(true)
      setWhatsappCheck(true)
      setEmailBlastCheck(true)
      setPurchaserAppCheck(true)
    } else {
      setSMSCheck(false)
      setWhatsappCheck(false)
      setEmailBlastCheck(false)
      setPurchaserAppCheck(false)
    }
  }

  const checkAllSubscribe = (currentCheck, check1, check2, check3) => {
    if (!currentCheck) {
      setSubscribeMarketingCheck(false)
    }
    if (currentCheck && check1 && check2 && check3) {
      setSubscribeMarketingCheck(true)
    }
  }

  const formatInput = value => {
    // Hapus tanda "." dan "-" dari perhitungan panjang teks
    const cleanedValue = value.replace(/[^\d]/g, '')

    const formattedValue = cleanedValue.replace(
      /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/,
      '$1.$2.$3.$4-$5.$6'
    )

    // Batasi jumlah karakter maksimum
    if (cleanedValue.length <= maxCharacters) {
      return formattedValue
    } else {
      return formattedValue.slice(0, maxCharacters)
    }
  }

  const onSubmit = data => {
    const detailID = detailId ?? uuidv4()

    if (PrincipalBuyerCheck == true) {
      if (
        state?.address != '' &&
        state?.country.name != '' &&
        state?.city.name != '' &&
        state?.state.name != '' &&
        state?.postcode.postcode != ''
      ) {
        let tempData = {
          detailId: detailID,
          IsPrincipalBuyer: PrincipalBuyerCheck,
          nationality: data?.Nationality?.id,
          identityNo: data?.IdentityNo,
          name: data?.Name,
          salutation: data?.Salutation?.id,
          maritalStatus: data?.MaritalStatus?.id,
          gender: data?.Gender,
          email: data?.Email,
          profession: data?.Profession?.id,
          religion: data?.Religion?.id,
          address: {
            address: state.address,
            country: state.country.name,
            city: state.city.name,
            state: state.state.name,
            postCode: state.postcode.postcode,
          },
          homeTelNo: data?.HomeTelNo,
          mobileNo: data?.MobileNo,
          officeTelNo: data?.OfficeTelNo,
          contactExt: {
            identityType: data?.IdentityType,
            customerOrigin: data?.CustomerOrigin,
            IsVip: VIPCheck,
            subscribeMaterialSms: SMSCheck,
            subscribeMaterialWhatsapp: WhatsappCheck,
            subscribeMaterialEmail: EmailBlastCheck,
            subscribeMaterialPaNews: PurchaserAppCheck,
            mailingMethod: EmailCheck ? 'Email' : LetterCheck ? 'Letter' : '',
            remark: data?.Remark ?? null,
          },
          tax: {
            taxRegNo: data?.GstRegNo,
            nameTax: data?.NameTax,
            taxAddress: data?.TaxAddress,
          },
          unitBookingAttachment: files,
          addressList: data?.addressList,
          commonStatus: 'ACTIVE',
        }

        const existingDataIndex = tempCache.findIndex(
          item => item.detailId === detailId
        )

        if (existingDataIndex !== -1) {
          // Jika ID yang akan diedit ditemukan dalam tempCache
          // Perbarui data yang ada dengan data baru
          tempCache[existingDataIndex] = tempData
        } else {
          // Jika ID tidak ditemukan, tambahkan data baru
          tempCache.push(tempData)
        }

        tempCache.sort((a, b) => {
          if (a.IsPrincipalBuyer && !b.IsPrincipalBuyer) {
              return -1; // a should come before b
          } else if (!a.IsPrincipalBuyer && b.IsPrincipalBuyer) {
              return 1; // b should come before a
          } else {
              return 0; // no change
          }
      });

        navigate(`/${projectID}/purchaser-name-transfer/add`, {
          state: {
            getBookDate: location?.state?.getBookDate,
            unitId: location?.state?.unitId,
            purchaseBuyerList: tempCache,
          },
        })
      }
    } else {
      let tempData = {
        detailId: detailID,
        IsPrincipalBuyer: PrincipalBuyerCheck,
        nationality: data?.Nationality?.id,
        identityNo: data?.IdentityNo,
        name: data?.Name,
        salutation: data?.Salutation?.id,
        maritalStatus: data?.MaritalStatus?.id,
        gender: data?.Gender,
        email: data?.Email,
        profession: data?.Profession?.id,
        religion: data?.Religion?.id,
        // address: {
        //   address: state.address,
        //   country: state.country.name,
        //   city: state.city.name,
        //   state: state.state.name,
        //   postCode: state.postcode.postcode,
        // },
        homeTelNo: data?.HomeTelNo,
        mobileNo: data?.MobileNo,
        officeTelNo: data?.OfficeTelNo,
        contactExt: {
          identityType: data?.IdentityType,
          customerOrigin: data?.CustomerOrigin,
          IsVip: VIPCheck,
          subscribeMaterialSms: SMSCheck,
          subscribeMaterialWhatsapp: WhatsappCheck,
          subscribeMaterialEmail: EmailBlastCheck,
          subscribeMaterialPaNews: PurchaserAppCheck,
          mailingMethod: EmailCheck ? 'Email' : LetterCheck ? 'Letter' : '',
          remark: data?.Remark ?? null,
        },
        tax: {
          taxRegNo: data?.GstRegNo,
          nameTax: data?.NameTax,
          taxAddress: data?.TaxAddress,
        },
        unitBookingAttachment: files,
        addressList: data?.addressList,
        commonStatus: 'ACTIVE',
      }

      const existingDataIndex = tempCache.findIndex(
        item => item.detailId === detailId
      )

      if (existingDataIndex !== -1) {
        // Jika ID yang akan diedit ditemukan dalam tempCache
        // Perbarui data yang ada dengan data baru
        tempCache[existingDataIndex] = tempData
      } else {
        // Jika ID tidak ditemukan, tambahkan data baru
        tempCache.push(tempData)
      }

      tempCache.sort((a, b) => {
        if (a.IsPrincipalBuyer && !b.IsPrincipalBuyer) {
            return -1; // a should come before b
        } else if (!a.IsPrincipalBuyer && b.IsPrincipalBuyer) {
            return 1; // b should come before a
        } else {
            return 0; // no change
        }
    });

      navigate(`/${projectID}/purchaser-name-transfer/add`, {
        state: {
          getBookDate: location?.state?.getBookDate,
          unitId: location?.state?.unitId,
          purchaseBuyerList: tempCache,
        },
      })
    }
  }

  // -- enum listing start
  const IdentityTypeListing = [
    {
      ID: 'IDENTITY_CARD',
      Name: 'Identity Card',
    },
    { ID: 'PASSPORT', Name: 'Passport' },
    { ID: 'MILITARY_CARD', Name: 'Military Card' },
    { ID: 'BUSINESS_LICENSE', Name: 'Business License' },
  ]

  const CustomerOriginListing = [
    { ID: 'STAFF_PURCHASE', Name: 'Staff Purchaser' },
    { ID: 'PUBLIC', Name: 'Public' },
    { ID: 'GOVERNMENT_STAFF', Name: 'Government Staff' },
    { ID: 'COMPANY_PURCHASE', Name: 'Company Purchase' },
  ]

  const { Genders, MaritalStatus, Salutations, Nationalities }: any =
    GetAllMasterInfo

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    ignoreCase: true,
    limit: 3,
    stringify: (option: any) => {
      return option.fullName.toLowerCase()
    },
  })

  const [deleteDialog, setDeleteDialog] = React.useState(false)

  const DeleteDialog = (
    <Dialog
      fullWidth={true}
      open={deleteDialog}
      onClose={() => setDeleteDialog(false)}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Address
                  </Typography>
                  <Typography
                    className="text-xsTitle"
                    color="primary.dark"
                    component="span"
                    variant="inherit"
                  >
                    Delete
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <Box>
          <Box width="100%" display="flex">
            <Typography
              component="span"
              flex="1 1"
              variant="inherit"
              className="text-xsTitle"
            >
              Are you sure want to delete this address?
            </Typography>
          </Box>
          <Box width="100%" display="flex" paddingTop={'10px'}>
            <Typography
              component="span"
              flex="1 1"
              variant="inherit"
              className="text-mdDesc"
            >
              {menu?.data?.address?.AddressType}
            </Typography>
          </Box>
          <Box width="100%" display="flex" paddingTop={'5px'}>
            <Typography
              component="span"
              flex="1 1"
              variant="inherit"
              className="text-mdDesc"
            >
              {menu?.data?.address?.address} {menu?.data?.address?.city}{' '}
              {menu?.data?.address?.country}
            </Typography>
          </Box>
        </Box>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => setDeleteDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            onClick: () => handleDeleteAddress(menu?.index),
          },
        ],
      }}
    />
  )
  //#enderegion

  const handleDeleteAddress = index => {
    setDeleteDialog(false)
    remove(index)
  }

  return (
    <>
      {DeleteDialog}
      <PurchaserInfoAddressDialog
        open={addressDialog}
        setOpen={setAddressDialog}
        dialogMode={dialogMode}
        addressList={watch('addressList')}
        menu={menu?.data}
        update={update}
        append={append}
        index={menu?.index}
      />
      <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
        <Container bottomSpacing="lg">
          <Card>
            <Typography component="div" display={'flex'} width={'100%'}>
              <Typography component="div" width={'50%'}>
                <Typography
                  color="common.black"
                  component="span"
                  flex="1 1"
                  className="text-desc"
                  variant="inherit"
                >
                  {/* {(purchaserList?.length < 1 || PrincipalBuyerCheck) && ( */}
                  {isPrincipalHide == false && (
                    <Box width="100%" marginLeft="2%">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="principalBuyer"
                            color="primary"
                            onChange={e => {
                              setPrincipalBuyerCheck(e.target.checked)
                              // checkAllSubscribe(
                              //   e.target.checked,
                              //   WhatsappCheck,
                              //   EmailBlastCheck,
                              //   PurchaserAppCheck
                              // )
                            }}
                            checked={PrincipalBuyerCheck}
                          />
                        }
                        label="Principal Buyer"
                      />
                    </Box>
                  )}

                  <Box width="100%" marginLeft="2%">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="companyPurchase"
                          color="primary"
                          onChange={e => {
                            setCompanyPurchaseCheck(e.target.checked)
                            // checkAllSubscribe(
                            //   e.target.checked,
                            //   WhatsappCheck,
                            //   EmailBlastCheck,
                            //   PurchaserAppCheck
                            // )
                          }}
                          checked={CompanyPurchaseCheck}
                        />
                      }
                      label="Company Purchase"
                    />
                  </Box>
                </Typography>
              </Typography>
              <Typography
                component="div"
                width={'50%'}
                textAlign={'right'}
                mt="20px"
              >
                <Typography
                  component="span"
                  variant="inherit"
                  className="text-desc"
                  color="primary"
                >
                  <DialogButton
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Scan ID <QrCodeScannerTwoTone />
                  </DialogButton>
                </Typography>
              </Typography>
            </Typography>

            <Box pr="6px" width="50%" mt="10px">
              <Controller
                control={control}
                name="Nationality"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Autocomplete
                    options={Nationalities}
                    getOptionLabel={option => option?.['name']}
                    fullWidth
                    disabled={detailId ? true : false}
                    value={value}
                    onChange={(event, value) => {
                      onChange(value)
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Nationality"
                        required
                        variant="standard"
                        margin="normal"
                        value={value}
                        autoComplete="off"
                        helperText={errors?.Nationality?.message}
                        error={errors?.Nationality ? true : false}
                      />
                    )}
                  />
                )}
              />
            </Box>

            <Box width="50%" pr="6px" mt="35px">
              <FormControlLabel
                control={
                  <Checkbox
                    name="VIP"
                    color="primary"
                    onChange={e => setVIPCheck(e.target.checked)}
                    checked={VIPCheck}
                  />
                }
                label="VIP"
              />
            </Box>

            <Box pr="6px" width="50%">
              <Controller
                control={control}
                name="IdentityType"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    select
                    variant="standard"
                    fullWidth
                    margin="normal"
                    onBlur={onBlur}
                    onChange={onChange}
                    autoComplete="off"
                    name="IdentityType"
                    disabled={detailId ? true : false}
                    label="Identity Type"
                    required
                    value={value}
                    helperText={errors?.IdentityType?.message}
                    error={errors?.IdentityType ? true : false}
                  >
                    {IdentityTypeListing?.map(v => (
                      <MenuItem value={v.ID}>{v.Name}</MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Box>
            <Box pr="6px" width="50%">
              {watch('IdentityType') == 'IDENTITY_CARD' ||
              !watch('IdentityType') ? (
                <Controller
                  control={control}
                  name="IdentityNo"
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    // <NricTextField
                    //   label="Identity No"
                    //   name="IdentityNo"
                    //   onChange={onChange}
                    //   onBlur={onBlur}
                    //   value={value}
                    //   helperText={errors?.IdentityNo?.message}
                    //   error={errors?.IdentityNo ? true : false}
                    //   required={true}
                    // />
                    <NumberFormat
                      customInput={TextField}
                      label="Identity No"
                      disabled={detailId ? true : false}
                      name="IdentityNo"
                      autoComplete="off"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      fullWidth
                      margin="normal"
                      variant="standard"
                      decimalScale={0}
                      format="################"
                      mask="_"
                      helperText={error?.message}
                      error={error ? true : false}
                      required={true}
                    />
                  )}
                />
              ) : (
                <Controller
                  control={control}
                  name="IdentityNo"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      variant="standard"
                      fullWidth
                      margin="normal"
                      disabled={detailId ? true : false}
                      onBlur={onBlur}
                      onChange={onChange}
                      autoComplete="off"
                      name="IdentityNo"
                      label={
                        IdentityTypeListing?.find(
                          el => el.ID == watch('IdentityType')
                        )?.Name
                      }
                      required
                      value={value}
                      helperText={errors?.IdentityNo?.message}
                      error={errors?.IdentityNo ? true : false}
                    />
                  )}
                />
              )}
            </Box>

            <Controller
              control={control}
              name="Name"
              render={({ field: { onChange, onBlur, value } }) => (
                <Autocomplete
                  freeSolo
                  autoSelect
                  // open={true}
                  // openOnFocus
                  // disabled={detailId ? true : false}
                  options={BookingContactList ?? []}
                  // getOptionLabel={option => option?.['fullName']}
                  getOptionLabel={option =>
                    typeof option !== 'string' ? `${option?.fullName}` : option
                  }
                  renderOption={(props, item) => (
                    <li {...props} key={item.contactId}>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            component="div"
                            display="flex"
                            variant="inherit"
                            fontWeight="bold"
                          >
                            {item.fullName}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            component="div"
                            display="flex"
                            variant="inherit"
                          >
                            {item.identityNo}
                          </Typography>
                        }
                      />
                    </li>
                    // <MenuItem key={item.contactId} value={item}>{item.fullName}</MenuItem>
                  )}
                  // autoComplete
                  filterOptions={(options, input) => {
                    const filter = createFilterOptions({
                      matchFrom: 'start',
                      ignoreCase: true,
                      // limit:1,
                      stringify: (option: any) => {
                        return option.fullName.toLowerCase()
                      },
                    })(options, input)
                    return filter as any[]
                  }}
                  // filterOptions={(options, {inputValue, getOptionLabel})=>{
                  //   const opts = options.filter(opt=>getOptionLabel(opt).toLowerCase().includes(inputValue ?? ""))
                  //   return opts
                  // }}
                  // getOptionSelected={(option, value) => option?.fullName === value?.fullName}
                  fullWidth
                  value={value}
                  onChange={(event, value) => {
                    if (typeof value === 'string') return onChange(value)
                    else if (value === null) return onChange((value = ''))

                    onChange(value.fullName)

                    const nationalityExist = Nationalities?.find(
                      x => x.id == value?.nationality
                    )

                    const maritalStatusExist = MaritalStatus?.find(
                      x => x.id == value?.maritalStatus
                    )

                    const profession = getAllProfession?.find(
                      x => x.id == value?.profession
                    )

                    const religion = getAllReligion?.find(
                      x => x.id == value?.religion
                    )

                    const salutationExist = Salutations?.find(
                      x => x.id == value?.salutation
                    )

                    setContactId(value?.contactId)

                    // Address
                    if (PrincipalBuyerCheck == true) {
                      const address = value?.address?.address
                      const city = value?.address?.city
                      const country = getCountry?.find(
                        x => x?.name == value?.address?.country
                      )
                      const postCode = value?.address?.postCode
                      const state = getCountry?.find(
                        x => x?.name == value?.address?.state
                      )
                      if (address) {
                        let array_of_address = [address] || []
                        setQuery(array_of_address.join(', '))
                        setOGAddr(array_of_address)
                        setAddress(array_of_address?.slice(0))
                        initializeEditValue({
                          address: value?.address?.address,
                          country: value?.address?.country,
                          state: value?.address?.state,
                          city: value?.address?.city,
                          postCode: value?.address?.postCode,
                        })
                      }
                    }

                    const iType = value?.identityType

                    setValue('IdentityType', iType)

                    setValue('IdentityNo', value?.identityNo ?? '')
                    setValue('MaritalStatus', maritalStatusExist ?? null)
                    setValue('Gender', value?.gender ?? '')
                    setValue('Email', value?.email ?? '')
                    setValue('MobileNo', value?.otherContactNumber ?? '')
                    setValue('Salutation', salutationExist ?? null)
                    setValue('Nationality', nationalityExist ?? null)
                    setValue('Religion', religion ?? null)
                    setValue('TaxAddress', value?.taxRegistrationAddress ?? '')
                    setValue('Profession', profession ?? null)
                    setValue('GstRegNo', value?.taxRegistrationNo ?? '')
                    setValue('NameTax', value?.nameOnTax ?? '')
                    // let addressListingExist = value?.address?.map(el => {
                    //   append({
                    //     addressId: el.id ?? null,
                    //     isPrimary: el.isPrimary ?? null,
                    //     addressType: el.AddressType ?? null,
                    //     address: el ?? null,
                    //   })
                    // })
                    // let addressListingExist = ({
                    //   addressType: value?.address?.addressType ?? null,
                    //   address: value?.address,
                    //   addressId: null,
                    //   isPrimary: value?.address?.isPrimary ?? null
                    // })
                    // setValue('addressList', addressListingExist)

                    clearErrors()
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      // select
                      label="Name as per ID"
                      required
                      variant="standard"
                      margin="normal"
                      value={value}
                      autoComplete="off"
                      helperText={errors?.Name?.message}
                      error={errors?.Name ? true : false}
                    />
                  )}
                />
              )}
            />

            <Controller
              control={control}
              name="Salutation"
              render={({ field: { onChange, onBlur, value } }) => (
                <Autocomplete
                  options={Salutations}
                  getOptionLabel={option => option?.['name']}
                  fullWidth
                  value={value}
                  // disabled={detailId ? true : false}
                  onChange={(event, value) => {
                    onChange(value)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Salutation"
                      required
                      variant="standard"
                      margin="normal"
                      value={value}
                      autoComplete="off"
                      helperText={errors?.Salutation?.message}
                      error={errors?.Salutation ? true : false}
                    />
                  )}
                />
              )}
            />

            <Box display="flex" width="100%">
              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="MaritalStatus"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Autocomplete
                      options={MaritalStatus}
                      // disabled={detailId ? true : false}
                      getOptionLabel={option => option?.['name']}
                      fullWidth
                      onChange={(event, value) => {
                        onChange(value)
                      }}
                      value={value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Marital Status"
                          required
                          variant="standard"
                          margin="normal"
                          value={value}
                          autoComplete="off"
                          helperText={errors?.MaritalStatus?.message}
                          error={errors?.MaritalStatus ? true : false}
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="Gender"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      select
                      variant="standard"
                      fullWidth
                      margin="normal"
                      // disabled={detailId ? true : false}
                      onBlur={onBlur}
                      onChange={onChange}
                      autoComplete="off"
                      name="Gender"
                      label="Gender"
                      required
                      value={value}
                      helperText={errors?.Gender?.message}
                      error={errors?.Gender ? true : false}
                    >
                      {Genders &&
                        Object.keys(Genders)?.map(el => (
                          <MenuItem value={el}>{Genders[el]}</MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Box>
            </Box>

            {/* <Controller
              control={control}
              name="CustomerOrigin"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  select
                  variant="standard"
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  onChange={onChange}
                  autoComplete="off"
                  name="CustomerOrigin"
                  label="Customer Origin"
                  required
                  value={value}
                  helperText={errors?.CustomerOrigin?.message}
                  error={errors?.CustomerOrigin ? true : false}
                >
                  {CustomerOriginListing?.map(v => (
                    <MenuItem value={v.ID}>{v.Name}</MenuItem>
                  ))}
                </TextField>
              )}
            /> */}

            <Controller
              control={control}
              name="Email"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  // disabled={detailId ? true : false}
                  onChange={onChange}
                  autoComplete="off"
                  name="Email"
                  label="Email"
                  required
                  value={value}
                  helperText={errors?.Email?.message}
                  error={errors?.Email ? true : false}
                />
              )}
            />

            <Box display="flex" width="100%">
              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="Profession"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Autocomplete
                      options={getAllProfession}
                      getOptionLabel={option => option?.['name']}
                      fullWidth
                      // disabled={detailId ? true : false}
                      onChange={(event, value) => {
                        onChange(value)
                      }}
                      value={value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Profession"
                          required
                          variant="standard"
                          margin="normal"
                          value={value}
                          autoComplete="off"
                          helperText={errors?.Profession?.message}
                          error={errors?.Profession ? true : false}
                        />
                      )}
                    />
                  )}
                />
              </Box>

              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="Religion"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Autocomplete
                      options={getAllReligion}
                      getOptionLabel={getAllReligion =>
                        getAllReligion?.['name']
                      }
                      fullWidth
                      // disabled={detailId ? true : false}
                      onChange={(event, value) => {
                        onChange(value)
                      }}
                      value={value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Religion"
                          required
                          variant="standard"
                          margin="normal"
                          value={value}
                          autoComplete="off"
                          helperText={errors?.Religion?.message}
                          error={errors?.Religion ? true : false}
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Box>
            <Controller
                control={control}
                name="address"
                render={({ field: { onChange, onBlur, value } }) => (
                  <AddressAutocomplete 
                    sections={{
                      address: {
                        props: {
                          inputRef: autoCompleteRef,
                          label: 'Address',
                          variant: 'standard',
                          autoComplete: 'off',
                          name: 'address',
                          margin: 'normal',
                          value: query,
                          // required,
                          multiline: true,
                          onChange: e => handleGoogleOnChange(e.target.value),
                          style: { width: '100%', display: PrincipalBuyerCheck == true ? 'block' : 'none' },
                          helperText:
                            isSubmitted && !query
                              ? 'Address is required'
                              : null,
                          error: isSubmitted && !query ? true : false,
                        },
                      },
                      country: {
                        props: {
                          value: {
                            name: state?.country?.name ? state?.country?.name : '',
                            // : findDetailId?.address?.country,
                          },
                          autoComplete: 'off',
                          options: getCountry || [],
                          style: { width: '50%',display: PrincipalBuyerCheck == true ? 'block' : 'none' },
                          //filterOptions: filterOptions,
                          onChange: (event, newValue) => {
                            if (newValue) {
                              handleCountryOnChange(newValue)
                            }
                          },
                          onInputChange: (e, newValue) => {
                            handleCountryOnChange({ name: newValue })
                          },
                          getOptionLabel: (option: any) => option?.name || '',
                          renderInput: params => {
                            return (
                              <TextField
                                {...params}
                                //value={state.country}
                                label="Country *"
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                helperText={
                                  isSubmitted && !state?.country?.name
                                    ? // !state?.country?.name &&
                                      // !editData?.address?.country
                                      'Country is required'
                                    : null
                                  // errors?.country?.message
                                }
                                error={
                                  isSubmitted && !state?.country?.name
                                    ? // !state?.country?.name &&
                                      // !editData?.address?.country
                                      true
                                    : false
                                }
                              />
                            )
                          },
                        },
                      },
                      state: {
                        props: {
                          value: {
                            name: state?.state?.name
                              ? state?.state?.name
                              : // : ''
                                findDetailId?.address?.state,
                          },

                          options: getStatebyCountry || [],
                          style: { width: '50%', display: PrincipalBuyerCheck == true ? 'block' : 'none' },
                          getOptionLabel: option => option?.name || '',
                          onChange: (e, newValue) => {
                            if (newValue) {
                              handleStateOnChange(
                                newValue || { name: e.target.value }
                              )
                            }
                          },
                          onInputChange: (e, newValue) => {
                            handleStateOnChange({ name: newValue })
                          },
                          renderInput: params => {
                            return (
                              <TextField
                                {...params}
                                value={state?.state}
                                style={{ width: '100%', paddingLeft: '4px' }}
                                label="State *"
                                variant="standard"
                                autoComplete="off"
                                margin="normal"
                                helperText={
                                  isSubmitted && !state?.state?.name
                                    ? 'State is required'
                                    : null
                                }
                                error={
                                  isSubmitted && !state?.state?.name
                                    ? true
                                    : false
                                }
                              />
                            )
                          },
                        },
                      },
                      city: {
                        props: {
                          // className: `left`,
                          value: {
                            name: state?.city?.name
                              ? state?.city?.name
                              : // : ''
                                findDetailId?.address?.city,
                          },
                          options: getCitybyState || [],
                          style: { width: '50%', display: PrincipalBuyerCheck == true ? 'block' : 'none' },
                          getOptionLabel: option => option?.name || '',
                          onChange: (e, newValue) => {
                            if (newValue) {
                              handleCityOnChange(newValue)
                            }
                          },
                          onInputChange: (e, newValue) => {
                            handleCityOnChange({ name: newValue })
                          },
                          renderInput: params => {
                            return (
                              <TextField
                                {...params}
                                // onChange={(e: any, newValue) =>
                                //   handleCityOnChange({ name: e.target.value })
                                // }
                                value={state?.city}
                                label="City *"
                                variant="standard"
                                autoComplete="off"
                                margin="normal"
                                style={{ width: '100%' }}
                                helperText={
                                  isSubmitted && !state?.city?.name
                                    ? // !state?.city?.name &&
                                      // !editData?.address?.city
                                      'City is required'
                                    : null
                                  // errors?.country?.message
                                }
                                error={
                                  isSubmitted && state?.city?.name
                                    ? // !state?.city?.name &&
                                      // !editData?.address?.city
                                      true
                                    : false
                                }
                              />
                            )
                          },
                        },
                      },
                      postCode: {
                        props: {
                          value: {
                            postcode: state?.postcode?.postcode
                              ? state?.postcode?.postcode
                              : // : ''
                                findDetailId?.address?.postCode,
                          },
                          options: getPostcodebyCity || [],
                          style: { width: '50%', display: PrincipalBuyerCheck == true ? 'block' : 'none' },
                          getOptionLabel: option => option?.postcode || '',
                          onChange: (e, newValue) => {
                            if (newValue) {
                              handlePostCodeOnChange(newValue)
                            }
                          },
                          onInputChange: (e, newValue) => {
                            handlePostCodeOnChange({ postcode: newValue })
                          },
                          renderInput: params => {
                            return (
                              <TextField
                                {...params}
                                /*onChange={(e: any, newValue) => {
                            handlePostCodeOnChange(
                              newValue || { postCode: e.target.value },
                            );
                          }}*/
                                value={state?.postcode}
                                style={{ width: '100%', paddingLeft: '4px' }}
                                label="Postcode *"
                                autoComplete="off"
                                variant="standard"
                                margin="normal"
                                helperText={
                                  isSubmitted && !state?.postcode?.postcode
                                    ? // !state?.postcode?.postcode &&
                                      // !editData?.address?.postCode
                                      'Postcode is required'
                                    : null
                                  // errors?.country?.message
                                }
                                error={
                                  isSubmitted && !state?.postcode?.postcode
                                    ? // !state?.postcode?.postcode &&
                                      // !editData?.address?.postCode
                                      true
                                    : false
                                }
                              />
                            )
                          },
                        },
                      },
                    }}
                  />
                )}
            />
            <Box width="100%" display="flex">
              <Box width="50%" pr="8px">
                <Controller
                  control={control}
                  name="HomeTelNo"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <MuiPhoneNumber
                      autoComplete="off"
                      disableAreaCodes={true}
                      defaultCountry={'id'}
                      variant="standard"
                      fullWidth
                      margin="normal"
                      onBlur={onBlur}
                      onChange={onChange}
                      name="HomeTelNo"
                      label="Home Tel No."
                      // required
                      value={value}
                      type="tel"
                      // helperText={errors?.HomeTelNo?.message}
                      // error={errors?.HomeTelNo ? true : false}
                    />
                  )}
                />
              </Box>

              <Box width="50%" pr="8px">
                <Controller
                  control={control}
                  name="MobileNo"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <MuiPhoneNumber
                      autoComplete="off"
                      disableAreaCodes={true}
                      defaultCountry={'id'}
                      variant="standard"
                      fullWidth
                      // disabled={detailId ? true : false}
                      margin="normal"
                      onBlur={onBlur}
                      onChange={onChange}
                      name="MobileNo"
                      label="Mobile No."
                      required
                      value={value}
                      type="tel"
                      helperText={errors?.MobileNo?.message}
                      error={errors?.MobileNo ? true : false}
                    />
                  )}
                />
              </Box>
            </Box>

            <Controller
              control={control}
              name="OfficeTelNo"
              render={({ field: { onChange, onBlur, value } }) => (
                <MuiPhoneNumber
                  autoComplete="off"
                  disableAreaCodes={true}
                  defaultCountry={'id'}
                  variant="standard"
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  onChange={onChange}
                  name="OfficeTelNo"
                  label="Office Tel No."
                  // required
                  value={value}
                  type="tel"
                  // helperText={errors?.OfficeTelNo?.message}
                  // error={errors?.OfficeTelNo ? true : false}
                />
              )}
            />

            <FileUploadTextField
              label="Upload Attachment"
              accept="image/gif,image/png,image/jpg,image/jpeg,application/pdf"
              autoComplete="off"
              multiple
              value={files}
              onChange={e => handleUploadChange(e)}
              filePreview={
                <>
                  {previewFiles?.map((file, index) => (
                    <FilePreviewCard
                      key={index}
                      label={files[index]?.name}
                      badge={{
                        onClick: () => {
                          handleRemove(index)
                        },
                      }}
                    >
                      {files[index]?.type?.includes('pdf') ? (
                        <PictureAsPdfSharp style={{ color: '#e2574c' }} />
                      ) : (
                        <Avatar variant="square" src={file} />
                      )}
                    </FilePreviewCard>
                  ))}
                </>
              }
            />
            {/* <Box width="100%" display="flex" mt="-20px"> */}
            <Controller
              control={control}
              name="GstRegNo"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Tax Registration No."
                  name="GstRegNo"
                  autoComplete="off"
                  // required
                  error={errors?.GstRegNo ? true : false}
                  helperText={errors?.GstRegNo?.message}
                  onChange={e => {
                    let formattedValue = formatInput(e.target.value)

                    // Batasi jumlah karakter maksimum
                    if (formattedValue.length > maxCharacters) {
                      formattedValue = formattedValue.slice(0, maxCharacters)
                    }

                    // Periksa jika pengguna menghapus karakter "." atau "-"
                    if (e.target.value.length > formattedValue.length) {
                      formattedValue = formattedValue.slice(0, -1)
                    }

                    onChange(formattedValue)
                  }}
                  inputProps={{ maxLength: maxCharacters }}
                  onBlur={onBlur}
                  value={value}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  //required
                />
              )}
            />
            {/* </Box> */}
            {/* <Box width="100%" display="flex" mt="-15px"> */}
            <Controller
              control={control}
              name="NameTax"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  onChange={onChange}
                  autoComplete="off"
                  name="NameTax"
                  label="Name on Tax Registration"
                  // required
                  value={value}
                  helperText={errors?.NameTax?.message}
                  error={errors?.NameTax ? true : false}
                />
              )}
            />
            {/* </Box> */}
            {/* <Box width="100%" display="flex" mt="-15px"> */}
            <Controller
              control={control}
              name="TaxAddress"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  onChange={onChange}
                  autoComplete="off"
                  name="TaxAddress"
                  label="Tax Registration Address"
                  // required
                  value={value}
                  helperText={errors?.TaxAddress?.message}
                  error={errors?.TaxAddress ? true : false}
                />
              )}
            />
            {/* </Box> */}
            <Typography
              className="text-xlTitle"
              color="common.black"
              component="span"
              flex="1 1"
              variant="inherit"
            >
              Mailing Method
            </Typography>

            <Box width="100%" display="flex">
              <Box pr="6px" width="50%">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Email"
                      color="primary"
                      onChange={e => {
                        setEmailCheck(e.target.checked)
                        if (e.target.checked) {
                          setLetterCheck(false)
                        }
                      }}
                      checked={EmailCheck}
                    />
                  }
                  label="Email"
                />
              </Box>
              <Box pr="6px" width="50%">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Letter"
                      color="primary"
                      onChange={e => {
                        setLetterCheck(e.target.checked)
                        if (e.target.checked) {
                          setEmailCheck(false)
                        }
                      }}
                      checked={LetterCheck}
                    />
                  }
                  label="Letter"
                />
              </Box>
            </Box>
            <Box width="100%">
              <FormControlLabel
                control={
                  <Checkbox
                    name="SubscribeMarketing"
                    color="primary"
                    onChange={e => checkSubscribe(e.target.checked)}
                    checked={SubscribeMarketingCheck}
                  />
                }
                label="Allowed Sending Of Marketing Material"
              />
            </Box>

            <Box width="100%" marginLeft="2%">
              <FormControlLabel
                control={
                  <Checkbox
                    name="SMS"
                    color="primary"
                    onChange={e => {
                      setSMSCheck(e.target.checked)
                      checkAllSubscribe(
                        e.target.checked,
                        WhatsappCheck,
                        EmailBlastCheck,
                        PurchaserAppCheck
                      )
                    }}
                    checked={SMSCheck}
                  />
                }
                label="SMS"
              />
            </Box>

            <Box width="100%" marginLeft="2%">
              <FormControlLabel
                control={
                  <Checkbox
                    name="Whatsapp"
                    color="primary"
                    onChange={e => {
                      setWhatsappCheck(e.target.checked)
                      checkAllSubscribe(
                        e.target.checked,
                        SMSCheck,
                        EmailBlastCheck,
                        PurchaserAppCheck
                      )
                    }}
                    checked={WhatsappCheck}
                  />
                }
                label="Whatsapp"
              />
            </Box>

            <Box width="100%" marginLeft="2%">
              <FormControlLabel
                control={
                  <Checkbox
                    name="EmailBlast"
                    color="primary"
                    onChange={e => {
                      setEmailBlastCheck(e.target.checked)
                      checkAllSubscribe(
                        e.target.checked,
                        SMSCheck,
                        WhatsappCheck,
                        PurchaserAppCheck
                      )
                    }}
                    checked={EmailBlastCheck}
                  />
                }
                label="Email Blast"
              />
            </Box>

            <Box width="100%" marginLeft="2%">
              <FormControlLabel
                control={
                  <Checkbox
                    name="PurchaserApp"
                    color="primary"
                    onChange={e => {
                      setPurchaserAppCheck(e.target.checked)
                      checkAllSubscribe(
                        e.target.checked,
                        SMSCheck,
                        WhatsappCheck,
                        EmailBlastCheck
                      )
                    }}
                    checked={PurchaserAppCheck}
                  />
                }
                label="Purchaser App > News"
              />
            </Box>

            <Controller
              control={control}
              name="Remark"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Remarks"
                  name="Remark"
                  autoComplete="off"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              )}
            />
          </Card>
          <div className="addres-card-list">
            <CardListHeader
              title={
                <Typography
                  className="text-xsTitle"
                  color="#FF9800"
                  component="span"
                  width="100%"
                  flex="1 1"
                  variant="inherit"
                >
                  Address Listing
                </Typography>
              }
              action={
                <IconButton
                  aria-label="Addbox"
                  style={{
                    padding: '0px',
                  }}
                  onClick={() => {
                    if (fields?.length < 4) {
                      setAddressDialog(true)
                      setDialogMode('Add')
                    } else {
                      setSnackBar(SystemMsgs.addressLimit())
                    }
                  }}
                >
                  <AddBox color="primary" />
                </IconButton>
              }
            >
              {fields.length === 0 ? (
                <ListItemText
                  className="listingPadding"
                  primary={
                    <Typography
                      flex="1 1"
                      component="span"
                      variant="inherit"
                      className="text-xsTitle"
                    >
                      No Address Added
                    </Typography>
                  }
                  secondary={
                    <Typography
                      flex="1 1"
                      component="span"
                      variant="inherit"
                      className="text-xsTitle"
                    >
                      Click add button to add address
                    </Typography>
                  }
                />
              ) : (
                fields?.map((el, index) => (
                  <CardListItem
                    id={el.id}
                    itemButton={
                      <>
                        {/* <Switch
                          checked={activeListingIndex === index}
                          onChange={() =>
                            setActiveListingIndex(prevIndex =>
                              prevIndex === index ? null : index
                            )
                          }
                        /> */}
                        <IconButton
                          edge="end"
                          aria-label="more"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          style={{
                            padding: '0px 12px',
                          }}
                          onClick={e =>
                            handleClick(e, el?.address?.AddressType, index, el)
                          }
                        >
                          <MoreVert />
                        </IconButton>
                      </>
                    }
                    body={
                      <ListItemText
                        primary={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              flex="1 1"
                              component="span"
                              variant="inherit"
                              className="text-xsTitle"
                            >
                              {el?.address?.AddressType}
                            </Typography>
                            <Typography
                              className="text-xsTitle"
                              color="primary"
                            >
                              {el?.isPrimary ? 'Primary' : ''}
                            </Typography>
                          </div>
                        }
                        secondary={
                          <Typography
                            flex="1 1"
                            component="span"
                            variant="inherit"
                            className="text-xsTitle"
                          >
                            {el?.address?.address} {el?.address?.city}{' '}
                            {el?.address?.country}
                          </Typography>
                        }
                      />
                    }
                  />
                ))
              )}
            </CardListHeader>
          </div>
          {/* </Card> */}

          <Menu
            id="menu-list"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem
              onClick={() => {
                setAddressDialog(true)
                setDialogMode('Edit')
              }}
            >
              <span className="">Edit</span>{' '}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDeleteDialog(true)
              }}
            >
              <span className="">Delete</span>
            </MenuItem>
          </Menu>
        </Container>
      </form>

      <Footer
        buttons={[
          {
            children: 'Save',
            color: 'primary',
            onClick: handleSubmit(onSubmit),
          },
        ]}
      />
    </>
  )
}

export default PurchaserTransferBuyerForm
