import { lazy } from 'react'
import { RouteObject } from 'react-router'

const PAIndoLedgerCreditNoteListing = lazy(
  () => import('./PAIndoLedgerCreditNoteListing')
)
const PAIndoLedgerCreditNoteForm = lazy(
  () => import('./PAIndoLedgerCreditNoteForm')
)
const PAIndoLedgerCreditNoteDetails = lazy(
  () => import('./PAIndoLedgerCreditNoteDetails')
)

const CreditNoteRoutes: RouteObject[] = [
  {
    path: ':saleID/credit-note',
    children: [
      {
        path: '',
        element: <PAIndoLedgerCreditNoteListing />,
      },
      {
        path: 'add',
        element: <PAIndoLedgerCreditNoteForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoLedgerCreditNoteForm mode="edit" />,
      },
      {
        path: ':id/view',
        element: <PAIndoLedgerCreditNoteDetails />,
      },
    ],
  },
]

export default CreditNoteRoutes
