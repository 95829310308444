import {
  Card,
  Container,
  Spinner,
  SubHeaderContainer,
  Subheader,
  SubheaderText,
} from '@ifca-ui/core'
import { MonetizationOnRounded } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import { CustomSubheader } from 'components/General/CustomSubheader'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import ColumnFlex from 'components/Layout/ColumnLayout'
import { RowFlex } from 'components/Layout/RowLayout'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { AttachmentsListComponent } from 'containers/modules/PurchaserAccounts/Components/AttachmentsListComponent'
import {
  useGetPaIndoDepositByIdQuery,
  useGetCreditCardTypeDescQuery,
  useGetPaymentMethodDescQuery,
  PaymentMethod,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import { amtStr } from 'helpers/numFormatter'
import { useContext, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

const PAIndoDepositDetail = (props: any) => {
  const subHeaderRef = useRef(null)
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const { projectID, id }: any = useParams()
  let navigate = useNavigate()
  const { navBack, navTo } = useNav()
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  //#region API

  const {
    loading: getPAIndoDepositByIdLoading,
    data: { getPAIndoDepositById } = { getPAIndoDepositById: null },
  } = useGetPaIndoDepositByIdQuery({
    variables: { projectId: projectID, id: id },
    onCompleted: data => {
      if (subHeaderRef.current?.clientHeight) {
        rootDispatch({
          type: 'subHeaderHeight',
          payload: subHeaderRef.current?.clientHeight,
        })
      }

      const name = data.getPAIndoDepositById?.projectDetail?.name ?? '-'

      rootDispatch({
        type: 'headerComponent',
        payload: {
          ...rootState.headerComponent,
          leftIcon: {
            icon: 'back',
            props: {
              onClick: () =>
                nextPath(`/${projectID}/purchaser-accounts-indo/deposit`),
            },
          },
          rightIcon: {},
          topSection: {
            smTitle: TopSectionHeader,
            title: name,
          },
          bottomSection: {
            breadcrumbs: {
              maxItems: 4,
              current: 'Deposit',
            },
            rightText: 'Detail',
          },
        },
      })
    },
  })

  const {
    loading: paymentMethodLoading,
    error: paymentMethodError,
    data: { getPaymentMethodDesc } = { getPaymentMethodDesc: null },
  } = useGetPaymentMethodDescQuery({})

  //#endregion

  const splitPaymentMethod = x => {
    if (!!getPaymentMethodDesc) {
      const keys = Object.keys(x)
      const arr = []
      for (let i = 0; i < keys.length; i++) {
        arr.push({
          code: keys[i],
          name: x[keys[i]],
        })
      }
      return arr
    }
  }
  let paymentMethodList = splitPaymentMethod(getPaymentMethodDesc)
  let salePaymentMethod = paymentMethodList?.find(
    el => el.code == getPAIndoDepositById?.paymentMethod
  )?.name

  const {
    loading: creditCardTypeLoading,
    error: creditCardTypeError,
    data: { getCreditCardTypeDesc } = { getCreditCardTypeDesc: null },
  } = useGetCreditCardTypeDescQuery({})
  //#endregion

  const splitCreditCardType = x => {
    if (!!getCreditCardTypeDesc) {
      const keys = Object.keys(x)
      const arr = []
      for (let i = 0; i < keys.length; i++) {
        arr.push({
          code: keys[i],
          name: x[keys[i]],
        })
      }
      return arr
    }
  }
  let creditCardTypeList = splitCreditCardType(getCreditCardTypeDesc)

  const nextPath = path => {
    navTo(path, 'APPROVED')
  }

  return (
    <>
      {getPAIndoDepositByIdLoading && <Spinner />}
      {creditCardTypeLoading && <Spinner />}
      {paymentMethodLoading && <Spinner />}

      <SubHeaderContainer id="subheader-container" ref={subHeaderRef}>
        <Subheader>
          <SubheaderText
            primary={
              <ColumnFlex gap="1px">
                <RowFlex mainAxisAlignment="SPACE_BETWEEN">
                  <Typography
                    className="text-xsTitle"
                    color="primary"
                    style={{ fontSize: '11px' }}
                  >
                    {formatDate(getPAIndoDepositById?.documentDate)}
                  </Typography>

                  <Typography
                    className="text-xsTitle"
                    color="primary"
                    style={{ fontSize: '11px' }}
                  >
                    {getPAIndoDepositById?.documentNo}
                  </Typography>

                  <RowFlex>
                    {/* <Typography className="text-desc text-icon" color="primary">
                      <MonetizationOnRounded />
                    </Typography> */}

                    <Typography
                      className="text-xsTitle"
                      color="primary"
                      style={{ fontSize: '11px' }}
                    >
                      {amtStr(getPAIndoDepositById?.documentAmount)}
                    </Typography>
                  </RowFlex>
                </RowFlex>
              </ColumnFlex>
            }
            secondary={
              <ColumnFlex height="10px">
                <RowFlex mainAxisAlignment="SPACE_BETWEEN" width="100%">
                  <Typography className="text-desc">
                    {getPAIndoDepositById?.clusterName?.name}/
                    {getPAIndoDepositById?.unitDetail}{' '}
                    {getPAIndoDepositById?.contactDetail}
                  </Typography>

                  <RowFlex>
                    {/* <Typography className="text-desc text-icon" color="red">
                      <MonetizationOnRounded />
                    </Typography> */}

                    <Typography
                      className="text-xsTitle"
                      color="red"
                      style={{ fontSize: '11px' }}
                    >
                      {amtStr(getPAIndoDepositById?.unallocatedAmount)}
                    </Typography>
                  </RowFlex>
                </RowFlex>
              </ColumnFlex>
            }
          />
        </Subheader>
      </SubHeaderContainer>
      <Box height={`${rootState?.subHeaderHeight}px`} />
      <Container>
        <Card className="card-container-detail">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className="label-f">Payment Method</Typography>
              <Typography className="value-f">{salePaymentMethod}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Reference No.</Typography>
              <Typography className="value-f">
                {getPAIndoDepositById?.documentRef != ''
                  ? getPAIndoDepositById?.documentRef
                  : `-`}
              </Typography>
            </Grid>
            {getPAIndoDepositById?.paymentMethod == PaymentMethod.Cheque ? (
              <>
                <Grid item xs={6}>
                  <Typography className="label-f">Cheque Date</Typography>
                  <Typography className="value-f">
                    {getPAIndoDepositById?.chequeDate
                      ? formatDate(getPAIndoDepositById?.chequeDate)
                      : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="label-f">
                    Cheque Expiry Date
                  </Typography>
                  <Typography className="value-f">
                    {getPAIndoDepositById?.chequeExpireDate
                      ? formatDate(getPAIndoDepositById?.chequeExpireDate)
                      : '-'}
                  </Typography>
                </Grid>
              </>
            ) : getPAIndoDepositById?.paymentMethod ==
              PaymentMethod.CreditCard ? (
              <>
                <Grid item xs={12}>
                  <Typography className="label-f">Credit Card Type</Typography>
                  <Typography className="value-f">
                    {
                      creditCardTypeList?.find(
                        el => el.code == getPAIndoDepositById?.creditCardType
                      )?.name
                    }
                  </Typography>
                </Grid>
              </>
            ) : null}
            <Grid item xs={12}>
              <Typography className="label-f">Deposit Type</Typography>
              <Typography className="value-f">
                {getPAIndoDepositById?.billItemInt?.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="label-f">Deposit Amount</Typography>
              <Typography className="value-f">
                {amtStr(getPAIndoDepositById?.documentAmount)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="label-f">Company Bank Account</Typography>
              <Typography className="value-f">
                {getPAIndoDepositById?.bankAccount
                  ? `${getPAIndoDepositById?.bankAccount?.bankProfile?.name} - ${getPAIndoDepositById?.bankAccount?.accountNo}`
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="label-f">Description</Typography>
              <Typography className="value-f">
                {getPAIndoDepositById?.description ?? '-'}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography className="label-f">Attachments</Typography>
              <Typography
                className="value-f text-underline text-xsTitle text-pointer"
                onClick={e => setOpenDialog(true)}
              >
                {getPAIndoDepositById?.attachment.length}
              </Typography>
            </Grid>
            <AttachmentsListComponent
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              listData={getPAIndoDepositById?.attachment}
            />
            <Grid item xs={6}>
              <Typography className="label-f">Submitted By</Typography>
              <Typography className="value-f">
                {getPAIndoDepositById?.submitUserData?.name ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Submitted Date</Typography>
              <Typography className="value-f">
                {getPAIndoDepositById?.submittedDate
                  ? formatDate(getPAIndoDepositById?.submittedDate)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Approved By</Typography>
              <Typography className="value-f">
                {getPAIndoDepositById?.approveUserData?.name ?? '-'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography className="label-f">Approved Date</Typography>
              <Typography className="value-f">
                {getPAIndoDepositById?.approvedDate
                  ? formatDate(getPAIndoDepositById?.approvedDate)
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </>
  )
}

export default PAIndoDepositDetail
