import { lazy } from 'react'
import { RouteObject } from 'react-router'

const PAIndoLedgerDepositListing = lazy(
  () => import('./PAIndoLedgerDepositListing')
)
const PAIndoLedgerDepositForm = lazy(() => import('./PAIndoLedgerDepositForm'))
const PAIndoLedgerDepositDetails = lazy(
  () => import('./PAIndoLedgerDepositDetails')
)

const DepositRoutes: RouteObject[] = [
  {
    path: ':saleID/deposit',
    children: [
      {
        path: '',
        element: <PAIndoLedgerDepositListing />,
      },
      {
        path: 'add',
        element: <PAIndoLedgerDepositForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoLedgerDepositForm mode="edit" />,
      },
      {
        path: ':id/view',
        element: <PAIndoLedgerDepositDetails />,
      },
    ],
  },
]

export default DepositRoutes
