import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const LateInterestGenerationListing = lazy(
  () => import('./LateInterestGenerationListing')
)
const LateInterestGenerationDetail = lazy(
  () => import('./LateInterestGenerationDetail')
)
const LateInterestGenerationForm = lazy(
  () => import('./LateInterestGenerationForm')
)

const LatePaymentInterestGenerationRoutes: RouteObject[] = [
  {
    path: 'late-payment-interest-generation',
    children: [
      {
        path: '',
        element: <LateInterestGenerationListing />,
      },
      {
        path: 'add',
        element: <LateInterestGenerationForm mode="add" />,
      },
      {
        path: 'edit',
        element: <LateInterestGenerationForm mode="edit" />,
      },
      {
        path: 'submit',
        element: <LateInterestGenerationDetail mode="submit" />,
      },
      {
        path: 'approve',
        element: <LateInterestGenerationDetail mode="approve" />,
      },
    ],
  },
]

export default LatePaymentInterestGenerationRoutes
