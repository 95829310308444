import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const LoanApprovalListing = lazy(() => import('./LoanApprovalListing'))
const LoanApprovalForm = lazy(() => import('./LoanApprovalForm'))
const LoanApprovalSubmittedListing = lazy(
  () => import('./LoanApprovalSubmittedListing')
)
const LoanApprovalSubmittedDetails = lazy(
  () => import('./LoanApprovalSubmittedDetails')
)

export const LOAN_APPROVAL_PATH = {
  LOAN_APPROVAL_LISTING: './:projectID/loan-approval-listing',
  LOAN_APPROVAL_FORM: './:projectID/loan-approval-listing/add/:saleloanID',
  LOAN_APPROVAL_SUBMITTED_LISTING:
    './:projectID/loan-approval-listing/submitted',
  LOAN_APPROVAL_SUBMITTED_DETAILS:
    './:projectID/loan-approval-listing/submitted/details/:saleloanID',
}

const LoanApprovalRoutes: RouteObject[] = [
  {
    path: 'loan-approval-listing',
    children: [
      {
        path: '',
        element: <LoanApprovalListing />,
      },
      {
        path: 'add/:saleloanID',
        element: <LoanApprovalForm />,
      },
      {
        path: 'add/',
        element: <LoanApprovalForm />,
      },
      {
        path: 'submitted',
        element: <LoanApprovalSubmittedListing />,
      },
      {
        path: 'submitted/details/:saleloanID',
        element: <LoanApprovalSubmittedDetails />,
      },
    ],
  },
]

export default LoanApprovalRoutes
