import { lazy } from 'react'
import { RouteObject } from 'react-router'

const PAIndoLedgerMiscBillingListing = lazy(
  () => import('./PAIndoLedgerMiscBillingListing')
)
const PAIndoLedgerMiscBillingForm = lazy(
  () => import('./PAIndoLedgerMiscBillingForm')
)
const PAIndoLedgerMiscBillingDetails = lazy(
  () => import('./PAIndoLedgerMiscBillingDetails')
)

const MiscBillingRoutes: RouteObject[] = [
  {
    path: ':saleID/misc-billing',
    children: [
      {
        path: '',
        element: <PAIndoLedgerMiscBillingListing />,
      },
      {
        path: 'add',
        element: <PAIndoLedgerMiscBillingForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoLedgerMiscBillingForm mode="edit" />,
      },
      {
        path: ':id/view',
        element: <PAIndoLedgerMiscBillingDetails />,
      },
    ],
  },
]

export default MiscBillingRoutes
