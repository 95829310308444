import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const BuyBackDraftListing = lazy(() => import('./BuyBackDraftListing'))
const BuyBackDraftForm = lazy(() => import('./BuyBackDraftForm'))
const BuyBackSubmittedListing = lazy(() => import ('./BuyBackSubmittedListing'))
const BuyBackSubmittedDetails = lazy(() => import('./BuyBackSubmittedDetails'))
const BuyBackApprovedListing = lazy(() => import('./BuyBackApprovedListing'))
const BuyBackApprovedDetails = lazy(() => import('./BuyBackApprovedDetails'))

export const BUY_BACK_PATH = {
    BUYBACK_DRAFT_LISTING: './:projectId/sale-loan-buyback',
    BUYBACK_DRAFT_FORM: './:projectId/buyback-draft-listing/add',
    BUYBACK_SUBMITTED_LISTING: './:projectId/buyback-submitted-listing',
    BUYBACK_SUBMITTED_DETAILS: './:projectId/buyback-submitted-listing/details',
    BUYBACK_APPROVED_LISTING: './:projectId/buyback-approved-listing',
    BUYBACK_APPROVED_DETAILS: './:projectId/buyback-approved-listing/details'
}

const BuyBackRoutes : RouteObject [] = [
    {
        path:'sale-loan-buyback',
        children: [
            {
                path:'',
                element: <BuyBackDraftListing/>
            },
            {
                path:'add',
                element:<BuyBackDraftForm mode="add"/>
            },
            {
                path:'edit/:id',
                element:<BuyBackDraftForm mode="edit"/>
            },
            {
                path:'details/:id/:approvalStatus',
                element:<BuyBackSubmittedDetails/>
            },
        ]
    }
]

export default BuyBackRoutes