import { ColumnFlex, Container, EmptyState } from '@ifca-ui/core'
import useAppData from 'components/General/useAppData'

import { SalesContractSubheader } from 'components/General/SalesContractSubheader'
import { useGetNewPBdataQuery } from 'generated/graphql'
import { useSalesContractById } from './PBcomponent/PAprogressBillingBloc'
import { PBsaleProductList } from './PBcomponent/PBsaleProductList'
import { PBtypeList } from './PBcomponent/PBtypeList'
import usePBheader from './PBcomponent/usePBheader'

interface props {
  isPA: boolean
}

const PAprogressBillingListing = ({ isPA }: props) => {
  const { rootState, subHeaderRef, rootDispatch, saleID } = useAppData()
  const { salesContract } = useSalesContractById()
  usePBheader(isPA)

  const {
    data: { getNewPBdata } = { getNewPBdata: {} },
    refetch: refetchData,
  } = useGetNewPBdataQuery({
    variables: { saleId: saleID },
  })

  return (
    <>
      <SalesContractSubheader getSalesContractById={salesContract} />
      <Container>
        {getNewPBdata && (
          <ColumnFlex gap={'5px'}>
            {getNewPBdata['sale_progress_billing'] && (
              <PBsaleProductList
                isPA={isPA}
                pbListData={getNewPBdata['sale_progress_billing']}
                refetchData={refetchData}
              />
            )}
            {getNewPBdata['car_park'] && (
              <PBtypeList
                isPA={isPA}
                type={'SALE_SUBPRODUCT'}
                pbListData={getNewPBdata['car_park']}
                refetchData={refetchData}
              />
            )}
            {getNewPBdata['sale_package'] && (
              <PBtypeList
                isPA={isPA}
                type={'SALE_PACKAGE'}
                pbListData={getNewPBdata['sale_package']}
                refetchData={refetchData}
              />
            )}
            {!getNewPBdata['sale_progress_billing'] &&
              !getNewPBdata['car_park'] &&
              !getNewPBdata['sale_package'] && (
                <EmptyState title="No Record found" />
              )}
          </ColumnFlex>
        )}
      </Container>
    </>
  )
}
export default PAprogressBillingListing
