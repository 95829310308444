import React from 'react'
import { Dialog } from 'components/Dialogs/Dialog'
import { useDeleteBillItemIntMutation } from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'

interface BillItems2DeleteProps {
  open: boolean
  setDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>
  refetch: any
  billItemId: string
}

export default function BillItems2Delete({
  open,
  setDeleteDialog,
  refetch,
  billItemId,
}: BillItems2DeleteProps) {
  const [deleteBillItem, { loading: deleteBillItemLoading }] =
    useDeleteBillItemIntMutation({
      onError: error => {},
      onCompleted: data => {
        if (data.deleteBillItemInt) {
          setSnackBar(SystemMsgs.deleteRecord())

          refetch()
        }
      },
    })

  return (
    <Dialog
      useDefaultDialogHeader={true}
      useDefaultDialogBody={true}
      open={open}
      defaultDialogData={{
        header: {
          title: {
            leftTopTitle: {
              title: {
                text: 'Transaction Type',
              },
            },
            rightTopTitle: {
              text: 'Delete',
            },
          },
        },
        bodyText: 'Are you sure want to delete?',
      }}
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => setDeleteDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            type: 'button',
            onClick: () => {
              deleteBillItem({ variables: { id: billItemId } })
              setDeleteDialog(false)
            },
          },
        ],
      }}
    />
  )
}
