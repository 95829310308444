import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const OverduedPurchaserIndoListing = lazy(
  () => import('./OverduedPurchaserIndoListing')
)
const OverduedPurchaserIndoDetail = lazy(
  () => import('./OverduedPurchaserIndoDetail')
)

const OverduedPurchaserIndoForm = lazy(
  () => import('./OverduedPurchaserIndoForm')
)
const OverduedPurchaserIndoView = lazy(
  () => import('./OverduedPurchaserIndoView')
)

const OverduedPurchaserAccountsIndonesiaRoutes: RouteObject[] = [
  {
    path: 'overdued-purchaser-account',
    children: [
      {
        path: '',
        element: <OverduedPurchaserIndoListing />,
      },
      {
        path: 'detail/:saleId/:overduedPurchaserAccountId',
        children: [
          {
            path: '',
            element: <OverduedPurchaserIndoDetail />,
          },
          {
            path: 'add',
            element: <OverduedPurchaserIndoForm mode="add" />,
          },
          {
            path: ':id/edit',
            element: <OverduedPurchaserIndoForm mode="edit" />,
          },
          {
            path: ':caId/view',
            element: <OverduedPurchaserIndoView />,
          },
        ],
      },
    ],
  },
]

export default OverduedPurchaserAccountsIndonesiaRoutes
