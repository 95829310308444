import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const PurchaserInstallmentListing = lazy(
  () => import('./PurchaserInstallmentListing')
)

// const BankPaymentDetail = lazy(() => import('./BankPaymentDetail'))

const PurchaserInstallmentRoutes: RouteObject[] = [
  {
    path: ':saleID/purchaser-installment',
    children: [
      {
        path: '',
        element: <PurchaserInstallmentListing />,
      },
    ],
  },
]

export default PurchaserInstallmentRoutes
