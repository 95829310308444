import { lazy } from 'react'
import { RouteObject } from 'react-router'

const PAIndoLedgerRefundListing = lazy(
  () => import('./PAIndoLedgerRefundListing')
)
const PAIndoLedgerRefundForm = lazy(() => import('./PAIndoLedgerRefundForm'))
const PAIndoLedgerRefundDetails = lazy(
  () => import('./PAIndoLedgerRefundDetails')
)

const RefundRoutes: RouteObject[] = [
  {
    path: ':saleID/refund',
    children: [
      {
        path: '',
        element: <PAIndoLedgerRefundListing />,
      },
      {
        path: 'add',
        element: <PAIndoLedgerRefundForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoLedgerRefundForm mode="edit" />,
      },
      {
        path: ':id/view',
        element: <PAIndoLedgerRefundDetails />,
      },
    ],
  },
]

export default RefundRoutes
