import { RouteObject } from "react-router";
import StatutoryDocumentsListing from "./StatutoryDocumentsListing";
const StatutoryDocumentsRoutes : RouteObject[] = [
    {
        path: 'statutory-documents',
        children: [
            {
                path: '',
                element: <StatutoryDocumentsListing />
            }
        ]
    }
]

export default StatutoryDocumentsRoutes