import {
  ColumnFlex,
  Container,
  List,
  ListItem,
  ListItemText,
  SubHeaderContainer,
} from '@ifca-ui/core'
import { KeyboardArrowRight } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { IconButton, Typography } from '@mui/material'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import { RowFlex } from 'components/Layout/RowLayout'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useGetPaIndoSummaryLazyQuery,
  useProjectDetailQuery,
} from 'generated/graphql'
import { amtStr } from 'helpers/numFormatter'
import { useContext, useEffect, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useLocation, useNavigate, useParams } from 'react-router'

const PurchaserAccountSummary = () => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const subHeaderRef = useRef(null)
  let navigate = useNavigate()
  const { navBack, navTo } = useNav()
  let location = useLocation()
  let stateData: any = location?.state
  const { projectID }: any = useParams()
  const [showPage, setShowPage] = useState<boolean>(false)
  const path = `/${projectID}/purchaser-accounts-indo`
  const [saleID, setSaleID] = useState<string>('')

  //#region API

  const { data: { projectDetail } = { projectDetail: null } } =
    useProjectDetailQuery({ variables: { id: projectID } })

  const [
    fetchPAIndoModuleSummary,
    {
      loading: loadingPAmoduleSummary,
      data: { getPAIndoSummary } = {
        getPAIndoSummary: null,
      },
    },
  ] = useGetPaIndoSummaryLazyQuery({
    onCompleted: data => {
      setShowPage(true)
    },
  })

  useEffect(() => {
    if (projectID != null) {
      fetchPAIndoModuleSummary({
        variables: {
          projectId: projectID,
        },
      })
    }
  }, [projectID])

  //#endregion

  const submenuDescription = (tableRef, draft, submit, approve, unAllocAmt) => {
    return (
      <ColumnFlex gap="5px">
        <div></div>
        <RowFlex mainAxisAlignment="SPACE_BETWEEN">
          <RowFlex gap="10px" key="3 item">
            <RowFlex gap="4px">
              <Typography className="text-desc" color="primary">
                {draft}
              </Typography>
              <Typography className="text-desc">Draft</Typography>
            </RowFlex>
            <RowFlex gap="4px">
              <Typography className="text-desc" color="primary">
                {submit}
              </Typography>
              <Typography className="text-desc">Submitted</Typography>
            </RowFlex>
            <RowFlex gap="4px">
              <Typography className="text-desc" color="primary">
                {approve}
              </Typography>
              <Typography className="text-desc">Approved</Typography>
            </RowFlex>
          </RowFlex>
          {tableRef !== 'refund' ? (
            <RowFlex>
              <Typography className="text-xsTitle" color="red">
                {amtStr(unAllocAmt)}
              </Typography>
            </RowFlex>
          ) : null}
        </RowFlex>
      </ColumnFlex>
    )
  }

  //#region header/subheader
  useEffect(() => {
    if (subHeaderRef.current?.clientHeight) {
      rootDispatch({
        type: 'subHeaderHeight',
        payload: subHeaderRef.current?.clientHeight,
      })
    }
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navTo(`/${projectID}`),
          },
        },
        rightIcon: {},
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Purchaser Accounts',
          },
        },
      },
    })
  }, [projectDetail])
  //#endregion

  const submenuList = [
    {
      title: 'Purchaser Ledger',
      path: `${path}/purchaser-ledger`,
      description: showPage ? (
        <Typography component="span" className="text-desc">
          -
        </Typography>
      ) : null,
    },
    {
      title: 'Misc Billing',
      amount: showPage ? getPAIndoSummary?.miscBillingSummary?.totalDocAmt : 0,
      path: `${path}/misc-billing`,
      rightTextColor: 'primary',
      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'misc',
            getPAIndoSummary?.miscBillingSummary?.draftCount,
            getPAIndoSummary?.miscBillingSummary?.submitCount,
            getPAIndoSummary?.miscBillingSummary?.approveCount,
            getPAIndoSummary?.miscBillingSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Debit Note',
      amount: showPage ? getPAIndoSummary.debitNoteSummary?.totalDocAmt : 0,
      path: `${path}/debit-note`,
      rightTextColor: 'primary',

      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'debit-note',
            getPAIndoSummary?.debitNoteSummary?.draftCount,
            getPAIndoSummary?.debitNoteSummary?.submitCount,
            getPAIndoSummary?.debitNoteSummary?.approveCount,
            getPAIndoSummary?.debitNoteSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Credit Note',
      amount: showPage ? getPAIndoSummary.creditNoteSummary?.totalDocAmt : 0,
      path: `${path}/credit-note`,
      rightTextColor: 'primary',

      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'credit-note',
            getPAIndoSummary?.creditNoteSummary?.draftCount,
            getPAIndoSummary?.creditNoteSummary?.submitCount,
            getPAIndoSummary?.creditNoteSummary?.approveCount,
            getPAIndoSummary?.creditNoteSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Rebate',
      amount: showPage ? getPAIndoSummary.rebateSummary?.totalDocAmt : 0,
      path: `${path}/rebate`,
      rightTextColor: 'primary',

      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'rebate',
            getPAIndoSummary?.rebateSummary?.draftCount,
            getPAIndoSummary?.rebateSummary?.submitCount,
            getPAIndoSummary?.rebateSummary?.approveCount,
            getPAIndoSummary?.rebateSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Payment',
      amount: showPage ? getPAIndoSummary.paymentSummary?.totalDocAmt : 0,
      path: `${path}/payment`,
      rightTextColor: 'primary',
      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'payment',
            getPAIndoSummary?.paymentSummary?.draftCount,
            getPAIndoSummary?.paymentSummary?.submitCount,
            getPAIndoSummary?.paymentSummary?.approveCount,
            getPAIndoSummary?.paymentSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Deposit',
      amount: showPage ? getPAIndoSummary.depositSummary?.totalDocAmt : 0,
      path: `${path}/deposit`,
      rightTextColor: 'primary',
      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'deposit',
            getPAIndoSummary?.rebateSummary?.draftCount,
            getPAIndoSummary?.rebateSummary?.submitCount,
            getPAIndoSummary?.rebateSummary?.approveCount,
            getPAIndoSummary?.rebateSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Contra',
      amount: showPage ? getPAIndoSummary.contraSummary.totalDocAmt : 0,
      path: `${path}/contra`,
      rightTextColor: 'primary',
      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'contra',
            getPAIndoSummary?.contraSummary?.draftCount,
            getPAIndoSummary?.contraSummary?.submitCount,
            getPAIndoSummary?.contraSummary?.approveCount,
            getPAIndoSummary?.contraSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Refund',
      amount: showPage ? getPAIndoSummary.refundSummary?.totalDocAmt : 0,
      path: `${path}/refund`,
      rightTextColor: 'primary',

      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'refund',
            getPAIndoSummary?.refundSummary?.draftCount,
            getPAIndoSummary?.refundSummary?.submitCount,
            getPAIndoSummary?.refundSummary?.approveCount,
            getPAIndoSummary?.refundSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
  ]

  return (
    <>
      <SubHeaderContainer
        id="subheader-container"
        ref={subHeaderRef}
        style={{ backgroundColor: '#ffe7cb', fontSize: 'smaller' }}
      ></SubHeaderContainer>
      <Container bottomSpacing="lg">
        <>
          <List>
            {submenuList?.map(el => {
              return (
                <ListItem
                  key={el.title}
                  secondaryAction={
                    <IconButton>
                      <KeyboardArrowRight />
                    </IconButton>
                  }
                  onClick={() => {
                    navTo(el?.path)
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography component="div" display="flex">
                        <Typography
                          component="div"
                          flex="1"
                          display="flex"
                          variant="inherit"
                          color="common.black"
                        >
                          <Typography component="span" className="text-xsTitle">
                            {el?.title}
                          </Typography>
                        </Typography>
                        <Typography
                          component="div"
                          flex="1"
                          display="flex"
                          variant="inherit"
                          justifyContent="right"
                          alignItems="start"
                        >
                          <Typography
                            component="span"
                            color={el?.rightTextColor}
                            className="text-xsTitle"
                          >
                            {el.amount < 0 && '('}
                            <NumberFormat
                              value={el?.amount}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                            {el.amount < 0 && ')'}
                          </Typography>
                        </Typography>
                      </Typography>
                    }
                    secondary={<div> {el?.description}</div>}
                  />
                </ListItem>
              )
            })}
          </List>
        </>
      </Container>
    </>
  )
}

export default PurchaserAccountSummary
