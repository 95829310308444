import React from 'react'

enum JustifyContentEnum {
  CENTER = 'center',
  FLEX_END = 'flex-end',
  FLEX_START = 'flex-start',
  SPACE_AROUND = 'space-around',
  SPACE_BETWEEN = 'space-between',
  SPACE_EVENLY = 'space-evenly',
}

enum AlignItemsEnum {
  BASELINE = 'baseline',
  CENTER = 'center',
  END = 'end',
  FLEX_END = 'flex-end',
  FLEX_START = 'flex-start',
  NORMAL = 'normal',
  START = 'start',
  STRETCH = 'stretch',
}
interface ColumnProps {
  children?: any
  className?: string
  crossAxisAlignment?: keyof typeof AlignItemsEnum // Specifies the vertical alignment of flex items
  gap?: string // Specifies the gap between grid items
  gridTemplateColumns?: string // Defines the columns in the grid using CSS grid syntax
  gridTemplateRows: string
  id?: string
  mainAxisAlignment?: keyof typeof JustifyContentEnum // Specifies the horizontal alignment of flex items
  width?: string
  height?: string
}

// ColumnGrid
/**
 * A grid layout component that arranges its children in a column.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.id - The unique identifier for the grid layout.
 * @param {string} props.gridTemplateRows - The CSS grid template rows property.
 * @param {string} props.gap - The gap between grid items.
 * @param {string} props.mainAxisAlignment - The alignment of grid items along the main axis.
 * @param {string} props.crossAxisAlignment - The alignment of grid items along the cross axis.
 * @param {string} props.className - The additional CSS class name for the grid layout.
 * @param {string} props.height - The height of the grid layout.
 * @param {string} props.width - The width of the grid layout.
 * @param {ReactNode} props.children - The children components to be rendered within the grid layout.
 * @returns {ReactNode} The rendered grid layout component.
 */
export const ColumnGrid: React.FC<ColumnProps> = React.memo(
  ({
    id,
    gridTemplateRows,
    gap,
    mainAxisAlignment,
    crossAxisAlignment,
    className,
    height,
    width,
    children,
  }) => {
    return (
      <div
        id={id}
        key={id}
        className={className}
        style={{
          alignItems: AlignItemsEnum[crossAxisAlignment || ''],
          display: 'grid',
          gap,
          gridTemplateRows,
          justifyContent: JustifyContentEnum[mainAxisAlignment || ''],
          width,
          height,
        }}
      >
        {children}
      </div>
    )
  }
)

// Column flexbox
interface ColumnFlexProps {
  children?: React.ReactNode
  className?: string
  crossAxisAlignment?: keyof typeof AlignItemsEnum // Specifies the vertical alignment of flex items
  gap?: string // Specifies the gap between flex items
  height?: string
  id?: string
  mainAxisAlignment?: keyof typeof JustifyContentEnum // Specifies the horizontal alignment of flex items
  width?: string
}

/**
 * Renders a column layout with flexible properties.
 *
 * @component
 * @param {string} id - The unique identifier for the column layout.
 * @param {string} className - The CSS class name for the column layout.
 * @param {number} gap - The gap between child elements in the column layout.
 * @param {string} mainAxisAlignment - The alignment of child elements along the main axis.
 * @param {string} crossAxisAlignment - The alignment of child elements along the cross axis.
 * @param {string} height - The height of the column layout.
 * @param {string} width - The width of the column layout.
 * @param {React.ReactNode} children - The child elements to be rendered within the column layout.
 * @returns {React.ReactElement} The rendered column layout component.
 */
export const ColumnFlex: React.FC<ColumnFlexProps> = React.memo(
  ({
    id,
    className,
    gap,
    mainAxisAlignment,
    crossAxisAlignment,
    height,
    width,
    children,
  }) => {
    return (
      <div
        id={id}
        key={id}
        className={className}
        style={{
          alignItems: AlignItemsEnum[crossAxisAlignment || ''],
          display: 'flex',
          flexDirection: 'column',
          gap,
          justifyContent: JustifyContentEnum[mainAxisAlignment || ''],
          width,
          height,
        }}
      >
        {children}
      </div>
    )
  }
)

export default ColumnFlex
