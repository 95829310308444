import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const NotaryListing = lazy(
  () => import('./NotaryListing')
)
const NotaryDetail = lazy(
  () => import('./NotaryDetail')
)
const NotaryForm = lazy(
  () => import('./NotaryForm')
)

const notaryRoutes: RouteObject[] = [
  {
    path: 'notary',
    children: [
      {
        path: '',
        element: <NotaryListing />,
      },
      {
        path: 'add',
        element: <NotaryForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <NotaryForm mode="edit" />,
      },
      {
        path: ':id',
        element: <NotaryDetail />,
      },
    ],
  },
]

export default notaryRoutes
