import { lazy } from 'react'
import { RouteObject } from 'react-router'

const PAIndoContraListing = lazy(
  () => import('./PAIndoContraListing')
)
const PAIndoContraDetails = lazy(
  () => import('./PAIndoContraDetails')
)
const PAIndoCotraAllocation = lazy(
  () => import('./PAIndoCotraAllocation')
)
const PAIndoCotraAllocationForm = lazy(
  () => import('./AllocationForm/PaIndoContraAllocationForm')
)

const PAIndoContraRoutes: RouteObject[] = [
  {
    path: 'contra',
    children: [
      {
        path: '',
        element: <PAIndoContraListing />,
      },
      {
        path: ':id/view',
        element: <PAIndoContraDetails />,
      },
      {
        path: ':id/allocation',
        children: [
          {
            path: '',
            element: <PAIndoCotraAllocation />,
          },
          {
            path: 'add',
            element: <PAIndoCotraAllocationForm mode="add" />,
          },
        ]
      },
    ],
  },
]

export default PAIndoContraRoutes
