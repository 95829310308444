import React, {lazy} from 'react';
import { RouteObject } from 'react-router';

const PromotionalItemList = lazy(() => import ('./PromotionalItemList'))

const promotionalItemRoute: RouteObject []= [
    {
        path: 'promotional-item-list',
        element: <PromotionalItemList />,
    }
]
    

export default promotionalItemRoute