import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const CollectionActivityListing = lazy(
  () => import('./CollectionActivityListing')
)
const CollectionActivityForm = lazy(() => import('./CollectionActivityForm'))
const CollectionActivityDetails = lazy(
  () => import('./CollectionActivityDetails')
)

const CollectionActivityRoutes: RouteObject[] = [
  {
    path: 'collection-activity',
    children: [
      { path: '', element: <CollectionActivityListing /> },
      {
        path: ':saleId/:overduedPurchaserAccountId/detail',
        element: <CollectionActivityDetails />,
      },
      { path: ':saleId/:overduedPurchaserAccountId/detail/:collectionID/edit', element: <CollectionActivityForm mode="edit" /> },
      { path: ':saleId/:overduedPurchaserAccountId/detail/add', element: <CollectionActivityForm mode="add" /> },
    ],
  },
]

export default CollectionActivityRoutes
