import React, { lazy } from 'react'
import { RouteObject } from 'react-router'


const PurchaserPaymentScheduleListing = lazy(() => import('./PurchaserPaymentScheduleListing'))

// const BankPaymentDetail = lazy(() => import('./BankPaymentDetail'))

const PurchaserPaymentScheduleRoutes: RouteObject[] = [
  {
    path: 'purchaser-payment-schedule',
    children: [
      {
        path: '',
        element: <PurchaserPaymentScheduleListing />,
      },
    ],
  },
]

export default PurchaserPaymentScheduleRoutes
