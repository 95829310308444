import {
  Card,
  Container,
  Subheader,
  SubHeaderContainer,
  SubheaderText,
  ListItemText,
  Spinner,
} from '@ifca-ui/core'
import { Box, Typography } from '@mui/material'
import { CardListHeader } from 'components/General/CardListItem'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { useProjectDetailQuery, useGetJointBuyerQuery, useGetProfessionByIdLazyQuery, useGetReligionByIdLazyQuery } from 'generated/graphql'
import { useContext, useEffect, useRef } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

const PurchaserDetailsPreview = () => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const subHeaderRef = useRef(null)
  let navigate = useNavigate()
  const { navBack, navTo } = useNav()
  const location = useLocation()
  const state = location.state
  // const saleId = state?.saleId
  const mode = state?.mode
  const { projectID, contactId, saleId }: any = useParams()

  //--Start GraphQL--
  const {
    loading: curProjectLoading,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })

  const [ getProfessionById, { loading: getProfessionByIdLoading, data: getProfessionByIdData } ] =  useGetProfessionByIdLazyQuery()

  const [ getReligionById, { loading: getReligionByIdLoading, data: getReligionByIdData } ] =  useGetReligionByIdLazyQuery()

  const { data } = useGetJointBuyerQuery({
    variables: { contactId },
    onCompleted: data => {
      getProfessionById({
        variables: { 
          professionId : data.getJointBuyer.contactExt.professionId 
        }
      })
      getReligionById({
        variables: { 
          religionId : data.getJointBuyer.contactExt.religionId 
        }
      })
    },
  })

  const buyerDetails = data?.getJointBuyer

  //#region header/subheader
  useEffect(() => {
    if (subHeaderRef.current?.clientHeight) {
      rootDispatch({
        type: 'subHeaderHeight',
        payload: subHeaderRef.current?.clientHeight,
      })
    }
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () =>
              navTo(
                `/${projectID}/sales-registrations/form/preview/${saleId}`,
                state
              ),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 3,
            current: 'Purchaser Info',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])
  //#endregion

  return (
    <>
      {curProjectLoading && <Spinner />}
      {/* {getUnitBookingDetailSaleLoading && <></>} */}
      <SubHeaderContainer id="subheader-container" ref={subHeaderRef}>
        <Subheader>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box width="100%" display="flex" height="20px">
                  <ListItemText
                    primary={
                      <Typography
                        component="div"
                        display="flex"
                        variant="inherit"
                      >
                        <Typography
                          className="text-xsTitle"
                          variant="inherit"
                          flex="2"
                          color="#FF9800"
                        >
                          {buyerDetails?.fullName ?? ''}
                        </Typography>
                      </Typography>
                    }
                    secondary={
                      <Typography
                        component="div"
                        display="flex"
                        variant="inherit"
                      >
                        <Typography
                          className="text-xsTitle"
                          variant="inherit"
                          flex="1"
                          color="common.black"
                        >
                          {/* {getUnitBookingDetailSale?.fullName} (
                            <Typography
                              component="span"
                              variant="inherit"
                              className="search-bar-count-f"
                            >
                              {getUnitBookingDetailSale?.jointBuyerCount + 1}
                            </Typography>
                            ) */}
                          {buyerDetails?.icNo ?? ''}
                        </Typography>
                      </Typography>
                    }
                  ></ListItemText>
                </Box>
              </Box>
            }
          />
        </Subheader>
      </SubHeaderContainer>
      <Box height={`${rootState?.subHeaderHeight}px`} />
      <Container>
        <Card className="card-container-detail">
          <Box width="100%" display="flex">
            <Box className="left">
              <Typography className="label-f">Nationality</Typography>
              <Typography className="value-f">
                {buyerDetails?.nationality?.name}
              </Typography>
            </Box>
            <Box className="right">
              <Typography className="label-f">Email</Typography>
              <Typography className="value-f">{buyerDetails?.email}</Typography>
            </Box>
          </Box>
          <Box width="100%" display="flex">
            <Box className="left">
              <Typography className="label-f">Identity Type</Typography>
              <Typography className="value-f">
                {buyerDetails?.contactExt?.identityType}
              </Typography>
            </Box>
            <Box className="right">
              <Typography className="label-f">Identity No.</Typography>
              <Typography className="value-f">{buyerDetails?.icNo}</Typography>
            </Box>
          </Box>
          <Box width="100%" display="flex">
            <Box className="left">
              <Typography className="label-f">Name as per ID</Typography>
              <Typography className="value-f">
                {buyerDetails?.fullName}
              </Typography>
            </Box>
          </Box>
          <Box width="100%" display="flex">
            <Box className="left">
              <Typography className="label-f">Salutation</Typography>
              <Typography className="value-f">
                {buyerDetails?.salutation?.name ?? '-'}
              </Typography>
            </Box>
          </Box>
          <Box width="100%" display="flex">
            <Box className="left">
              <Typography className="label-f">Marital Status</Typography>
              <Typography className="value-f">
                {buyerDetails?.maritalStatus?.name}
              </Typography>
            </Box>
            <Box className="left">
              <Typography className="label-f">Gender</Typography>
              <Typography className="value-f">
                {buyerDetails?.gender}
              </Typography>
            </Box>
          </Box>
          <Box width="100%" display="flex">
            <Box className="left">
              <Typography className="label-f">Profession</Typography>
              <Typography className="value-f">
                {getProfessionByIdData?.getProfessionById?.name ?? '-'}
              </Typography>
            </Box>
            <Box className="left">
              <Typography className="label-f">Religion</Typography>
              <Typography className="value-f">
                {getReligionByIdData?.getReligionById.name ?? '-'}
              </Typography>
            </Box>
          </Box>
          <Box width="100%" display="flex">
            <Box className="left">
              <Typography className="label-f">Customer Origin</Typography>
              <Typography className="value-f">
                {buyerDetails?.contactExt?.customerOrigin}
              </Typography>
            </Box>
          </Box>
        </Card>

        <CardListHeader
          title={
            <Typography
              className="text-xsTitle"
              color="#FF9800"
              component="span"
              flex="1 1"
              variant="inherit"
            >
              Address Details
            </Typography>
          }
        >
              <>
                {state?.address && <AddressCard address={state.address} />}
                {buyerDetails?.contactAddress?.filter(x => x.AddressType).map(address => (
                  <AddressCard address={address} />
                ))}
              </>
        </CardListHeader>
        <CardListHeader
          title={
            <Typography
              className="text-xsTitle"
              color="#FF9800"
              component="span"
              flex="1 1"
              variant="inherit"
            >
              Tax Details
            </Typography>
          }
        >
          <Card className="card-container-detail">
            <Box width="100%" display="flex">
              <Box className="left">
                <Typography className="label-f">
                  Tax Registration No.
                </Typography>
                <Typography className="value-f">
                  {buyerDetails?.contactExt?.taxRegistrationNo}
                </Typography>
              </Box>
            </Box>
            <Box width="100%" display="flex">
              <Box className="left">
                <Typography className="label-f">
                  Name on Tax Registration
                </Typography>
                <Typography className="value-f">
                  {buyerDetails?.contactExt?.nameOnTax}
                </Typography>
              </Box>
            </Box>
            <Box width="100%" display="flex">
              <Box className="left">
                <Typography className="label-f">
                  Tax Registration Address
                </Typography>
                <Typography className="value-f">
                  {buyerDetails?.contactExt?.taxRegistrationAddress}
                </Typography>
              </Box>
            </Box>
          </Card>
        </CardListHeader>
        <CardListHeader
          title={
            <Typography
              className="text-xsTitle"
              color="#FF9800"
              component="span"
              flex="1 1"
              variant="inherit"
            >
              Contact Numbers
            </Typography>
          }
        >
          <Card className="card-container-detail" style={{ width: '100%' }}>
            <Box width="100%" display="flex">
              <Box className="left">
                <Typography className="label-f">Mobile No.</Typography>
                <Typography className="value-f">
                  {buyerDetails?.phoneNo}
                </Typography>
              </Box>
              <Box className="right">
                <Typography className="label-f">Home Tel No.</Typography>
                <Typography className="value-f">
                  {buyerDetails?.homeTel}
                </Typography>
              </Box>
            </Box>
            <Box width="100%" display="flex">
              <Box className="left">
                <Typography className="label-f">Office Tel No.</Typography>
                <Typography className="value-f">
                  {buyerDetails?.officeTel}
                </Typography>
              </Box>
            </Box>
          </Card>
        </CardListHeader>
        <CardListHeader
          title={
            <Typography
              className="text-xsTitle"
              color="#FF9800"
              component="span"
              flex="1 1"
              variant="inherit"
            >
              Mailing Method
            </Typography>
          }
        >
          <Card className="card-container-detail" style={{ width: '100%' }}>
            <Box width="100%" display="flex">
              <Box className="left">
                {/* <Typography className="label-f">Tax Registration No.</Typography> */}
                <Typography className="value-f">
                  {buyerDetails?.contactExt?.mailingMethod ?? '-'}
                </Typography>
              </Box>
            </Box>
          </Card>
        </CardListHeader>
        <CardListHeader
          title={
            <Typography
              className="text-xsTitle"
              color="#FF9800"
              component="span"
              flex="1 1"
              variant="inherit"
            >
              Marketing Material
            </Typography>
          }
        >
          <Card className="card-container-detail" style={{ width: '100%' }}>
            <Box
              width="100%"
              display="flex"
              style={{
                display:
                  buyerDetails?.contactExt?.subscribeMaterialEmail == true
                    ? 'block'
                    : 'none',
              }}
            >
              <Box className="left">
                {/* <Typography className="label-f">Tax Registration No.</Typography> */}
                <Typography className="value-f">Email</Typography>
              </Box>
            </Box>
            <Box
              width="100%"
              display="flex"
              style={{
                display:
                  buyerDetails?.contactExt?.subscribeMaterialWhatsapp == true
                    ? 'block'
                    : 'none',
              }}
            >
              <Box className="left">
                {/* <Typography className="label-f">Name on Tax Registration</Typography> */}
                <Typography className="value-f">WhatsApp</Typography>
              </Box>
            </Box>
            <Box
              width="100%"
              display="flex"
              style={{
                display:
                  buyerDetails?.contactExt?.subscribeMaterialPaNews == true
                    ? 'block'
                    : 'none',
              }}
            >
              <Box className="left">
                {/* <Typography className="label-f">Tax Registration Address</Typography> */}
                <Typography className="value-f">News</Typography>
              </Box>
            </Box>
            <Box
              width="100%"
              display="flex"
              style={{
                display:
                  buyerDetails?.contactExt?.subscribeMaterialSms == true
                    ? 'block'
                    : 'none',
              }}
            >
              <Box className="left">
                {/* <Typography className="label-f">Tax Registration Address</Typography> */}
                <Typography className="value-f">SMS</Typography>
              </Box>
            </Box>
          </Card>
        </CardListHeader>
        <CardListHeader
          title={
            <Typography
              className="text-xsTitle"
              color="#FF9800"
              component="span"
              flex="1 1"
              variant="inherit"
            >
              Remark
            </Typography>
          }
        >
          <Card className="card-container-detail" style={{ width: '100%' }}>
            <Box width="100%" display="flex">
              <Box className="left">
                {/* <Typography className="label-f">Tax Registration No.</Typography> */}
                <Typography className="value-f">Test</Typography>
              </Box>
            </Box>
          </Card>
        </CardListHeader>
      </Container>
    </>
  )
}


const AddressCard = ({ address }) => {
  return (
    <Box width="100%" zIndex="0 !important" position="relative">
      <Card className="card-container-detail" style={{ width: '100%' }}>
        <Box width="100%" display="flex">
          <Box className="left">
            <Typography className="label-f">
              {address?.AddressType ?? 'Corresspondence Address'}
            </Typography>
            <Typography className="value-f">
              {address?.address ?? '-'}
            </Typography>
          </Box>
        </Box>
        <Box width="100%" display="flex">
          <Box className="left">
            <Typography className="label-f">City</Typography>
            <Typography className="value-f">
              {address?.city ?? '-'}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Post Code</Typography>
            <Typography className="value-f">
              {address?.postCode ?? '-'}
            </Typography>
          </Box>
        </Box>
        <Box width="100%" display="flex">
          <Box className="left">
            <Typography className="label-f">State</Typography>
            <Typography className="value-f">
              {address?.state ?? '-'}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Country</Typography>
            <Typography className="value-f">
              {address?.country ?? '-'}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};


export default PurchaserDetailsPreview
