import {
  Dialog,
  EmptyState,
  FilePreviewCard,
  FileUploadTextField,
  Subheader,
  SubheaderText,
  useUploadAttachment,
} from '@ifca-ui/core'
import { Clear, PictureAsPdfSharp } from '@mui/icons-material'
import { Avatar, Badge, Box, IconButton, Typography } from '@mui/material'
import {
  useDownloadStatutoryDocumentListLazyQuery,
  useUploadStatutoryDocumentListLazyQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { useState } from 'react'
import { useParams } from 'react-router'

export default function ExportTab() {
  const [openDialogAttachment, setOpenDialogAttachment] =
    useState<boolean>(false)

  const [downloadStatutoryDocumentList] =
    useDownloadStatutoryDocumentListLazyQuery()
  const [uploadStatutoryDocumentList] =
    useUploadStatutoryDocumentListLazyQuery()
  const { projectID } = useParams()

  const { files, handleUploadChange, handleRemove, previewFiles } =
    useUploadAttachment()

  const downloadExcel = () => {
    downloadStatutoryDocumentList({
      variables: {
        projectId: projectID,
      },
      onCompleted: data => {
        var mediaType =
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;base64,'

        var userInp = data.downloadStatutoryDocumentList.base64
        let a = document.createElement('a')
        a.href = mediaType + userInp
        a.download = data.downloadStatutoryDocumentList.projectname
        a.click()
      },
    })
  }

  const toggleAttachment = (val: boolean) => {
    setOpenDialogAttachment(val)
  }

  const excelAttachment = openDialogAttachment && (
    <Dialog
      open={openDialogAttachment}
      onClose={() => {
        toggleAttachment(false)
      }}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Upload Statutory Documents
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <Box>
          <FileUploadTextField
            label="Upload Document"
            value={files}
            variant="standard"
            margin="normal"
            multiple={false}
            name="files"
            onChange={e => handleUploadChange(e)}
            filePreview={
              <>
                {previewFiles?.map((file, index) => (
                  <span
                    style={{ paddingRight: '20px' }}
                    key={files[index]?.name}
                  >
                    <Badge
                      className={'override-icon-button'}
                      style={{ marginRight: '0px', width: '93px' }}
                      badgeContent={
                        <IconButton
                          onClick={() => {
                            handleRemove(index)
                          }}
                        >
                          <Clear />
                        </IconButton>
                      }
                      color="secondary"
                    >
                      <FilePreviewCard
                        key={index}
                        label={files[index]?.name}
                        badge={{
                          invisible: true,
                        }}
                      >
                        {files[index]?.type?.includes('pdf') ? (
                          <PictureAsPdfSharp style={{ color: '#e2574c' }} />
                        ) : (
                          <Avatar variant="square" src={file} />
                        )}
                      </FilePreviewCard>
                    </Badge>
                  </span>
                ))}
              </>
            }
          />
        </Box>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => toggleAttachment(false),
          },

          {
            children: 'Confirm',
            color: 'primary',
            onClick: () => {
              uploadExcel()
            },
          },
        ],
      }}
    ></Dialog>
  )

  const uploadExcel = () => {
    if (files.length > 0) {
      uploadStatutoryDocumentList({
        variables: {
          projectId: projectID,
          file: files[0],
        },
        onCompleted: data => {
          setSnackBar(data.uploadStatutoryDocumentList.message)
          setOpenDialogAttachment(false)
          handleRemove(0)
        },
      })
    } else {
      setSnackBar('Please upload attachment')
    }
  }

  return (
    <>
      {excelAttachment}
      <Box padding={4}>
        <Typography
          className="fs-14 fw-700"
          flex="center"
          style={{ textAlign: 'center' }}
        >
          1. You may click{' '}
          <a
            className="a-font-link text-underline"
            onClick={() => downloadExcel()}
          >
            HERE
          </a>{' '}
          to Download Excel Template.
        </Typography>
        <Typography
          className="fs-14 fw-700"
          flex="center"
          style={{ textAlign: 'center' }}
        >
          2. You can{' '}
          <a
            className="a-font-link text-underline"
            onClick={() => toggleAttachment(true)}
          >
            Upload
          </a>{' '}
          Excel Template with Statutory Document data.
        </Typography>
      </Box>
    </>
  )
}
