import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const RescheduleInstallmentListing = lazy(() => import('./RescheduleInstallmentListing'))
const RescheduleInstallmentForm = lazy(() => import('./RescheduleInstallmentForm'))
const RescheduleInstallmentDetails = lazy(() => import('./RescheduleInstallmentDetails'))
const RescheduleForm = lazy(() => import('./RescheduleForm/RescheduleForm'))
const RescheduleFormConfirm = lazy(() => import('./RescheduleForm/RescheduleFormConfirm'))

export const RESCHEDULE_INSTALLMENT_PATH = {
  RESCHEDULE_INSTALLMENT_LISTING: '/:projectID/reschedule-installment',
  RESCHEDULE_INSTALLMENT_ADD: '/:projectID/reschedule-installment/add',
  RESCHEDULE_INSTALLMENT_DETAILS: '/:projectID/reschedule-installment/details',
  RESCHEDULE_FORM : '/:projectID/reschedule-installment/add/reschedule',
  RESCHEDULE_FORM_CONFIRM : '/:projectID/reschedule-installment/add/reschedule-confirm',
}

const RescheduleInstallmentRoutes: RouteObject[] = [
  {
    path: 'reschedule-installment',
    children: [
      {
        path: '',
        element: <RescheduleInstallmentListing />,
      },
      {
        path: 'add',
        element: <RescheduleInstallmentForm mode="add" />,
      },
      {
        path: 'details',
        element: <RescheduleInstallmentDetails />,
      },
      {
        path: 'add/reschedule',
        element: <RescheduleForm />,
      },
      {
        path: 'add/reschedule-confirm',
        element: <RescheduleFormConfirm />,
      },
    ],
  },
]

export default RescheduleInstallmentRoutes
