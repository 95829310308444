import {
  ColumnFlex,
  ExpansionDetails,
  RowFlex,
  RowGrid,
  Wrap,
} from '@ifca-ui/core'
import {
  AccountBalance,
  CalendarMonthRounded,
  KeyboardArrowRightOutlined,
  MonetizationOnRounded,
  Person,
} from '@mui/icons-material'
import { Divider, IconButton, Tooltip } from '@mui/material'
import IconX from 'components/General/IconX'
import TextX from 'components/General/TextX'
import useAppData from 'components/General/useAppData'
import moment from 'moment'

type props = {
  item: any
}
const PBexpansionDetails = ({ item }: props) => {
  const { saleID, navTo, projectID } = useAppData()

  return (
    <ExpansionDetails sx={{ paddingRight: '10px' }}>
      <ColumnFlex gap={'5px'} fullWidth className="text-desc text-icon">
        {item.details.map((subData, index) => (
          <RowFlex
            fullWidth
            crossAxisAlignment="center"
            gap="2px"
            className="clickable-item"
          >
            <ColumnFlex key={index} gap={'5px'} fullWidth>
              {index !== 0 && <Divider />}
              <div
                onClick={() =>
                  navTo(
                    `/${projectID}/purchaser-accounts/${saleID}/progress-billing/${subData.progressBillingId}/view`
                  )
                }
              >
                <ColumnFlex gap={'4px'} fullWidth>
                  <RowFlex mainAxisAlignment="space-between" fullWidth>
                    <RowFlex key="left">
                      <IconX icon={CalendarMonthRounded} />
                      <RowFlex gap={'4px'}>
                        <div>
                          {subData.documentDate
                            ? moment(subData.documentDate).format('DD MMM yyyy')
                            : '-'}
                        </div>
                        <div>|</div>
                        <TextX data={subData.documentNo} />
                      </RowFlex>
                    </RowFlex>
                    <RowFlex className="c-orange">
                      <IconX icon={MonetizationOnRounded} />
                      <TextX
                        data={subData.calculatedDocumentAmt}
                        dataType="Number"
                      />
                    </RowFlex>
                  </RowFlex>
                  <RowFlex mainAxisAlignment="space-between" fullWidth>
                    {subData.paymentParty === 'Buyer' ? (
                      <RowGrid gridTemplateColumns={'1fr 0.3fr'}>
                        <RowFlex>
                          <IconX icon={Person} />
                          <Wrap rowGap="4px">
                            {subData.creditDocumentNo ? (
                              subData.creditDocumentNo
                                .split(',')
                                .map((no, index, arr) => (
                                  <RowFlex key={index}>
                                    <TextX data={no} />
                                    {index !== arr.length - 1 && <span>,</span>}
                                  </RowFlex>
                                ))
                            ) : (
                              <span>-</span>
                            )}
                          </Wrap>
                        </RowFlex>
                        <div></div>
                      </RowGrid>
                    ) : (
                      <Tooltip
                        title={`${subData.paymentParty ?? '-'} (${
                          subData.paymentPartyCode ?? '-'
                        })`}
                        id="pbTooltip"
                        placement="top-start"
                      >
                        <div>
                          <RowGrid gridTemplateColumns={'1fr 0.3fr'}>
                            <RowFlex>
                              <IconX icon={AccountBalance} />
                              <Wrap rowGap="4px">
                                {subData.creditDocumentNo ? (
                                  subData.creditDocumentNo
                                    .split(',')
                                    .map((no, index, arr) => (
                                      <RowFlex key={index}>
                                        <TextX data={no} />
                                        {index !== arr.length - 1 && (
                                          <span>,</span>
                                        )}
                                      </RowFlex>
                                    ))
                                ) : (
                                  <span>-</span>
                                )}
                              </Wrap>
                            </RowFlex>
                            <div></div>
                          </RowGrid>
                        </div>
                      </Tooltip>
                    )}
                    <div style={{ color: 'red' }}>
                      <RowFlex>
                        <MonetizationOnRounded />
                        <TextX
                          data={
                            subData.outstandingAmt === 0 &&
                            subData.documentNo === null
                              ? '-'
                              : subData.outstandingAmt
                          }
                          dataType="Number"
                        />
                      </RowFlex>
                    </div>
                  </RowFlex>
                </ColumnFlex>
              </div>
            </ColumnFlex>
            <IconButton sx={{ paddingRight: '0px' }}>
              <IconX icon={KeyboardArrowRightOutlined} size={25} />
            </IconButton>
          </RowFlex>
        ))}
      </ColumnFlex>
    </ExpansionDetails>
  )
}

export default PBexpansionDetails
