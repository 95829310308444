import {
  Dialog,
  DialogButton,
  EmptyState,
  Expansion,
  ExpansionDetails,
  ExpansionSummary,
  FilePreviewCard,
  FileUploadTextField,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SearchBar,
  SelectionBar,
  Subheader,
  SubheaderText,
  useUploadAttachment,
} from '@ifca-ui/core'
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { ChangeEvent, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CommonYupValidation } from 'helpers/yup'
import { DatePickerX } from 'components/Inputs/DatePickerX'
import { AttachFile, Clear, PictureAsPdfSharp } from '@mui/icons-material'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import {
  useAddSalesStatutoryDocumentMutation,
  useGetStatutoryTypeQuery,
  useEditSalesStatutoryDocumentMutation,
  useDeleteSalesStatutoryDocumentMutation,
  useGetUnitStatutoryDocumentListByTypeLazyQuery,
  useMergeSalesStatutoryDocumentMutation,
  useGetNotarySelectionListLazyQuery,
} from 'generated/graphql'
import { useParams } from 'react-router'
import moment from 'moment'
import {
  DataFormFields,
  EditDataField,
  StatutoryDocumentInterface,
  UnitStatutoryInterface,
  statutoryType,
  Option,
} from '../StatutoryDocumentInterface'
import { MobileDatePicker } from '@mui/x-date-pickers'

export default function ByDocType() {
  const { projectID } = useParams()
  const selectionRef = useRef(null)
  const [selectedDocument, setSelectedDocument] = useState<statutoryType>({
    id: '',
    name: '',
    code: '',
    perSalesContract: false,
  })
  const [notaryList, setNotaryList] = useState<Option[]>()
  const [toBeDeleted, setToBeDeleted] = useState<string>('')
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(null)
  const [search, setSearchInput] = useState<string>('')
  const [expanded, setExpanded] = useState<boolean[]>([])
  const [openDialogAttachment, setOpenDialogAttachment] = useState<boolean>()
  const [openDialogDelete, setOpenDialogDelete] = useState<boolean>()
  const [selectedData, setSelectedData] = useState<EditDataField>()
  const [dialogType, setDialogType] = useState('')
  const [formData, setFormData] = useState<UnitStatutoryInterface>()
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const [checked, setChecked] = useState<StatutoryDocumentInterface[]>([])
  const [mergeHandler, setMergeHandler] = useState<Boolean>(false)
  const formSchema = yup.object().shape({
    documentDate: CommonYupValidation.requireDate('Date is required.'),
    documentRef: CommonYupValidation.requireField(
      'Document number is required'
    ),
    // files: CommonYupValidation.requireArray('File is required.')
  })

  const {
    error,
    loading,
    data: { getStatutoryDocsList } = { getStatutoryDocsList: null },
  } = useGetStatutoryTypeQuery({ fetchPolicy: 'network-only' })
  if (error) console.log(error)

  const [getUnitStatutoryDocumentListByType] =
    useGetUnitStatutoryDocumentListByTypeLazyQuery({
      onCompleted: data => {
        setOriginalListing(data.getUnitStatutoryDocumentListByType)
        let arr = []
        data.getUnitStatutoryDocumentListByType.forEach(el => {
          arr.push(false)
        })
        expanded.forEach((el, i) => {
          if (el === true) arr[i] = el
        })
        setExpanded(arr)
      },
    })

  const [editSalesStatutoryDocument] = useEditSalesStatutoryDocumentMutation({
    onCompleted: () => {
      reset()
      clearErrors()
      handleRemove(0)
      handleDialogClosed()
      setSnackBar(SystemMsgs.updateRecord())
      getUnitStatutoryDocumentListByType({
        variables: {
          projectId: projectID,
          perSalesContract: selectedDocument.perSalesContract,
          statutoryDocumentId: selectedDocument.id,
        },
      })
    },
  })

  const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
    setStatusAnchorEl(event.currentTarget)
  }

  const statusMenuOpen = Boolean(statusAnchorEl)

  const handleStatusMenuClose = () => {
    setStatusAnchorEl(null)
  }

  const handleStatusMenuItemClick = (doc: statutoryType) => {
    setOriginalListing([])
    setSelectedDocument(doc)
    setStatusAnchorEl(null)
    getUnitStatutoryDocumentListByType({
      variables: {
        projectId: projectID,
        perSalesContract: doc.perSalesContract,
        statutoryDocumentId: doc.id,
      },
    })
    getNotarySelectionList()
  }

  const [getNotarySelectionList] = useGetNotarySelectionListLazyQuery({
    variables: {
      projectId: projectID,
    },
    onCompleted: data => {
      setNotaryList(data.getNotarySelectionList)
    },
  })

  const [addSalesStatutoryDocument] = useAddSalesStatutoryDocumentMutation()

  const [mergeSalesStatutoryDocument] = useMergeSalesStatutoryDocumentMutation({
    onCompleted: () => {
      setMergeHandler(false)
      setChecked([])
      setOpenDialogAttachment(false)
      clearErrors()
      reset()
      handleRemove(0)
      setSnackBar(SystemMsgs.createNewRecord())
      getUnitStatutoryDocumentListByType({
        variables: {
          projectId: projectID,
          perSalesContract: selectedDocument.perSalesContract,
          statutoryDocumentId: selectedDocument.id,
        },
      })
    },
  })

  const handleExpand = (index: number, doc: UnitStatutoryInterface) => {
    const res = expanded.map((el, i) => {
      if (i == index) el = !el
      else el = false
      return el
    })
    setChecked([])
    setMergeHandler(false)
    setExpanded(res)
    setFormData(doc)
  }

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<DataFormFields>({
    mode: 'all',
    defaultValues: {
      documentDate: new Date(),
      notaryId: null,
    },
    resolver: yupResolver(formSchema),
  })

  const addStatutory = () => {
    const { documentDate, documentRef, notaryId, bpnLandArea } = getValues()
    // if (files.length > 0) {
    //   if (dialogType == 'add') {
    //     addSalesStatutoryDocument({
    //       variables: {
    //         input: {
    //           documentDate: documentDate,
    //           file: files,
    //           document_ref: documentRef,
    //           saleId: formData.saleId,
    //           statutoryDocumentId: selectedDocument.id,
    //           projectId: projectID,
    //           unitStatutoryId: formData.unitStatutoryId,
    //           contactId: formData.contactId,
    //           notaryId,
    //           bpnLandArea: +bpnLandArea,
    //         },
    //       },
    //       onError: err => {
    //         console.log(err)
    //       },
    //       onCompleted: () => {
    //         setOpenDialogAttachment(false)
    //         clearErrors()
    //         reset()
    //         handleRemove(0)
    //         setSnackBar(SystemMsgs.createNewRecord())
    //         getUnitStatutoryDocumentListByType({
    //           variables: {
    //             projectId: projectID,
    //             perSalesContract: selectedDocument.perSalesContract,
    //             statutoryDocumentId: selectedDocument.id,
    //           },
    //         })
    //       },
    //     })
    //   } else if (dialogType == 'edit') {
    //     editSalesStatutoryDocument({
    //       variables: {
    //         input: {
    //           id: selectedData.id,
    //           document_ref: documentRef,
    //           documentDate: documentDate,
    //           file: files,
    //           bpnLandArea: +bpnLandArea,
    //           notaryId,
    //         },
    //       },
    //     })
    //   } else if (dialogType == 'merge') {
    //     const datas = checked.map(el => {
    //       return {
    //         id: el.id,
    //         statutoryDocumentId: el.statutoryDocumentId,
    //         unitStatutoryId: el.unitStatutoryId,
    //         documentDate: el.documentDate.toString(),
    //         documentRef: el.documentRef,
    //         contactId: el.contactId,
    //       }
    //     })
    //     mergeSalesStatutoryDocument({
    //       variables: {
    //         input: {
    //           checked: datas,
    //           document_ref: documentRef,
    //           documentDate: documentDate,
    //           file: files,
    //           contactId: datas[0].contactId,
    //           projectId: projectID,
    //           bpnLandArea: +bpnLandArea,
    //           notaryId,
    //           statutoryDocumentId: datas[0].statutoryDocumentId,
    //           unitStatutoryId: datas[0].unitStatutoryId,
    //         },
    //       },
    //     })
    //   }
    // } else {
    //   return setSnackBar('File must not be empty.')
    // }
    if (dialogType === 'add') {
      addSalesStatutoryDocument({
        variables: {
          input: {
            documentDate: documentDate,
            file: files,
            document_ref: documentRef,
            saleId: formData.saleId,
            statutoryDocumentId: selectedDocument.id,
            projectId: projectID,
            unitStatutoryId: formData.unitStatutoryId,
            contactId: formData.contactId,
            notaryId,
            bpnLandArea: +bpnLandArea,
          },
        },
        onError: err => {
          console.log(err)
        },
        onCompleted: () => {
          setOpenDialogAttachment(false)
          clearErrors()
          reset()
          handleRemove(0)
          setSnackBar(SystemMsgs.createNewRecord())
          getUnitStatutoryDocumentListByType({
            variables: {
              projectId: projectID,
              perSalesContract: selectedDocument.perSalesContract,
              statutoryDocumentId: selectedDocument.id,
            },
          })
        },
      })
    } else if (dialogType === 'edit') {
      editSalesStatutoryDocument({
        variables: {
          input: {
            id: selectedData.id,
            document_ref: documentRef,
            documentDate: documentDate,
            file: files,
            bpnLandArea: +bpnLandArea,
            notaryId,
          },
        },
      })
    } else if (dialogType === 'merge') {
      const datas = checked.map(el => {
        return {
          id: el.id,
          statutoryDocumentId: el.statutoryDocumentId,
          unitStatutoryId: el.unitStatutoryId,
          documentDate: el.documentDate.toString(),
          documentRef: el.documentRef,
          contactId: el.contactId,
        }
      })
      mergeSalesStatutoryDocument({
        variables: {
          input: {
            checked: datas,
            document_ref: documentRef,
            documentDate: documentDate,
            file: files,
            contactId: datas[0].contactId,
            projectId: projectID,
            bpnLandArea: +bpnLandArea,
            notaryId,
            statutoryDocumentId: datas[0].statutoryDocumentId,
            unitStatutoryId: datas[0].unitStatutoryId,
          },
        },
      })
    }
  }

  const attachmentDialog = (val: boolean, doc: UnitStatutoryInterface) => {
    setDialogType('add')
    setOpenDialogAttachment(val)
    setFormData(doc)
  }

  const deleteAttachmentDialog = (val, doc, el) => {
    setOpenDialogDelete(val)
    setFormData(doc)
    setToBeDeleted(el)
  }

  const [deleteSalesStatutoryDocument] =
    useDeleteSalesStatutoryDocumentMutation({
      onCompleted: () => {
        setSnackBar(SystemMsgs.deleteRecord())
        getUnitStatutoryDocumentListByType({
          variables: {
            projectId: projectID,
            perSalesContract: selectedDocument.perSalesContract,
            statutoryDocumentId: selectedDocument.id,
          },
        })
        setOpenDialogDelete(false)
      },
    })

  const doDeleteAttachment = () => {
    deleteSalesStatutoryDocument({
      variables: {
        id: toBeDeleted,
      },
    })
  }

  const prepareHandleUploadChange = (e: ChangeEvent) => {
    if ((e.target as HTMLInputElement).files !== null) {
      const fileObj = []
      const tempFiles = []
      const tempPreviewFiles = []

      fileObj.push((e.target as HTMLInputElement).files)
      tempFiles.push(fileObj[0][0])
      tempPreviewFiles.push(URL.createObjectURL(fileObj[0][0]))

      setFiles(tempFiles)
      setPreviewFiles(tempPreviewFiles)
    }
  }

  const handleDialogClosed = () => {
    setOpenDialogAttachment(false)
    setOpenDialogDelete(false)
    clearErrors()
    reset()
    resetUploadedFiles()
  }

  const {
    files,
    previewFiles,
    uploadedFiles,
    handleUploadChange,
    handleRemove,
    setFiles,
    setPreviewFiles,
    resetUploadedFiles
  } = useUploadAttachment()

  const editAttachmentDialog = (dataEdit: EditDataField) => {
    setSelectedData(dataEdit)
    setValue('documentDate', dataEdit.documentDate)
    setValue('documentRef', dataEdit.documentRef)
    setValue('bpnLandArea', dataEdit.bpnLandArea)
    setValue('notaryId', dataEdit.notaryId)
    getNotarySelectionList()
    setOpenDialogAttachment(true)
    setDialogType('edit')

    if (dataEdit.resourceUrl && dataEdit.fileName && dataEdit.mediaType) {
      const fileObj = []
      const tempFiles = []
      const tempPreviewFiles = []

      fileObj.push(new File([dataEdit.resourceUrl], dataEdit.fileName, {
        type: dataEdit.mediaType
      }))
      tempFiles.push(fileObj[0])
      tempPreviewFiles.push(dataEdit.resourceUrl)

      setFiles(tempFiles)
      setPreviewFiles(tempPreviewFiles)
    }
  }

  const mergeDialog = () => {
    getNotarySelectionList()
    setDialogType('merge')
    setOpenDialogAttachment(true)
  }

  // --> Upload Modal <--
  const AttachmentDialog = openDialogAttachment && (
    <Dialog
      fullWidth={true}
      open={openDialogAttachment}
      onClose={() => {
        setOpenDialogAttachment(false)
        clearErrors()
      }}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Statutory Documents
                  </Typography>
                  <Typography
                    className="text-xsTitle"
                    color="primary.dark"
                    component="span"
                    variant="inherit"
                  >
                    {formData?.unitNo}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <>
          <Box width="100%">
            <DatePickerX
              required
              control={control}
              name="documentDate"
              label="Date"
            />
          </Box>
          <Box width="100%">
            <Controller
              control={control}
              name="documentRef"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Document Number"
                  autoComplete="off"
                  error={errors?.documentRef ? true : false}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  required
                />
              )}
            />
          </Box>
          <Box>
            <FileUploadTextField
              label="Upload Document"
              value={files}
              variant="standard"
              margin="normal"
              multiple={false}
              name="files"
              // onChange={e => handleUploadChange(e)}
              onChange={e => prepareHandleUploadChange(e)}
              filePreview={
                <>
                  {previewFiles?.map((file, index) => (
                    <span
                      style={{ paddingRight: '20px' }}
                      key={files[index]?.name}
                    >
                      <Badge
                        className={'override-icon-button'}
                        style={{ marginRight: '0px', width: '93px' }}
                        badgeContent={
                          <IconButton
                            onClick={() => {
                              handleRemove(index)
                            }}
                          >
                            <Clear />
                          </IconButton>
                        }
                        color="secondary"
                      >
                        <FilePreviewCard
                          key={index}
                          label={files[index]?.name}
                          badge={{
                            invisible: true,
                          }}
                        >
                          {files[index]?.type?.includes('pdf') ? (
                            <PictureAsPdfSharp style={{ color: '#e2574c' }} />
                          ) : (
                            <Avatar variant="square" src={file} />
                          )}
                        </FilePreviewCard>
                      </Badge>
                    </span>
                  ))}
                </>
              }
            />
          </Box>
          <Box width="100%">
            <Controller
              control={control}
              name="notaryId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Autocomplete
                  id="clear-on-blur"
                  options={notaryList as Option[]}
                  fullWidth
                  clearOnBlur
                  getOptionLabel={opt =>
                    typeof opt === 'object' ? opt.name : ''
                  }
                  onChange={(e, value: any) => {
                    setValue('notaryId', value?.notaryId || null)
                    console.log(value)
                  }}
                  value={notaryList?.find(el => el.notaryId == value) || null}
                  renderInput={params => (
                    <TextField
                      {...params}
                      value={value}
                      label="Notary Public"
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Notary Name"
                    />
                  )}
                />
              )}
            />
          </Box>
          <Box width="100%" marginY={2}>
            <Controller
              control={control}
              name="bpnLandArea"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  id="standard-basic"
                  label="BPN Land Area"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  placeholder="-"
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
          </Box>
        </>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => handleDialogClosed(),
          },

          {
            children: 'Save',
            color: 'primary',
            onClick: () => handleSubmit(addStatutory)(),
          },
        ],
      }}
    ></Dialog>
  )

  // --> Delete Attachment Modal --<

  const DeleteAttachmentDialog = openDialogDelete && (
    <Dialog
      fullWidth={true}
      open={openDialogDelete}
      onClose={() => {
        setOpenDialogDelete(false)
      }}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Statutory Documents
                  </Typography>
                  <Typography
                    className="text-xsTitle"
                    color="primary.dark"
                    component="span"
                    variant="inherit"
                  >
                    {formData?.unitNo}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <Typography className="text-xsTitle">
          Are you sure want to delete?
        </Typography>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => handleDialogClosed(),
          },

          {
            children: 'Confirm',
            color: 'primary',
            onClick: () => {
              doDeleteAttachment()
            },
          },
        ],
      }}
    ></Dialog>
  )

  const checklistHandler = (doc: StatutoryDocumentInterface) => {
    setChecked(prevChecked => {
      const isChecked = prevChecked.some(item => item.id === doc.id)
      if (!isChecked) {
        if (prevChecked.length === 1) setMergeHandler(true)
        return [...prevChecked, doc]
      } else {
        const updatedChecked = prevChecked.filter(item => item.id !== doc.id)
        if (updatedChecked.length < 2) setMergeHandler(false)
        return updatedChecked
      }
    })
  }

  return (
    <>
      {AttachmentDialog}
      {DeleteAttachmentDialog}
      <Box ref={selectionRef}>
        <SelectionBar
          title={
            <Typography
              component="span"
              className="text-mdLabel"
              variant="inherit"
            >
              {getStatutoryDocsList?.find(el => el.id == selectedDocument.id)
                ?.name || 'Select Document Type'}
            </Typography>
          }
          selectionItem={{
            style: {
              background: selectedDocument.name !== '' ? '#ff9800' : '',
            },
            onClick: e => handleStatusClick(e),
          }}
        />
        <Menu
          id="default-menu"
          divRef={selectionRef}
          anchorEl={statusAnchorEl}
          open={statusMenuOpen}
          onClose={handleStatusMenuClose}
          MenuListProps={{
            role: 'listbox',
          }}
        >
          {getStatutoryDocsList?.map((option, index) => (
            <MenuItem
              key={index}
              onClick={e =>
                handleStatusMenuItemClick({
                  id: option.id,
                  name: option.name,
                  code: option.code,
                  perSalesContract: option.perSalesContract,
                })
              }
            >
              {option.name}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {selectedDocument.name != '' ? (
        <Box style={{ marginTop: 4 }}>
          <SearchBar
            title={
              <Box component="span" className="text-mdLabel">
                {`Search Here`} (
                <Typography
                  component="span"
                  variant="inherit"
                  className="search-bar-count-f"
                >
                  {filteredList?.length ?? 0}
                </Typography>
                )
              </Box>
            }
            searchInput={{
              value: search,
              onChange: e => {
                setSearchInput(e.target.value)
                handleSearch(e.target.value, ['unitNo', 'fullName'])
              },
              onChangeClearInput: (e) => {
                setSearchInput('')
                handleSearch('', ['unitNo', 'fullName'])
              },
            }}
          />
          {filteredList?.length > 0 ? (
            <>
              {expanded.map((_, index) => (
                <div key={'filteredList ' + index} style={{ marginTop: 4 }}>
                  <Expansion
                    expanded={expanded[index]}
                    defaultExpanded={false}
                    key={'exp' + index}
                  >
                    <ExpansionSummary
                      style={{ backgroundColor: '#faf2e8' }}
                      key={'expansionDocTypeSum' + index}
                      className="mortgage-expansion-summary"
                      expandIcon={
                        <IconButton
                          sx={{ p: 0, color: 'common.black' }}
                          onClick={e =>
                            handleExpand(index, filteredList[index])
                          }
                        >
                          {expanded ? (
                            <ExpandMoreOutlinedIcon />
                          ) : (
                            <ExpandMoreOutlinedIcon />
                          )}
                        </IconButton>
                      }
                    >
                      <ListItemText
                        key={'listItemByDoc' + index}
                        primary={
                          <Box component="div" display="flex">
                            <Typography
                              className="text-xsTitle"
                              color="#454545"
                              variant="inherit"
                              component="span"
                              flex="1"
                            >
                              <div>{filteredList[index]?.unitNo}</div>
                              <Typography
                                className="text-xsTitle"
                                variant="inherit"
                              >
                                {filteredList[index]?.fullName}
                              </Typography>
                            </Typography>
                            <Box
                              className="text-desc"
                              component="span"
                              display="flex"
                            >
                              <Box component="div" display="flex" alignItems="start">
                                {parseInt(filteredList[index]?.totalUploaded) <
                                  parseInt(
                                    filteredList[index]?.totalNeedToBeUpload
                                  ) && (
                                  <IconButton
                                    sx={{ p: 0, color: 'common.black' }}
                                    onClick={() => {
                                      // attachmentDialog(true, 'add')
                                      attachmentDialog(
                                        true,
                                        filteredList[index]
                                      )
                                    }}
                                  >
                                    <AttachFile className="text-title" />
                                  </IconButton>
                                )}
                                <Typography className="search-bar-count-f text-desc">
                                  {filteredList[index]?.totalUploaded}
                                </Typography>
                                /
                                <Typography className="text-desc">
                                  {filteredList[index]?.totalNeedToBeUpload}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        }
                      />
                    </ExpansionSummary>
                    <ExpansionDetails>
                      {filteredList[index]?.documents?.length > 0 ? (
                        filteredList[index]?.documents?.map(
                          (el: StatutoryDocumentInterface, i: number) => (
                            <div key={'listItemByDocDetail' + i}>
                              <div key={'listItemDiv' + i}>
                                {el.isMerge && (
                                  <hr
                                    style={{
                                      width: '100%',
                                      margin: '60px 0 auto -15.5px',
                                      marginTop: '60px',
                                      position: 'absolute',
                                      borderColor: '#454545',
                                    }}
                                  />
                                )}
                                <Box
                                  sx={{
                                    width: '100%',
                                    borderColor: 'grey.500',
                                  }}
                                  display={'flex'}
                                  gap={1.5}
                                  // key={file.id}
                                  key={'box' + index}
                                >
                                  <Box
                                    sx={{ width: '5%', height: '5%' }}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignSelf={'center'}
                                  >
                                    <Checkbox
                                      size="medium"
                                      disabled={el.isMerge}
                                      name={el.documentRef}
                                      onChange={() => checklistHandler(el)}
                                      checked={checked.some(
                                        item => item.id === el.id
                                      )}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      width: '100%',
                                      marginY: 2,
                                    }}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={0.5}
                                  >
                                    <Controller
                                      control={control}
                                      name="documentDate"
                                      render={({
                                        field: { onChange, onBlur, value },
                                      }) => (
                                        <MobileDatePicker
                                          disabled={el.isMerge}
                                          InputProps={{ readOnly: true }}
                                          label="Doc Date"
                                          onChange={newValue => {
                                            onChange(newValue)
                                          }}
                                          readOnly
                                          value={el.documentDate}
                                          inputFormat="dd/MM/yyyy"
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              margin="normal"
                                              required
                                              fullWidth
                                              variant="outlined"
                                              size="small"
                                              sx={{ marginTop: 0 }}
                                              value={el.documentDate}
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <IconButton
                                                      disabled={el.isMerge}
                                                      style={{ padding: 1 }}
                                                    >
                                                      <EventIcon />
                                                    </IconButton>
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          )}
                                        />
                                      )}
                                    />
                                    <TextField
                                      required
                                      id="outlined-required"
                                      label="Notary Public"
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                      size="small"
                                      value={
                                        notaryList?.find(
                                          x => x.notaryId === el.notaryId
                                        )?.name || '-'
                                      }
                                      InputProps={{ readOnly: true }}
                                      disabled={el.isMerge}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      width: '100%',
                                      marginY: 2,
                                    }}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignSelf={'center'}
                                    gap={1.5}
                                  >
                                    <TextField
                                      required
                                      id="outlined-required"
                                      label="Doc No"
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                      size="small"
                                      value={el.documentRef}
                                      InputProps={{ readOnly: true }}
                                      disabled={el.isMerge}
                                    />
                                    <TextField
                                      id="outlined-required"
                                      label="BPN Land Area"
                                      InputLabelProps={{ shrink: true }}
                                      placeholder="-"
                                      fullWidth
                                      size="small"
                                      value={el.bpnLandArea || '-'}
                                      InputProps={{ readOnly: true }}
                                      disabled={el.isMerge}
                                    />
                                  </Box>
                                  <Box
                                    sx={{ width: '10%' }}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignSelf={'flex-start'}
                                    marginTop={2}
                                  >
                                    <IconButton
                                      disabled={el.isMerge}
                                      style={{ padding: 1 }}
                                      color="error"
                                      onClick={e =>
                                        deleteAttachmentDialog(
                                          true,
                                          filteredList[index],
                                          el.id
                                        )
                                      }
                                    >
                                      <DeleteIcon className="fs_20" />
                                    </IconButton>
                                    <IconButton
                                      disabled={el.isMerge}
                                      style={{ padding: 1 }}
                                      onClick={e => editAttachmentDialog(el)}
                                    >
                                      <EditIcon className="fs_20" />
                                    </IconButton>
                                  </Box>
                                </Box>

                                <Divider />
                              </div>
                            </div>
                          )
                        )
                      ) : (
                        <EmptyState
                          title="No Record found"
                          subTitle="Add a new record now."
                        />
                      )}
                      <Box
                        style={{
                          paddingTop: 8,
                          paddingBottom: 5,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <DialogButton
                          disabled={!mergeHandler}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            mergeDialog()
                          }}
                        >
                          Merge
                        </DialogButton>
                      </Box>
                    </ExpansionDetails>
                  </Expansion>
                </div>
              ))}
            </>
          ) : (
            <>
              <EmptyState
                title="No Record found"
                subTitle="Add a new record now."
              />
            </>
          )}
        </Box>
      ) : (
        <>
          <EmptyState
            title="No Record found"
            subTitle="Add a new record now."
          />
        </>
      )}
    </>
  )
}
