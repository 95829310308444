import Fuse from 'fuse.js'
import React, { useEffect } from 'react'

// filter the initital list to not search by ID, objects, createdBy, createdTs and modTs
// if possible, can also gives array of keys to be filtered
// this works even better with specific .graphql
// export const useSearch = () => {
//   const [filteredList, setFiltered] = React.useState([])
//   const handleSearch = (origList, filter: string) => {
//     setFiltered(origList)

//     if (origList) {
//       // filtering ID and object inside object
//       // array of object should consist of same element so define key by taking first element only
//       let objKey = Object.keys(origList[0])
//       let filteredKeys = objKey?.filter(
//         key =>
//           !key.includes('ID') &&
//           // typeof origList[0][key] !== 'object' &&
//           !key.includes('created') &&
//           !key.match('modTs')
//       )

//       let filteredObj = origList?.filter(i => {
//         let j = filteredKeys.reduce((val, entry) => {
//           if (entry.toLowerCase().includes('date') && val[entry] != null) {
//             val[entry] = i[entry]
//             //val[entry] = formatDate(new Date(i[entry]).toISOString())
//           } else {
//             val[entry] = i[entry]
//           }
//           // val[entry] = i[entry];
//           return val
//         }, {})

//         if (
//           JSON.stringify(j)
//             ?.toLowerCase()
//             .includes(filter.toString().toLowerCase())
//         )
//           return i
//       })

//       setFiltered(filteredObj)
//     }
//   }

//   return {
//     filteredList,
//     handleSearch,
//   }
// }

/**
 *
 * @param handleSearch
 * 3 parameters (origList, filter, keys)
 *
 * @param keys
 * keys to be search in the object
 *
 * first layer:
 * - {name: "chia"}
 * - keys = ["name"]
 *
 * nested object:
 * - { person: { name: "chia", size: { height: "180", weight: "120"}}}
 * - keys = ["person.name.size.height", "person.name.size.weight"]
 * 
 * @type Generic type T
 * 
 * Pass generic T as a single item of particular array
 * 
 * e.g:
 * 
 * const arr:T[] = []
 * 
 * const values = useFuseSearch\<T\>()
 * 
 * extra:
 * type ListItem = NonNullable\<GetSalesRegistrationListQuery\>['getSalesRegistrationList'][number]
 * 
 * const values = useFuseSearch\<ListItem\>()
 *
 * @param origList
 * original list to be search
 *
 * @param filter
 * filter string or pattern words being search
 * -- empty will return the original list
 
 * @function reset
 * -- only use if you want to reset the list manually,
 * else the search should be able to auto reset the list to original
 */
export const useFuseSearch = <T extends Record<string, any> = any>() => {
  const [filteredList, setFiltered] = React.useState<T[]>([])
  const [originalList, setOriginalList] = React.useState<T[]>([])
  const [searchText, setSearchText] = React.useState<string>()
  const [searchKey, setSearchKey] = React.useState<(keyof T)[]>([])
  const setOriginalListing = (
    origList: T[],
    customDataChanges?: (item: T) => void
  ) => {
    let tempOrig = origList
    if (!!customDataChanges) {
      tempOrig?.map(v => customDataChanges(v))
    }

    setFiltered(tempOrig)
    setOriginalList(tempOrig)
  }

  useEffect(() => {
    if (searchText && searchKey && searchKey.length > 0) {
      filterFn(searchText, searchKey)
    }
  }, [originalList])

  const filterFn = (searchText: string, keys: (keyof T)[]) => {
    if (originalList) {
      const options = {
        isCaseSensitive: false,
        // includeScore: false,
        shouldSort: true,
        // includeMatches: true,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        threshold: 0.0,
        // distance: 100,
        // useExtendedSearch: false,
        ignoreLocation: true,
        // ignoreFieldNorm: false,
        // keys: filteredKeys,
        // keys: L,
        keys: keys.map(k => k as string),
      }
      if (searchText) {
        const myFuse = new Fuse(originalList, options)
        const result = myFuse.search(searchText)
        const val = result?.map(x => x.item)
        setFiltered(val)
      } else {
        setFiltered(originalList)
      }
    }
  }

  const handleSearch = (filter: string, keys: (keyof T | (string & {}))[]) => {
    setSearchText(filter)
    setSearchKey(keys)

    filterFn(filter, keys)
  }

  const reset = () => {
    setFiltered(originalList)
  }

  return {
    filteredList,
    handleSearch,
    setOriginalListing,
    reset,
  }
}
