// TODO: Reformat all

import { lazy } from 'react'
import { RouteObject } from 'react-router'
import PurchaserTransferBuyerForm from './PurchaserTransferBuyerForm'
// import PurchaserNameTransferDraftForm from './Draft/PurchaserNameTransferDraftForm'

const PurchaserNameTransferListing = lazy(
  () => import('./PurchaserNameTransferListing')
)
const PurchaserNameTransferForm3 = lazy(
  () => import('./PurchaserNameTransferForm')
)

const PurchaserNameTransferDraftForm = lazy(
  () => import('./Draft/PurchaserNameTransferDraftForm')
)

const PurchaserTransferDraftBuyerForm = lazy(
  () => import('./Draft/PurchaserTransferDraftBuyerForm')
)

// const PurchaserNameTransferListingCreation = lazy(() => import('./PurchaserNameTransferListingCreation'))

const PurchaserNameTransferRoutes: RouteObject[] = [
  {
    path: 'purchaser-name-transfer',
    children: [
      {
        path: '',
        element: <PurchaserNameTransferListing />,
      },
      {
        path: 'add',
        element: <PurchaserNameTransferForm3 />,
      },
      {
        path: 'add/purchaser',
        element: <PurchaserTransferBuyerForm backtrack="add"/>,
      },
      {
        path: 'add/purchaser/:detailId',
        element: <PurchaserTransferBuyerForm backtrack="add"/>,
      },
      {
        path: ':saleId/:purchaserNameTransferId',
        children: [
          {
            path: 'purchaser',
            element: <PurchaserTransferDraftBuyerForm backtrack="" />,
          },
          {
            path: '',
            element: (
              <PurchaserNameTransferDraftForm backtrack="PurchaserNameTransfer" />
            ),
          },
          {
            path: 'purchaser/:detailId',
            element: (
              <PurchaserTransferDraftBuyerForm backtrack="" />
            ),
          },
        ],
      },
    ],
  },
]

export default PurchaserNameTransferRoutes
