import React, { lazy } from 'react'
import { RouteObject } from 'react-router'
import PurchaserPaymentScheduleRoutes from './PurchaserPaymentSchedule/Routes'
import RescheduleInstallmentRoutes from './RescheduleInstallment/Routes'
import RescheduledInstallmentApprovalRoutes from './RescheduledInstallmentApproval/Routes'
import InstallmentRevenueProjectionRoutes from './InstallmentRevenueProjection/Routes'

const PaymentScheduleSubmenu = lazy(() => import('./PaymentScheduleSubmenu'))

export const PAYMENT_SCHEDULES_PATH = {
  SUBMENU: '/:projectID/payment-schedule',
}

const PaymentSchedulesRoutes: RouteObject[] = [
  {
    path: 'payment-schedule',
    children: [
      {
        path: '',
        element: <PaymentScheduleSubmenu />,
      },
      ...PurchaserPaymentScheduleRoutes,
      ...RescheduleInstallmentRoutes,
      ...RescheduledInstallmentApprovalRoutes,
      ...InstallmentRevenueProjectionRoutes,
    ],
  },
]

export default PaymentSchedulesRoutes
