import { lazy } from 'react'
import { RouteObject } from 'react-router'

const PaymentReminderExemptionListing = lazy(
  () => import('./PaymentReminderExemptionListing')
)
const PaymentReminderExemptionForm = lazy(
  () => import('./PaymentReminderExemptionForm')
)

const PaymentRemindersRoutes: RouteObject[] = [
  {
    path: 'payment-reminder-exemption',
    children: [
      {
        path: '',
        element: <PaymentReminderExemptionListing />,
      },
      {
        path: 'add',
        element: <PaymentReminderExemptionForm />,
      },
    ],
  },
]

export default PaymentRemindersRoutes
