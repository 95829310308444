import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const InstallmentRevenueProjectionPage = lazy (() => import('./InstallmentRevenueProjectionPage'))

const InstallmentRevenueProjectionRoutes : RouteObject [] = [
    {
        path:'installment-revenue-projection',
        element: <InstallmentRevenueProjectionPage/>
    }
]

export default InstallmentRevenueProjectionRoutes