import React, { lazy } from 'react'
import { RouteObject } from 'react-router'


const LoanDifferentialListing = lazy(() => import('./LoanDifferentialListing'))
const LoanDifferentialSchedule = lazy(() => import('./LoanDifferentialSchedule'))
const LoanDifferentialPreview = lazy(() => import('./LoanDifferentialPreview'))
// const BankPaymentDetail = lazy(() => import('./BankPaymentDetail'))

const LoanDifferentialRoutes: RouteObject[] = [
  {
    path: 'loan-differential',
    children: [
      {
        path: '',
        element: <LoanDifferentialListing />,
      },
      {
        path: 'schedule',
        element: <LoanDifferentialSchedule />,
      },
      {
        path: 'preview',
        element: <LoanDifferentialPreview />,
      },
    //   {
    //     path: 'form/edit/:id',
    //     element: <BankPaymentForm mode="edit" />,
    //   },
    //   { path: 'detail/:id', element: <BankPaymentDetail /> },
    ],
  },
]

export default LoanDifferentialRoutes
