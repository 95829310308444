import { lazy } from 'react'
import { RouteObject } from 'react-router'
// import PAIndoPaymentAllocationForm from '../../Payment/PAIndoPaymentAllocationForm'

const PAIndoLedgerPaymentListing = lazy(
  () => import('./PAIndoLedgerPaymentListing')
)
const PAIndoLedgerPaymentForm = lazy(() => import('./PAIndoLedgerPaymentForm'))
const PAIndoLedgerPaymentDetails = lazy(
  () => import('./PAIndoLedgerPaymentDetails')
)

const PAIndoPaymentAllocationForm = lazy(
  () => import('../../Payment/PAIndoPaymentAllocationForm')
)

const PAIndoPaymentApprovedAllocation = lazy(
  () => import('../../Payment/PAIndoPaymentApprovedAllocation')
)

const PaymentRoutes: RouteObject[] = [
  {
    path: ':saleID/payment',
    children: [
      {
        path: '',
        element: <PAIndoLedgerPaymentListing />,
      },
      {
        path: 'add',
        element: <PAIndoLedgerPaymentForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoLedgerPaymentForm mode="edit" />,
      },
      {
        path: ':id/view',
        element: <PAIndoLedgerPaymentDetails />,
      },
      {
        path: ':id/allocation',
        children: [
          {
            path: '',
            element: (
              <PAIndoPaymentApprovedAllocation
                tableRef="payment"
                origin="ledger"
              />
            ),
          },
          {
            path: 'add',
            element: <PAIndoPaymentAllocationForm mode="add" origin="ledger" />,
          },
          // {
          //   path: ':debitId/edit',
          //   element: <PAIndoPaymentAllocationForm mode="edit" />,
          // },
        ],
      },
    ],
  },
]

export default PaymentRoutes
