import { lazy } from 'react'
import { RouteObject } from 'react-router'

const PAIndoLedgerRebateListing = lazy(
  () => import('./PAIndoLedgerRebateListing')
)
const PAIndoLedgerRebateForm = lazy(() => import('./PAIndoLedgerRebateForm'))
const PAIndoLedgerRebateDetails = lazy(
  () => import('./PAIndoLedgerRebateDetails')
)

const RebateRoutes: RouteObject[] = [
  {
    path: ':saleID/rebate',
    children: [
      {
        path: '',
        element: <PAIndoLedgerRebateListing />,
      },
      {
        path: 'add',
        element: <PAIndoLedgerRebateForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoLedgerRebateForm mode="edit" />,
      },
      {
        path: ':id/view',
        element: <PAIndoLedgerRebateDetails />,
      },
    ],
  },
]

export default RebateRoutes
