import React, { lazy } from 'react'
import { RouteObject } from 'react-router'
import AreaVarianceIndoRoutes from './AreaVarianceIndo/Routes'
import AreaVariancePolicyIndoRoutes from './AreaVariancePolicyIndo/Routes'

const AreaVarianceIndoSubmenu = lazy(() => import('./AreaVarianceIndoSubmenu'))

export const AREA_VARIANCE_PATH = {
  SUBMENU: '/:projectID/area-variance-indo',
}

const AreavarianceIndoRoutes: RouteObject[] = [
  {
    path: 'area-variance-indo',
    children: [
      {
        path: '',
        element: <AreaVarianceIndoSubmenu />,
      },
      ...AreaVarianceIndoRoutes,
      ...AreaVariancePolicyIndoRoutes,
    ],
  },
]

export default AreavarianceIndoRoutes
