import { lazy } from 'react'
import { RouteObject } from 'react-router'
import CreditNoteRoutes from '../PurchaserLedger/CreditNote/Routes'
import DebitNoteRoutes from '../PurchaserLedger/DebitNote/Routes'
import DepositRoutes from '../PurchaserLedger/Deposit/Routes'
import LateInterestRoutes from '../PurchaserLedger/LateInterest/Routes'
import MiscBillingRoutes from '../PurchaserLedger/MiscBilling/Routes'
import PaymentReminderRoutes from './PaymentReminder/Routes'
import PurchaserInstallmentRoutes from '../PurchaserLedger/PurchaserInstallment/Routes'
import AdditionalPackagesInstallmentRoutes from './AdditionalPackages/Routes'
import RefundRoutes from '../PurchaserLedger/Refund/Routes'
import PaymentRoutes from './Payment/Routes'
import RebateRoutes from './Rebate/Routes'
import CarParkInstallmentRoutes from './CarParkInstallment/Routes'
import ContraRoutes from './Contra/Routes'
const PurchaserLedgerListing = lazy(() => import('./PurchaserLedgerListing'))

const PurchaserLedgerRoutes: RouteObject[] = [
  {
    path: 'purchaser-ledger',
    children: [
      {
        path: '',
        element: <PurchaserLedgerListing />,
      },
      {
        path: '',
        children: [
          ...PurchaserInstallmentRoutes,
          ...CarParkInstallmentRoutes,
          ...MiscBillingRoutes,
          ...DebitNoteRoutes,
          ...LateInterestRoutes,
          ...CreditNoteRoutes,
          ...RebateRoutes,
          ...PaymentRoutes,
          ...DepositRoutes,
          ...ContraRoutes,
          ...RefundRoutes,
          ...PaymentReminderRoutes,
          ...AdditionalPackagesInstallmentRoutes,
        ],
      },
    ],
  },
]

export default PurchaserLedgerRoutes
