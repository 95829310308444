import { RouteObject } from 'react-router'
import { lazy } from 'react'

const PAIndoCreditNoteDetail = lazy(() => import('./PAIndoCreditNoteDetail'))
const PAIndoCreditNoteForm = lazy(() => import('./PAIndoCreditNoteForm'))
const PAIndoCreditNoteListing = lazy(() => import('./PAIndoCreditNoteListing'))

//PA = purchaser's accounts
const PAIndoCreditNoteRoutes: RouteObject[] = [
  {
    path: 'credit-note',
    children: [
      {
        path: '',
        element: (
          <PAIndoCreditNoteListing tableRef="credit_note" path="credit-note" />
        ),
      },
      {
        path: ':id/view',
        element: <PAIndoCreditNoteDetail tableRef="credit_note" path="credit-note" />,
      },
      {
        path: 'add',
        element: <PAIndoCreditNoteForm mode="add" tableRef="credit_note" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoCreditNoteForm mode="edit" tableRef="credit_note" />,
      },
    ],
  },
]

export default PAIndoCreditNoteRoutes
//
