import { Autocomplete, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

interface props {
  control: any
  errors?: any
  label: string
  listData?: any
  name: string
  options?: any
  optionLabel: string
  isRequired?: boolean
}
const DropdownButton = ({
  control,
  errors,
  label,
  listData,
  name,
  options,
  optionLabel,
  isRequired = true,
}: props) => {
  return (
    <Controller
      key={name}
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <Autocomplete
          options={options ? options : listData}
          getOptionLabel={option => option?.[optionLabel]}
          fullWidth
          value={value}
          onChange={(event, value) => {
            onChange(value)
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={`${label} ${isRequired ? '*' : ''}`}
              variant="standard"
              margin="normal"
              value={value}
              autoComplete="off"
              error={errors?.[name] ? true : false} // use the actual field name for error checking
              helperText={errors?.[name]?.message}
            />
          )}
        />
      )}
    />
  )
}

export default DropdownButton
