import { lazy } from 'react'
import { RouteObject } from 'react-router'

const PAIndoLedgerLateInterestListing = lazy(
  () => import('./PAIndoLedgerLateInterestListing')
)
const PAIndoLedgerLateInterestForm = lazy(
  () => import('./PAIndoLedgerLateInterestForm')
)
const PAIndoLedgerLateInterestDetails = lazy(
  () => import('./PAIndoLedgerLateInterestDetail')
)

const LateInterestRoutes: RouteObject[] = [
  {
    path: ':saleID/late-interest',
    children: [
      {
        path: '',
        element: <PAIndoLedgerLateInterestListing />,
      },
      {
        path: 'add',
        element: <PAIndoLedgerLateInterestForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoLedgerLateInterestForm mode="edit" />,
      },
      {
        path: ':id/submitted',
        element: <PAIndoLedgerLateInterestDetails mode="submitted" />,
      },
      {
        path: ':id/details',
        element: <PAIndoLedgerLateInterestDetails mode="approved" />,
      },
    ],
  },
]

export default LateInterestRoutes
