import { lazy } from 'react'
import { RouteObject } from 'react-router'

const PAIndoLedgerDebitNoteListing = lazy(
  () => import('./PAIndoLedgerDebitNoteListing')
)
const PAIndoLedgerDebitNoteForm = lazy(
  () => import('./PAIndoLedgerDebitNoteForm')
)
const PAIndoLedgerDebitNoteDetails = lazy(
  () => import('./PAIndoLedgerDebitNoteDetails')
)

const MiscBillingRoutes: RouteObject[] = [
  {
    path: ':saleID/debit-note',
    children: [
      {
        path: '',
        element: <PAIndoLedgerDebitNoteListing />,
      },
      {
        path: 'add',
        element: <PAIndoLedgerDebitNoteForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoLedgerDebitNoteForm mode="edit" />,
      },
      {
        path: ':id/view',
        element: <PAIndoLedgerDebitNoteDetails />,
      },
    ],
  },
]

export default MiscBillingRoutes
