import {
  Container,
  EmptyState,
  FloatButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SearchBar,
  Spinner,
  SubHeaderContainer,
  Tab,
  Tabs,
  useMenuOption,
} from '@ifca-ui/core'
import { Add, MoreVert } from '@mui/icons-material'
import LockIcon from '@mui/icons-material/Lock'
import AppContext, { AppContextProps } from 'containers/context/Context'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { getUserProfile } from 'UserData'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import { Box, IconButton, Typography } from '@mui/material'
import { useFuseSearch } from 'helpers/Hooks/useSearch'

import {
  useGetBillItemByTypeQuery,
  useGetTaxSchemeListQuery,
  BillItemType,
} from 'generated/graphql'
import BillItems2Dialog from './BillItems2Dialog'
import BillItems2Delete from './BillItems2Delete'
import useNav from 'components/RouteService/useNav'

const billItemTypeEnums = [
  { type: BillItemType.Misc, label: 'Misc Items' },
  { type: BillItemType.DebitNote, label: 'Debit Notes' },
  { type: BillItemType.CreditNote, label: 'Credit Notes' },
  { type: BillItemType.Rebate, label: 'Rebate' },
  { type: BillItemType.Deposit, label: 'Deposit' },
  { type: BillItemType.Contra, label: 'Contra' },
]

export default function BillItems2Listing() {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  let navigate = useNavigate()
  const { navBack, navTo } = useNav()

  const subHeaderRef = useRef(null)
  //#region header/subheader
  useEffect(() => {
    if (subHeaderRef.current?.clientHeight) {
      rootDispatch({
        type: 'subHeaderHeight',
        payload: subHeaderRef.current?.clientHeight,
      })
    }
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navTo(`/common-setting`),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${getUserProfile()?.subscriptionName}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Transaction Type',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //#endregion

  const [tabIndex, settabIndex] = useState(0)
  const billItemType = billItemTypeEnums[tabIndex]
  const handleTabChange = (e: any, index: number) => settabIndex(index)

  const [search, setSearchInput] = React.useState('')
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()

  const { data: taxSchemasData } = useGetTaxSchemeListQuery()

  const { data, refetch, loading } = useGetBillItemByTypeQuery({
    variables: { type: billItemType.type },
    onCompleted: ({ getBillItemByType }) =>
      setOriginalListing(getBillItemByType),
  })

  useEffect(() => {
    refetch({ type: billItemType.type })
    return () => {}
  }, [tabIndex])

  const [dialogMode, setDialogMode] = useState<'add' | 'edit' | null>(null)
  const addDialog = (mode: typeof dialogMode) => setDialogMode(mode)

  const handleDialogClosed = () => setDialogMode(null)

  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const menuOpen = Boolean(anchorEl)

  const [deleteDialog, setDeleteDialog] = React.useState(false)

  return (
    <>
      {loading && <Spinner />}
      <SubHeaderContainer id="subheader-container" ref={subHeaderRef}>
        <Box width="100%">
          <Tabs
            className="bill-item-tabs"
            value={tabIndex}
            onChange={handleTabChange}
          >
            {billItemTypeEnums.map((billItemType, i) => (
              <Tab
                key={i}
                className="custom-tab-label"
                style={{ flex: 1 }}
                label={
                  <Typography
                    className="text-mdLabel"
                    style={{ whiteSpace: 'initial' }}
                  >
                    {billItemType.label}
                  </Typography>
                }
                value={i}
              />
            ))}
          </Tabs>
          <SearchBar
            title={
              <Typography
                component="span"
                className="text-mdLabel"
                variant="inherit"
              >
                {`Transaction Type Listing `} (
                <Typography
                  component="span"
                  variant="inherit"
                  className="search-bar-count-f"
                >
                  {`${filteredList?.length ?? 0}`}
                </Typography>
                )
              </Typography>
            }
            searchInput={{
              value: search,
              onChange: e => {
                setSearchInput(e.target.value)
                handleSearch(e.target.value, ['name'])
              },
              onChangeClearInput: () => {
                setSearchInput('')
                handleSearch('', ['name'])
              },
            }}
          />
        </Box>
      </SubHeaderContainer>
      <Box height={`${rootState?.subHeaderHeight}px`} />
      <>
        <Container bottomSpacing="lg">
          <List>
            {filteredList?.length === 0 ? (
              <EmptyState subTitle="Click add button to insert new records" />
            ) : (
              filteredList?.map((x, i) => {
                return (
                  <ListItem
                    key={i}
                    style={{
                      background:
                        x?.recordStatus === 'INACTIVE' ? '#9b9b9b' : '',
                    }}
                    secondaryAction={
                      <IconButton onClick={e => handleClick(e, x.id, i, x)}>
                        <MoreVert />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Box display="flex">
                          <Typography
                            component="span"
                            className="text-xsTitle"
                            variant="inherit"
                            flex="1"
                          >
                            {x.name}
                          </Typography>
                          {x.systemUse === true ? (
                            <LockIcon
                              style={{ width: '15px', height: '15px' }}
                            />
                          ) : null}
                        </Box>
                      }
                    />
                  </ListItem>
                )
              })
            )}
          </List>
          <FloatButton
            color="primary"
            onClick={() => {
              addDialog('add')
            }}
          >
            <Add />
          </FloatButton>
        </Container>
      </>

      <BillItems2Dialog
        mode={dialogMode}
        setMode={setDialogMode}
        handleDialogClose={handleDialogClosed}
        refetch={() => refetch({ type: billItemType.type })}
        billItem={dialogMode === "edit" && menu.data}
        billItemId={dialogMode === "edit" && menu.id}
        billItemType={billItemType.type}
        taxSchemas={taxSchemasData?.getTaxSchemeList}
      />

      <BillItems2Delete
        open={deleteDialog}
        setDeleteDialog={setDeleteDialog}
        refetch={() => refetch({ type: billItemType.type })}
        billItemId={menu.id}
      />

      <Menu
        id="default-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={() => addDialog('edit')}>Edit</MenuItem>
        {!menu?.data?.systemUse && (
          <MenuItem onClick={() => setDeleteDialog(true)}>Delete</MenuItem>
        )}
      </Menu>
    </>
  )
}
