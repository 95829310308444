import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const EndFinancierListing = lazy(() => import('./EndFinancierListing'))
const EndFinancierSubmenu = lazy(() => import('./EndFinancierSubmenu'))
const EndFinancierForm = lazy(() => import('./EndFinancierForm'))
const MortgagePlan = lazy(() => import('./MortgagePlan'))

// export const END_FINANCIER_PATH = {
//   ROOT: '/end-financier',
// }

// const EndFinancierRoutes = [
//   {
//     props: { exact: true, path: END_FINANCIER_PATH.ROOT },
//     component: <EndFinancierListing />,
//   },
//   {
//     props: {
//       exact: true,
//       path: `/end-financier/:id`,
//     },
//     component: <EndFinancierSubmenu />,
//   },
//   {
//     props: {
//       exact: true,
//       path: `/end-financier/end-financier-form/add`,
//     },
//     component: <EndFinancierForm formMode="Add" />,
//   },
//   {
//     props: {
//       exact: true,
//       path: `/end-financier/end-financier-form/:id/edit`,
//     },
//     component: <EndFinancierForm formMode="Edit" />,
//   },
// ]

const EndFinancierRoutes: RouteObject[] = [
  {
    path: '',
    element: <EndFinancierListing />,
  },
  {
    path: '',
    children: [
      {
        path: ':id',
        element: <EndFinancierSubmenu />,
      },
      {
        path: ":financierId/mortgage-plan",        
        element: <MortgagePlan />
      },
      {
        path: 'end-financier-form/add',
        element: <EndFinancierForm formMode="Add" />,
      },
      {
        path: 'end-financier-form/:id/edit',
        element: <EndFinancierForm formMode="Edit" />,
      },
    ],
  },
]

export default EndFinancierRoutes
