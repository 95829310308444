import { lazy } from 'react'
import { RouteObject } from 'react-router'

const NotaryAssignmentForm = lazy(() => import('./NotaryAssignmentForm'))

export const NOTARY_ASSIGNMENT_PATH = {
    NOTARY_ASSIGNMENT_FORM: '/notary-assignment',
  }

const notaryAssignmentRoutes: RouteObject[] = [
  {
    path: 'notary-assignment',
    children: [
      {
        path: '',
        element: <NotaryAssignmentForm />,
      },
    ],
  },
]
  
export default notaryAssignmentRoutes