import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const CarParkInstallmentUnitListing = lazy(
  () => import('./CarParkInstallmentUnitListing')
)

const CarParkInstallmentListing = lazy(
  () => import('./CarParkInstallmentListing')
)

const PurchaserInstallmentRoutes: RouteObject[] = [
  {
    path: ':saleID/car-park-installment',
    children: [
      {
        path: '',
        element: <CarParkInstallmentUnitListing />,
      },
      {
        path: ':carParkId',
        element: <CarParkInstallmentListing />,
      },
    ],
  },
]

export default PurchaserInstallmentRoutes
