import { lazy } from 'react'
import { RouteObject } from 'react-router'
import PAIndoMiscBillingDetail from './PAIndoMiscBillingDetail'
const PAIndoMiscBillingForm = lazy(() => import('./PAIndoMiscBillingForm'))
const PAIndoMiscBillingListing = lazy(
  () => import('./PAIndoMiscBillingListing')
)

//PA = purchaser's accounts
const PAIndoMiscBillingRoutes: RouteObject[] = [
  {
    path: 'misc-billing',
    children: [
      {
        path: '',
        element: <PAIndoMiscBillingListing tableRef="misc_billing" />,
      },
      {
        path: ':id/view',
        element: <PAIndoMiscBillingDetail />,
      },
      {
        path: 'add',
        element: <PAIndoMiscBillingForm mode="add" tableRef="misc_billing" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoMiscBillingForm mode="edit" tableRef="misc_billing" />,
      },
    ],
  },
]

export default PAIndoMiscBillingRoutes
