import { RouteObject } from 'react-router'
import { lazy } from 'react'

const PAIndoRefundDetail = lazy(() => import('./PAIndoRefundDetail'))
const PAIndoRefundForm = lazy(() => import('./PAIndoRefundForm'))
const PAIndoRefundListing = lazy(() => import('./PAIndoRefundListing'))

//PA = purchaser's accounts
const PAIndoRefundRoutes: RouteObject[] = [
  {
    path: 'refund',
    children: [
      {
        path: '',
        element: (
          // <PAIndoRefundListing tableRef="refund" path="refund" />
          <PAIndoRefundListing />
        ),
      },
      {
        path: ':id/view',
        element: <PAIndoRefundDetail tableRef="refund" path="refund" />,
      },
      {
        path: 'add',
        element: <PAIndoRefundForm mode="add" tableRef="refund" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoRefundForm mode="edit" tableRef="refund" />,
      },
    ],
  },
]

export default PAIndoRefundRoutes
//
