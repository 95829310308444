import { lazy } from 'react'
import { RouteObject } from 'react-router'
import PAIndoDepositDetail from './PAIndoDepositDetail'
const PAIndoDepositForm = lazy(() => import('./PAIndoDepositForm'))
const PAIndoDepositListing = lazy(() => import('./PAIndoDepositListing'))

const PAIndoDepositRoutes: RouteObject[] = [
  {
    path: 'deposit',
    children: [
      {
        path: '',
        element: <PAIndoDepositListing tableRef="deposit" />,
      },
      {
        path: ':id/view',
        element: <PAIndoDepositDetail />,
      },
      {
        path: 'add',
        element: <PAIndoDepositForm mode="add" tableRef="deposit" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoDepositForm mode="edit" tableRef="deposit" />,
      },
    ],
  },
]

export default PAIndoDepositRoutes
