import { lazy } from 'react'
import { RouteObject } from 'react-router'

const PaymentReminderListing = lazy(() => import('./PaymentReminderListing'))

const PaymentReminderRoutes: RouteObject[] = [
  {
    path: ':saleID/payment-reminder',
    children: [
      {
        path: '',
        element: <PaymentReminderListing />,
      },
    ],
  },
]

export default PaymentReminderRoutes
