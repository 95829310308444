import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const AuthorizedSaleStaffIndoListing = lazy(
  () => import('./AuthorizedSaleStaffIndoListing')
)

const AuthorizedSaleStaffIndoRoute: RouteObject[] = [
  {
    path: 'collection-staff',
    children: [
      { path: '', element: <AuthorizedSaleStaffIndoListing /> },
    ],
  },
]

export default AuthorizedSaleStaffIndoRoute
