import React, { useEffect } from 'react'
import { Dialog } from 'components/Dialogs/Dialog'
import { MenuItem, Subheader, SubheaderText } from '@ifca-ui/core'
import { Box, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { CommonYupValidation } from 'helpers/yup'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import {
  useCreateBillItemMutation,
  BillItemType,
  useUpdateBillItemMutation,
} from 'generated/graphql'

interface BillItems2DialogProps {
  mode: 'add' | 'edit' | null
  setMode: any
  handleDialogClose: () => void
  refetch: any
  // edit data
  billItemType: BillItemType
  billItemId?: string
  billItem?: any
  taxSchemas?: any[]
}

const latePaymentInterest = ['Yes', 'No'] as const

interface BillItemFormFields {
  name: string
  latePaymentInterest: string
  taxSchema: string
  creditTerm: string
  gracePeriod: string
}

export default function BillItems2Dialog({
  mode,
  setMode,
  handleDialogClose,
  refetch,
  billItem,
  billItemId,
  taxSchemas,
  billItemType,
  ...props
}: BillItems2DialogProps) {
  //#region schema validation
  const schema: yup.SchemaOf<BillItemFormFields> = yup.object({
    name: CommonYupValidation.requireField(SystemMsgs.name()),
    latePaymentInterest: yup
      .mixed<typeof latePaymentInterest[number]>()
      .oneOf([...latePaymentInterest])
      .required('Field is required'),
    taxSchema: CommonYupValidation.requireField('Field is required'),
    creditTerm: CommonYupValidation.requireField('Field is required'),
    gracePeriod: CommonYupValidation.requireField('Field is required'),
  })
  //#endregion

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm<BillItemFormFields>({
    mode: 'all',
    resolver: yupResolver(schema),
  })

  const open = mode !== null
  useEffect(() => {
    if (!open || mode === null)
      reset({
        creditTerm: '',
        taxSchema: '',
        gracePeriod: '',
        latePaymentInterest: '',
        name: '',
      })
    if (open && billItem) {
      const { taxSchema, systemUse, __typename, ...item } = billItem
      reset({ ...item, taxSchema: taxSchema?.id })
    }

    return () => {}
  }, [open, mode])

  const [createBillItem, { loading: createLoading }] =
    useCreateBillItemMutation({
      onCompleted: data => {
        if (data.createBillItem) setSnackBar(SystemMsgs.createNewRecord())
        refetch()
      },
    })

  const [updateBillItem, { loading: updateLoading }] =
    useUpdateBillItemMutation({
      onCompleted: data => {
        if (data.updateBillItem) setSnackBar(SystemMsgs.updateRecord())
        refetch()
      },
    })

  const onSubmit = (data: BillItemFormFields) => {
    const { creditTerm, gracePeriod } = data

    const input = {
      ...data,
      creditTerm: parseFloat(creditTerm),
      gracePeriod: parseFloat(gracePeriod),
      billItemType,
    }

    if (mode === 'add') {
      createBillItem({ variables: { input } })
    } else {
      updateBillItem({ variables: { input: { ...input, id: billItemId } } })
    }
    handleDialogClose()
  }

  const onSaveAndNew = (data: BillItemFormFields) => {
    onSubmit(data)
    setMode('add')
  }

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={() => handleDialogClose}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Transaction Type
                  </Typography>
                  <Typography
                    className="text-xsTitle"
                    color="primary.dark"
                    component="span"
                    variant="inherit"
                  >
                    {mode === 'add' ? 'New' : 'Edit'}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <>
          <Controller
            control={control}
            name="name"
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <TextField
                label="Name"
                autoComplete="off"
                error={!!error}
                helperText={error?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                fullWidth
                variant="standard"
                margin="normal"
                required
                disabled={billItem.systemUse}
              />
            )}
          />
          <Controller
            control={control}
            name="latePaymentInterest"
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <TextField
                label="Late Payment Interest"
                placeholder={latePaymentInterest[0]}
                autoComplete="off"
                error={!!error}
                helperText={error?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                fullWidth
                variant="standard"
                margin="normal"
                required
                select
              >
                {latePaymentInterest.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            control={control}
            name="taxSchema"
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <TextField
                label="Tax Schema"
                placeholder={latePaymentInterest[0]}
                autoComplete="off"
                error={!!error}
                helperText={error?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                fullWidth
                variant="standard"
                margin="normal"
                required
                select
              >
                {taxSchemas.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Box display="flex" flexDirection="row" gap={1}>
            <Controller
              control={control}
              name="creditTerm"
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Credit Term"
                  placeholder="0"
                  error={!!error}
                  helperText={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  required
                  variant="standard"
                  margin="normal"
                />
              )}
            />
            <Controller
              control={control}
              name="gracePeriod"
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Grace Period"
                  autoComplete="off"
                  error={!!error}
                  helperText={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  required
                  variant="standard"
                  margin="normal"
                />
              )}
            />
          </Box>
        </>
      }
      footer={{
        buttons:
          mode === 'edit'
            ? [
                {
                  children: 'Cancel',
                  color: 'primary',
                  onClick: () => handleDialogClose(),
                },

                {
                  children: 'Save',
                  color: 'primary',
                  onClick: () => handleSubmit(onSubmit)(),
                },
              ]
            : [
                {
                  children: 'Cancel',
                  color: 'primary',
                  onClick: () => handleDialogClose(),
                },
                {
                  children: 'Save & New',
                  color: 'primary',
                  onClick: () => handleSubmit(onSaveAndNew)(),
                },
                {
                  children: 'Save',
                  color: 'primary',
                  onClick: () => handleSubmit(onSubmit)(),
                },
              ],
      }}
    />
  )
}
