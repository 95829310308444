import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const RefinancingDraftListing = lazy(() => import('./RefinancingDraftListing'))
const RefinancingDraftForm = lazy(() => import('./RefinancingDraftForm'))
const RefinancingSubmittedDetails = lazy(
  () => import('./RefinancingSubmittedDetails')
)

const RefinancingRoutes: RouteObject[] = [
  {
    path: 'sale-loan-refinancing',
    children: [
      {
        path: '',
        element: <RefinancingDraftListing />,
      },
      {
        path: 'add',
        element: <RefinancingDraftForm mode="add" />,
      },
      {
        path: 'edit/:id',
        element: <RefinancingDraftForm mode="edit" />,
      },
      {
        path: 'details/:id/:approvalStatus',
        element: <RefinancingSubmittedDetails />,
      },
    ],
  },
]

export default RefinancingRoutes
