import { lazy } from 'react'
import { RouteObject } from 'react-router'

const PurchaserPaymentPlanListing = lazy(
  () => import('./PurchaserPaymentPlanListing')
)

const PurchaserPaymentPlanForm = lazy(
  () => import('./PurchaserPaymentPlanForm')
)

// const PurchaserPaymentPlanDetailForm = lazy(() => import('./PurchaserPaymentPlanDetailForm'))

// const PurchaserPaymentPlanView = lazy(() => import('./PurchaserPaymentPlanView'))

// const PurhcaserPaymentPlanDetailView = lazy(() => import('./PurchaserPaymentPlanDetailView'))

const purchaserPaymentPlanRoutes: RouteObject[] = [
  {
    path: 'purchaser-payment-plan',
    children: [
      {
        path: '',
        element: <PurchaserPaymentPlanListing />,
      },
      {
        path: 'add',
        children: [
          {
            path: '',
            element: <PurchaserPaymentPlanForm mode="add" />,
          },
        ],
      },
      {
        path: ':id/edit',
        children: [
          {
            path: '',
            element: <PurchaserPaymentPlanForm mode="edit" />,
          },
        ],
      },
    ],
  },
]

export default purchaserPaymentPlanRoutes
