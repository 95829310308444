import React, { lazy } from 'react'
const LandDeposit = lazy(() => import('./LandDeposit'))
const LandDepositList = lazy(() => import('./LandDepositList'))
const LandDepositView = lazy(() => import('./LandDepositView'))

const LADRoutes = [
  {
    path: 'land-deposit',
    children: [
      {
        path: '',
        element: <LandDeposit />,
      },
      {
        path: ':saleId',
        element: <LandDepositList />,
      },
      {
        path: ':saleId/:view',
        element: <LandDepositView />,
      },
    ],
  },
]
export default LADRoutes
