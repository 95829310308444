import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const AreaVariancePolicyListingIndo = lazy(
  () => import('./AreaVariancePolicyListingIndo')
)
const AreaVariancePolicyFormIndo = lazy(() => import('./AreaVariancePolicyFormIndo'))

const AreaVariancePolicyIndoRoutes: RouteObject[] = [
  {
    path: 'area-variance-policy-indo',
    children: [
      {
        path: '',
        element: <AreaVariancePolicyListingIndo />,
      },
      {
        path: 'add',
        element: <AreaVariancePolicyFormIndo mode="add" />,
      },
      {
        path: 'edit',
        element: <AreaVariancePolicyFormIndo mode="edit" />,
      },
    ],
  },
]

export default AreaVariancePolicyIndoRoutes
