import { RouteObject } from 'react-router'
import { lazy } from 'react'

const PAIndoRebateDetail = lazy(() => import('./PAIndoRebateDetail'))
const PAIndoRebateForm = lazy(() => import('./PAIndoRebateForm'))
const PAIndoRebateListing = lazy(() => import('./PAIndoRebateListing'))

//PA = purchaser's accounts
const PAIndoRebateRoutes: RouteObject[] = [
  {
    path: 'rebate',
    children: [
      {
        path: '',
        element: (
          <PAIndoRebateListing tableRef="rebate" path="rebate" />
        ),
      },
      {
        path: ':id/view',
        element: <PAIndoRebateDetail tableRef="rebate" path="rebate" />,
      },
      {
        path: 'add',
        element: <PAIndoRebateForm mode="add" tableRef="rebate" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoRebateForm mode="edit" tableRef="rebate" />,
      },
    ],
  },
]

export default PAIndoRebateRoutes
//
