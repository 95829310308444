import {
  Card,
  Container,
  Spinner,
  SubHeaderContainer,
  Subheader,
  SubheaderText,
} from '@ifca-ui/core'
import { MonetizationOnRounded } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import { CustomSubheader } from 'components/General/CustomSubheader'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import ColumnFlex from 'components/Layout/ColumnLayout'
import { RowFlex } from 'components/Layout/RowLayout'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { AttachmentsListComponent } from 'containers/modules/PurchaserAccounts/Components/AttachmentsListComponent'
import { useGetPaIndomiscBillingByIdQuery } from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import { amtStr } from 'helpers/numFormatter'
import { useContext, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

const PAIndoMiscBillingDetail = (props: any) => {
  const subHeaderRef = useRef(null)
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const { projectID, id }: any = useParams()
  let navigate = useNavigate()
  const { navBack, navTo } = useNav()
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  //#region API

  const {
    loading: getPAIndomiscBillingByIdLoading,
    data: { getPAIndomiscBillingById } = { getPAIndomiscBillingById: null },
  } = useGetPaIndomiscBillingByIdQuery({
    variables: { projectId: projectID, id: id },
    onCompleted: data => {
      if (subHeaderRef.current?.clientHeight) {
        rootDispatch({
          type: 'subHeaderHeight',
          payload: subHeaderRef.current?.clientHeight,
        })
      }

      const name = data.getPAIndomiscBillingById?.projectDetail?.name ?? '-'

      rootDispatch({
        type: 'headerComponent',
        payload: {
          ...rootState.headerComponent,
          leftIcon: {
            icon: 'back',
            props: {
              onClick: () =>
                nextPath(`/${projectID}/purchaser-accounts-indo/misc-billing`),
            },
          },
          rightIcon: {},
          topSection: {
            smTitle: TopSectionHeader,
            title: name,
          },
          bottomSection: {
            breadcrumbs: {
              maxItems: 4,
              current: 'Misc Billing',
            },
            rightText: 'Detail',
          },
        },
      })
    },
  })

  const nextPath = path => {
    navTo(path, 'APPROVED')
  }

  //#endregion

  return (
    <>
      {getPAIndomiscBillingByIdLoading && <Spinner />}

      <SubHeaderContainer id="subheader-container" ref={subHeaderRef}>
        <Subheader>
          <SubheaderText
            primary={
              <ColumnFlex gap="1px">
                <RowFlex mainAxisAlignment="SPACE_BETWEEN">
                  <Typography
                    className="text-xsTitle"
                    color="primary"
                    style={{ fontSize: '11px' }}
                  >
                    {formatDate(getPAIndomiscBillingById?.documentDate)}
                  </Typography>

                  <Typography
                    className="text-xsTitle"
                    color="primary"
                    style={{ fontSize: '11px' }}
                  >
                    {getPAIndomiscBillingById?.documentNo}
                  </Typography>

                  <RowFlex>
                    {/* <Typography className="text-desc text-icon" color="primary">
                      <MonetizationOnRounded />
                    </Typography> */}

                    <Typography
                      className="text-xsTitle"
                      color="primary"
                      style={{ fontSize: '11px' }}
                    >
                      {amtStr(getPAIndomiscBillingById?.documentAmount)}
                    </Typography>
                  </RowFlex>
                </RowFlex>
              </ColumnFlex>
            }
            secondary={
              <ColumnFlex height="10px">
                <RowFlex mainAxisAlignment="SPACE_BETWEEN" width="100%">
                  <Typography className="text-desc">
                    {getPAIndomiscBillingById?.clusterName?.name}/
                    {getPAIndomiscBillingById?.unitDetail}{' '}
                    {getPAIndomiscBillingById?.contactDetail}
                  </Typography>

                  <RowFlex>
                    {/* <Typography className="text-desc text-icon" color="red">
                      <MonetizationOnRounded />
                    </Typography> */}

                    <Typography
                      className="text-xsTitle"
                      color="red"
                      style={{ fontSize: '11px' }}
                    >
                      {amtStr(getPAIndomiscBillingById?.unallocatedAmount)}
                    </Typography>
                  </RowFlex>
                </RowFlex>
              </ColumnFlex>
            }
          />
        </Subheader>
      </SubHeaderContainer>
      <Box height={`${rootState?.subHeaderHeight}px`} />
      <Container>
        <Card className="card-container-detail">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className="label-f">Date</Typography>
              <Typography className="value-f">
                {formatDate(getPAIndomiscBillingById?.documentDate)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Due Date</Typography>
              <Typography className="value-f">
                {formatDate(getPAIndomiscBillingById?.dueDate)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="label-f">Bill Item</Typography>
              <Typography className="value-f">
                {getPAIndomiscBillingById?.billItemInt?.name}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography className="label-f">Credit Term</Typography>
              <Typography className="value-f">
                {getPAIndomiscBillingById?.creditTerm}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Interest Rate</Typography>
              <Typography className="value-f">
                {getPAIndomiscBillingById?.interestRate}%
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography className="label-f">Amount</Typography>
              <Typography className="value-f">
                {amtStr(
                  getPAIndomiscBillingById?.documentAmount -
                    getPAIndomiscBillingById?.taxLedger?.taxAmount
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Tax Scheme</Typography>
              <Typography className="value-f">
                {getPAIndomiscBillingById?.taxData?.taxSchemeName ?? '-'}
              </Typography>
            </Grid>
            {/* <Grid item xs={6}>
              <Typography className="label-f">Tax Rate</Typography>
              <Typography className="value-f">
                {getPAIndomiscBillingById?.taxData?.totalTaxRate
                  ? `${getPAIndomiscBillingById.taxData.totalTaxRate}%`
                  : '-'}
              </Typography>
            </Grid> */}
            <Grid item xs={6}>
              <Typography className="label-f">Tax Amount</Typography>
              <Typography className="value-f">
                {amtStr(getPAIndomiscBillingById?.taxLedger?.taxAmount)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Total Amount</Typography>
              <Typography className="value-f">
                {amtStr(getPAIndomiscBillingById?.documentAmount)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="label-f">Remarks</Typography>
              <Typography className="value-f">
                {getPAIndomiscBillingById?.description ?? '-'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography className="label-f">Attachments</Typography>
              <Typography
                className="value-f text-underline text-xsTitle text-pointer"
                onClick={e => setOpenDialog(true)}
              >
                {getPAIndomiscBillingById?.attachment.length}
              </Typography>
            </Grid>
            <AttachmentsListComponent
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              listData={getPAIndomiscBillingById?.attachment}
            />
            <Grid item xs={12}>
              <Typography className="label-f">Request By</Typography>
              <Typography className="value-f">
                {getPAIndomiscBillingById?.requestUserData?.name
                  ? getPAIndomiscBillingById?.requestUserData?.name
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Submitted By</Typography>
              <Typography className="value-f">
                {getPAIndomiscBillingById?.submitUserData?.name ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Submitted Date</Typography>
              <Typography className="value-f">
                {getPAIndomiscBillingById?.submittedDate
                  ? formatDate(getPAIndomiscBillingById?.submittedDate)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Approved By</Typography>
              <Typography className="value-f">
                {getPAIndomiscBillingById?.approveUserData?.name ?? '-'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography className="label-f">Approved Date</Typography>
              <Typography className="value-f">
                {getPAIndomiscBillingById?.approvedDate
                  ? formatDate(getPAIndomiscBillingById?.approvedDate)
                  : '-'}
              </Typography>
            </Grid>

            {/* <Grid item xs={6}>
              <Typography className="label-f">Void Date</Typography>
              <Typography className="value-f">
                {getPAIndomiscBillingById?.voidDate
                  ? formatDate(getPAIndomiscBillingById?.approvedDate)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              
              <Typography className="label-f">Void By</Typography>
              <Typography className="value-f">
                {getPAIndomiscBillingById?.voidUserData?.name ?? '-'}
              </Typography>
            </Grid> */}
          </Grid>
        </Card>
      </Container>
    </>
  )
}

export default PAIndoMiscBillingDetail
