import { lazy } from 'react'
import { RouteObject } from 'react-router'

const LateInterestExceptionListing = lazy(
  () => import('./LateInterestExceptionListing')
)
const LateInterestExceptionForm = lazy(
  () => import('./LateInterestExceptionForm')
)

const LatePaymentInterestExceptionRoutes: RouteObject[] = [
  {
    path: 'late-payment-interest-exception',
    children: [
      {
        path: '',
        element: <LateInterestExceptionListing />,
      },
      {
        path: 'add',
        element: <LateInterestExceptionForm />,
      },
    ],
  },
]

export default LatePaymentInterestExceptionRoutes
