import { yupResolver } from '@hookform/resolvers/yup'
import {
  Autocomplete,
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'

import { DialogForm } from 'components/Form/DialogForm'
import { AddressAutocompleteDialog } from 'components/Inputs/AddressAutocomplete'
import { AddressJson } from 'generated/graphql'
import { useAddressAutoComplete } from 'helpers/Hooks/useAddressAutoComplete'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/yup'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import * as yup from 'yup'

export interface addressProps {
  addressId?: string
  isPrimary: boolean
  addressType: string
  address: AddressJson
}
interface purchaserInfoAddressDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  addressList: addressProps[]
  dialogMode: 'Add' | 'Edit' | ''
  menu: addressProps
  index: number
  update: any
  append: any
}

export interface addressTypeProps {
  addressType: string
}

export const PurchaserInfoAddressDialog = ({
  open,
  setOpen,
  dialogMode,
  menu,
  addressList,
  index,
  update,
  append,
}: purchaserInfoAddressDialogProps) => {
  // const [addAddressDialog, setAddAddressDialog] = React.useState(false)
  const { projectID, saleID }: any = useParams()
  const [addressType, setAddressType] = useState<string[]>([
    'ID Address',
    'Billing Address',
    'Postal Address',
    'Company Address',
  ])
  const [addrValue, setAddress] = useState(null)

  //region schema validation
  const validationSchema = yup.object().shape({
    addressType: CommonYupValidation.requireField(SystemMsgs.addressType()),
  })
  //#endregion

  //#region init useform method
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    setValue,
    reset,
    watch,
  } = useForm<addressProps>({
    defaultValues: {
      addressType: null,
      isPrimary: false,
    },

    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  })

  //#endregion

  // const handleDialogClosed = () => {
  //   setAddAddressDialog(false)
  //   setDialogMode('')
  //   // reset({
  //   //   name: '',
  //   // })
  // }

  // -- auto address start
  const {
    getCountry, // country
    getStatebyCountry, // State
    getCitybyState, // City
    getPostcodebyCity, // Postcode
    handleCountryOnChange,
    handleStateOnChange,
    handleCityOnChange,
    handlePostCodeOnChange,
    handleGoogleOnChange,
    query,
    autoCompleteRef,
    state,
    setQuery,
    initializeEditValue,
    loadGoogleApi,
  } = useAddressAutoComplete()
  // -- auto address end

  // const [
  //   getAddress,
  //   {
  //     data: { getAddressById } = { getAddressById: null },
  //     loading: getAddressByIdLoading,
  //     error: getAddressByIdError,
  //   },
  // ] = useGetAddressByIdLazyQuery({})

  useEffect(() => {
    if (open) {
      loadGoogleApi()

      if (menu) {
        let array_of_address = [menu?.address.address] || []
        setQuery(array_of_address.join(', '))
        setValue('addressType', menu?.address?.AddressType)
        setValue('isPrimary', menu?.isPrimary)
        setAddress(array_of_address?.slice(0))
        initializeEditValue({
          address: menu?.address.address,
          country: menu?.address.country,
          state: menu?.address.state,
          city: menu?.address.city,
          postCode: menu?.address.postCode,
        })
      }

      // getAddress({
      //   variables: {
      //     saleId: saleID,
      //   },
      // })
    }
  }, [open])

  useEffect(() => {
    if (open && dialogMode == 'Add') {
      reset()
      setQuery('')
      initializeEditValue({
        address: '',
        country: '',
        state: '',
        city: '',
        postCode: '',
      })
    }
  }, [open, dialogMode])

  useEffect(() => {
    if (open) {
      if (addressList) {
        const filteredAddressTypes = addressType.filter(
          type =>
            !addressList
              ?.flatMap(address => address?.address?.AddressType)
              ?.includes(type)
        )

        if (dialogMode === 'Edit') {
          filteredAddressTypes.push(menu?.address.AddressType)
        }

        setAddressType(filteredAddressTypes)
      }
    }
  }, [open, addressList])

  const onSubmit = (data: addressProps) => {
    if (
      state?.address?.length > 0 &&
      state.city.name?.length > 0 &&
      state.country.name?.length > 0 &&
      state.postcode.postcode?.length > 0 &&
      state.state.name?.length > 0
    ) {
      setOpen(false)

      const submitData: addressProps = {
        address: {
          AddressType: data.addressType,
          address: state.address,
          city: state.city.name,
          country: state.country.name,
          postCode: state.postcode.postcode,
          state: state.state.name,
        },
        isPrimary: data.isPrimary,
        addressType: data.addressType,
        addressId: menu?.addressId ? menu?.addressId : null,
      }

      if (data?.isPrimary) {
        addressList?.map((el, i) => {
          update(i, {
            ...el,
            isPrimary: false,
          })
        })
      }

      if (dialogMode == 'Edit') {
        update(index, submitData)
      } else {
        append(submitData)
      }

      reset()
    }
  }

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsSelectedAddress(event.target.checked)
  // }

  return (
    <>
      <DialogForm
        setOpenDialog={setOpen}
        dialogAction={open}
        mode={dialogMode}
        //onClose={() => handleDialogClosed()}
        title={'Address'}
        //subtitle={'Please fill in the form below'}
        body={
          <div>
            <Box pr="6px" width="100%">
              <Controller
                control={control}
                name="addressType"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Autocomplete
                    options={addressType}
                    // getOptionLabel={option}
                    fullWidth
                    onChange={(event, value) => {
                      onChange(value)
                    }}
                    value={value}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Address Type"
                        required
                        variant="standard"
                        margin="normal"
                        value={value}
                        autoComplete="off"
                        helperText={errors?.addressType?.message}
                        error={errors?.addressType ? true : false}
                      />
                    )}
                  />
                )}
              />
            </Box>
            <AddressAutocompleteDialog
              sections={{
                address: {
                  props: {
                    inputRef: autoCompleteRef,
                    label: 'Address *',
                    variant: 'standard',
                    margin: 'normal',
                    value: query,
                    multiline: true,
                    onChange: e => {
                      handleGoogleOnChange(e.target.value)
                    },
                    style: { width: '100%' },
                    helperText:
                      isSubmitted && !query ? 'Address is required' : null,
                    error: isSubmitted && !query ? true : false,
                    // defaultValue: editPurchaser?.contact?.address?.address,
                  },
                },
                country: {
                  props: {
                    value: state.country,
                    label: 'Country *',
                    margin: 'normal',
                    options: getCountry || [],
                    style: { width: '100%' },
                    variant: 'standard',
                    //filterOptions: filterOptions,
                    onChange: (event, newValue) => {
                      if (newValue) {
                        handleCountryOnChange(newValue)
                      }
                    },
                    onInputChange: (e, newValue) => {
                      handleCountryOnChange({ name: newValue })
                    },
                    getOptionLabel: (option: any) => option.name || '',
                    renderInput: params => {
                      return (
                        <TextField
                          {...params}
                          value={state.country}
                          label="Country *"
                          variant="standard"
                          helperText={
                            isSubmitted && !state?.country?.name
                              ? 'Country is required'
                              : null
                            // errors?.country?.message
                          }
                          error={
                            isSubmitted && !state?.country?.name ? true : false
                          }
                        />
                      )
                    },
                  },
                },
                state: {
                  props: {
                    value: state.state,
                    // defaultValue: state.state,
                    options: getStatebyCountry || [],
                    style: { width: '100%' },
                    margin: 'normal',
                    variant: 'standard',
                    label: 'State *',
                    getOptionLabel: option => option.name || '',
                    onChange: (e, newValue) => {
                      if (newValue) {
                        handleStateOnChange(
                          newValue || { name: e.target.value }
                        )
                      }
                    },
                    onInputChange: (e, newValue) => {
                      handleStateOnChange({ name: newValue })
                    },
                    renderInput: params => {
                      return (
                        <TextField
                          {...params}
                          // onChange={(e: any, newValue) =>
                          //   handleStateOnChange({ name: e.target.value })
                          // }
                          value={state.state}
                          // defaultValue={state.state}
                          label="State *"
                          variant="standard"
                          margin="normal"
                          helperText={
                            isSubmitted && !state?.state?.name
                              ? 'State is required'
                              : null
                            // errors?.country?.message
                          }
                          error={
                            isSubmitted && !state?.state?.name ? true : false
                          }
                        />
                      )
                    },
                  },
                },
                city: {
                  props: {
                    // className: `left`,
                    value: state.city,
                    options: getCitybyState || [],
                    style: { width: '100%' },
                    margin: 'normal',
                    variant: 'standard',
                    label: 'State *',
                    getOptionLabel: option => option.name || '',
                    onChange: (e, newValue) => {
                      if (newValue) {
                        handleCityOnChange(newValue)
                      }
                    },
                    onInputChange: (e, newValue) => {
                      handleCityOnChange({ name: newValue })
                    },
                    renderInput: params => {
                      return (
                        <TextField
                          {...params}
                          // onChange={(e: any, newValue) =>
                          //   handleCityOnChange({ name: e.target.value })
                          // }
                          value={state.city}
                          label="City *"
                          variant="standard"
                          margin="normal"
                          style={{ width: '100%' }}
                          helperText={
                            isSubmitted && !state?.city?.name
                              ? 'City is required'
                              : null
                            // errors?.country?.message
                          }
                          error={
                            isSubmitted && !state?.city?.name ? true : false
                          }
                        />
                      )
                    },
                  },
                },
                postCode: {
                  props: {
                    value: state.postcode,
                    options: getPostcodebyCity || [],
                    style: { width: '100%' },
                    margin: 'normal',
                    variant: 'standard',
                    label: 'Postcode *',
                    getOptionLabel: option => option.postcode || '',
                    onChange: (e, newValue) => {
                      if (newValue) {
                        handlePostCodeOnChange(newValue)
                      }
                    },
                    onInputChange: (e, newValue) => {
                      handlePostCodeOnChange({ postcode: newValue })
                    },
                    renderInput: params => {
                      return (
                        <TextField
                          {...params}
                          value={state.postcode}
                          style={{ width: '100%', paddingLeft: '4px' }}
                          label="Postcode *"
                          margin="normal"
                          variant="standard"
                          helperText={
                            isSubmitted && !state?.postcode?.postcode
                              ? 'Postcode is required'
                              : null
                            // errors?.country?.message
                          }
                          error={
                            isSubmitted && !state?.postcode?.postcode
                              ? true
                              : false
                          }
                        />
                      )
                    },
                  },
                },
              }}
            />
            {/* <Box display={'flex'} marginTop={'20px'}>
              <Typography
                className="userDialogLabel"
                component="span"
                // flex="1 1"
                variant="inherit"
                marginTop={'6px'}
                marginRight={'30px'}
              >
                Primary
              </Typography>

              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="isPrimary"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Switch
                        // className="switch-primary-color"
                        checked={value}
                        onChange={e => {
                          onChange(e.target.checked)
                        }}
                        color="primary"
                      />
                    )}
                  />
                }
                label=""
              />
            </Box> */}
          </div>
        }
        footer={{
          buttons: [
            {
              children: 'Cancel',
              color: 'primary',
              onClick: () => {
                setOpen(false)
                reset()
              },
            },

            {
              children: 'Save',
              color: 'primary',
              onClick: () => handleSubmit(onSubmit)(),
            },
          ],
        }}
      />
    </>
  )
}
