import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const LoanPaymentListing = lazy(() => import ('./LoanPaymentListing'))
const LoanPaymentForm = lazy (() => import ('./LoanPaymentForm'))
const LoanPaymentDetails = lazy (() => import ('./LoanPaymentDetails'))

export const LOAN_PAYMENT_PATH = {
    LOAN_PAYMENT_LISTING: './:projectID/loan-payment-listing',
    LOAN_PAYMENT_ADD: './:projectID/loan-payment-listing/add',
    LOAN_PAYMENT_EDIT: './:projectID/loan-payment-listing/edit',
    LOAN_PAYMENT_DETAILS: './:projectID/loan-payment-listing/details',
}

const LoanPaymentRoutes : RouteObject [] = [
    {
        path: '',
        children: [
            {
                path:'loan-payment-listing',
                element: <LoanPaymentListing/>
            },
            {
                path:'loan-payment-listing/add',
                element:<LoanPaymentForm mode="add"/>
            },
            {
                path:'loan-payment-listing/edit',
                element:<LoanPaymentForm mode="edit"/>
            },
            {
                path:'loan-payment-listing/details/:saleLoanPaymentId',
                element:<LoanPaymentDetails/>
            }
        ]
    }
]

export default LoanPaymentRoutes