import { yupResolver } from '@hookform/resolvers/yup'
import {
  Container,
  Dialog,
  EmptyState,
  Footer,
  List,
  ListItem,
  ListItemText,
  SubContainerX,
  Subheader,
  SubheaderText,
} from '@ifca-ui/core'
import {
  CalendarMonthRounded,
  HouseOutlined,
  MonetizationOnRounded,
} from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import { DatePickerX } from 'components/Inputs/DatePickerX'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { format } from 'date-fns'
import {
  useAddLadMutation,
  useCalculateLadGenerateValueLazyQuery,
  useGetProjectLadByProjectIdQuery,
  useProjectDetailQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { getDate } from 'helpers/checkDatetime'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { CommonYupValidation } from 'helpers/yup'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useLocation, useNavigate, useParams } from 'react-router'
import * as yup from 'yup'

interface GenrateLADResultProps {
  documentDate: Date | null
}

const LADProcessingGenerateResult = (props: any) => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  let navigate = useNavigate()
  const { navBack, navTo } = useNav()
  const { projectID }: any = useParams()

  const [postDialog, setPostDialog] = useState(false)
  let location = useLocation()
  const editData = location?.state as any

  const GenerateLADResultSchema = yup.object().shape({
    documentDate:
      editData?.isSimulate == false
        ? CommonYupValidation.requireDate('Document Date is required').min(
            getDate(editData?.handoverDateByUnit),
            `Document Date must be greater than or equal to ${moment(
              editData?.handoverDateByUnit
            ).format('DD MMM YYYY')}`
          )
        : CommonYupValidation.date(),
  })

  const {
    handleSubmit,
    control,
    register,
    setValue,
    reset,
    setError,
    getValues,
    formState: { errors, isSubmitted },
    watch,
  } = useForm<GenrateLADResultProps>({
    mode: 'onSubmit',
    resolver: yupResolver(GenerateLADResultSchema),
    defaultValues: {
      documentDate: new Date(),
    },
  })

  const [
    calculateLADGenerateValueQuery,
    {
      data: { calculateLADGenerateValue } = {
        calculateLADGenerateValue: null,
      },
      loading: generateResultLoading,
    },
  ] = useCalculateLadGenerateValueLazyQuery({
    fetchPolicy: 'network-only',
  })

  const calculateValue = (
    startWith: string,
    handoverDateByUnit: Date,
    handoverDateByCommonFacility: Date,
    unitLadPercent: number,
    spaPercent: number,
    unitDuration: number,
    facilityDuration: number,
    payoutPercent: number,
    facilityLADPercent: number,
    units: string[],
    taxSchemeId: string,
    commonFacility: boolean
  ) => {
    calculateLADGenerateValueQuery({
      variables: {
        input: {
          facilityDuration: facilityDuration,
          facilityLADPercent: facilityLADPercent,
          payoutPercent: payoutPercent,
          unitDuration: unitDuration,
          handoverDateByCommonFacility: handoverDateByCommonFacility,
          handoverDateByUnit: handoverDateByUnit,
          spaPercent: spaPercent,
          startWith: startWith,
          unitLadPercent: unitLadPercent,
          units: units,
          taxSchemeId: taxSchemeId,
          commonFacility: commonFacility,
        },
      },
    })
  }

  const {
    loading: projectLadLoading,
    data: { getProjectLadByProjectId: projectLad } = { projectLad: null },
  } = useGetProjectLadByProjectIdQuery({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      calculateValue(
        editData?.startWith,
        editData?.handoverDateByUnit,
        editData?.handoverDateByCommonFacility,
        data?.getProjectLadByProjectId?.unitLadPercent,
        data?.getProjectLadByProjectId?.spaPercent,
        data?.getProjectLadByProjectId?.unitDuration,
        data?.getProjectLadByProjectId?.facilityLadDuration,
        data?.getProjectLadByProjectId?.payoutPercent,
        data?.getProjectLadByProjectId?.facilityLadPercent,
        editData?.unitList?.map(x => x?.unitId),
        data?.getProjectLadByProjectId?.taxSchemeId,
        editData?.commonFacility
      )
    },
  })

  // Add LAD start
  const [addLad, { loading: addLadLoading }] = useAddLadMutation({
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        setSnackBar(message)
      })
    },
    onCompleted: data => {
      if (data?.addLad) {
        setSnackBar(SystemMsgs.createNewRecord())
        setTimeout(() => {
          navBack(null, 2)
        }, 500)
      }
    },
  })
  // Add LAD end

  const postLADDialog = (
    <Dialog
      fullWidth
      open={postDialog}
      onClose={() => {
        setPostDialog(false)
      }}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Post LAD
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <>
          <DatePickerX
            control={control}
            name="documentDate"
            label="Document Date"
            required={!editData?.isSimulate}
            error={!!errors.documentDate}
            helperText={errors.documentDate?.message}
            minDate={new Date(editData?.handoverDateByUnit)}
          />
        </>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => {
              setPostDialog(false)
            },
          },
          {
            children: 'Confirm',
            color: 'primary',
            onClick: () => {
              handleSubmit(onSubmit)()
            },
          },
        ],
      }}
    />
  )

  //#region project detail API
  const {
    loading: curProjectLoading,
    error: curProjectError,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })
  //#endregion

  //#region header/subheader
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
            // navigate(`/${projectID}/lad/lad-processing/add`, {
            //   state: editData,
            // }),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 3,
            current: 'Generate LAD Result',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])
  //#endregion

  const onSubmit = data => {
    return false
    if (calculateLADGenerateValue?.LADGenerateListing?.length > 0) {
      addLad({
        variables: {
          input: {
            startWith: editData.startWith,
            unitLadPercent: projectLad.unitLadPercent,
            unitDuration: projectLad.unitDuration,
            payoutPercent: projectLad.payoutPercent,
            spaPercent: projectLad.spaPercent,
            commonFacility: projectLad.commonFacility,
            facilityLadPercent: projectLad.facilityLadPercent ?? null,
            facilityLadDuration: projectLad.facilityLadDuration ?? null,
            byUnitDate: editData.handoverDateByUnit,
            byFacilityDate: editData.handoverDateByCommonFacility ?? null,
            isSimulate: editData.isSimulate,
            contraDate: data?.documentDate,
            taxSchemeId:
              editData.isSimulate == false ? editData.taxSchemeId : null,
            ladUnitDetails: calculateLADGenerateValue?.LADGenerateListing?.map(
              x => {
                return {
                  saleId: x.saleId,
                  unitId: x.unitId,
                  unitNo: x.unitNo,
                  unitLadAmount: x.actualUnitLADAmount,
                  unitDueDays: x.unitDuesDay,
                  facilityLadAmount:
                    x.actualFacilityLADAmount == 0
                      ? null
                      : x.actualFacilityLADAmount,
                  facilityDueDays:
                    x.facilityDuesDay == 0 ? null : x.facilityDuesDay,
                  totalTaxAmount: x.totalTaxAmount,
                  totalUnitFacilityAmountWithoutTax:
                    x.totalUnitFacilityAmountWithoutTax,
                }
              }
            ),
          },
        },
      })
    } else {
      setSnackBar('No record to post')
    }
  }

  return (
    <>
      {postLADDialog}
      <SubContainerX>
        <Subheader>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box width="100%" display="flex" margin="2px 0px">
                  <Typography
                    flex="1 1"
                    component="span"
                    variant="inherit"
                    className="text-icon text-xsTitle"
                    color="common.black"
                  >
                    <CalendarMonthRounded />
                    {format(new Date(), 'dd MMMM yyyy')}
                  </Typography>
                  <Typography
                    component="span"
                    variant="inherit"
                    className="text-icon text-desc"
                    color="primary"
                  >
                    <MonetizationOnRounded />
                    <NumberFormat
                      value={
                        calculateLADGenerateValue?.totalAllUnitFacilityAmount ??
                        0
                      }
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                    />
                  </Typography>
                </Box>
              </Box>
            }
            secondary={
              <Typography component="div" display="flex" variant="inherit">
                <Typography
                  component="span"
                  className="text-icon text-desc"
                  variant="inherit"
                  flex="1"
                >
                  <HouseOutlined />
                  {calculateLADGenerateValue?.LADGenerateListing?.length}
                  &emsp;&emsp;
                  <MonetizationOnRounded />
                  <NumberFormat
                    value={calculateLADGenerateValue?.totalUnitLadAmount}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                  &emsp;&emsp;
                  {'Facilities'}&nbsp;
                  <MonetizationOnRounded />
                  <NumberFormat
                    value={calculateLADGenerateValue?.totalFacilityLadAmount}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                </Typography>
              </Typography>
            }
          />
        </Subheader>
      </SubContainerX>
      <Container>
        <List>
          {calculateLADGenerateValue === undefined ||
          calculateLADGenerateValue?.LADGenerateListing?.length === 0 ? (
            <EmptyState subTitle="No record found" />
          ) : (
            calculateLADGenerateValue?.LADGenerateListing?.map(v => {
              return (
                <ListItem>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        component="div"
                        display="flex"
                        variant="inherit"
                        color="common.black"
                      >
                        <Typography
                          component="span"
                          className="text-xsTitle"
                          variant="inherit"
                          flex="1"
                        >
                          {v?.unitNo}
                        </Typography>
                        <Typography
                          component="span"
                          variant="inherit"
                          color="primary"
                          className="text-icon text-desc"
                        >
                          <MonetizationOnRounded />
                          <NumberFormat
                            value={v?.totalUnitFacilityAmount}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                          />
                        </Typography>
                      </Typography>
                    }
                    secondary={
                      <Typography
                        component="div"
                        display="flex"
                        variant="inherit"
                      >
                        <Typography
                          component="span"
                          className="text-icon text-desc"
                          variant="inherit"
                          flex="1"
                        >
                          <HouseOutlined />
                          <MonetizationOnRounded />
                          <NumberFormat
                            value={v?.actualUnitLADAmount}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                          />
                          &nbsp;|&nbsp;{v?.unitDuesDay}d
                        </Typography>
                        <Typography
                          component="span"
                          variant="inherit"
                          className="text-icon text-desc"
                        >
                          {'Facilities'}&nbsp;
                          <MonetizationOnRounded />
                          <NumberFormat
                            value={v?.actualFacilityLADAmount}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                          />
                          &nbsp;|&nbsp;{v?.facilityDuesDay}d
                        </Typography>
                      </Typography>
                    }
                  />
                </ListItem>
              )
            })
          )}
        </List>
      </Container>
      <Footer
        buttons={
          editData?.isSimulate
            ? [
                {
                  children: 'Close',
                  onClick: () => {
                    navBack(null, 2)
                  },
                  color: 'primary',
                },
                {
                  children: 'Save',
                  onClick: () => handleSubmit(onSubmit)(),
                  color: 'primary',
                },
              ]
            : [
                {
                  children: 'Cancel',
                  onClick: () => {
                    navBack()
                  },
                  color: 'primary',
                },
                {
                  children: 'Post',
                  onClick: () => {
                    setPostDialog(true)
                  },
                  color: 'primary',
                },
              ]
        }
      />
    </>
  )
}
export default LADProcessingGenerateResult
