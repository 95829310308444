import AppContext, { AppContextProps } from "containers/context/Context";
import { useEffect, useState, useRef, useContext } from "react";
import { useLocation, useParams } from "react-router";
import { Container, SubHeaderContainer, Tab, Tabs } from "@ifca-ui/core"
import { TopSectionHeader } from "components/Header/HeaderSection";
import { useProjectDetailQuery } from "generated/graphql";
import { Box, Typography } from "@mui/material";
import ByDocType from "./tabs/ByDocType";
import ByUnit from "./tabs/ByUnit";
import Import from "./tabs/Import";
import useNav from "components/RouteService/useNav";

const StatutoryDocumentsListing = () => {
    const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
    const { projectID } = useParams()
    const subHeaderRef = useRef(null)
    const { navTo } = useNav()
    const [tabIndex, setTabIndex] = useState(0)
    const location = useLocation()

    // --> Get Project Detail API <--
    const { data: { projectDetail } = { projectDetail: null } } = useProjectDetailQuery({
        variables: { id: projectID }
    })

    // --> Subheader <--
    useEffect(() => {
        if (subHeaderRef.current?.clientHeight) {
            rootDispatch({
                type: 'subHeaderHeight',
                payload: subHeaderRef.current?.clientHeight
            })
        }
        rootDispatch({
            type: 'headerComponent',
            payload: {
                ...rootState.headerComponent,
                leftIcon: {
                    icon: 'back',
                    props: {
                        onClick: () => navTo(`/${projectID}`),
                    }
                },
                rightIcon: {},
                topSection: {
                    smTitle: TopSectionHeader,
                    title: `${projectDetail?.name ?? '-'}`,
                },
                bottomSection: {
                    breadcrumbs: {
                        maxItems: 2,
                        current: 'Statutory Documents',
                    },
                },
            }
        })
    }, [projectDetail])

    // --> Tabs <--

    const tabs = [
        { label: 'By Doc Type', Component: ByDocType },
        { label: 'By Unit', Component: ByUnit },
        { label: 'Import', Component: Import }
    ]

    const renderTabs = (index: number, props: any = {}) => {
        const Component = tabs[index].Component
        return (
            <Component />
        )
    }

    // --> Page Render <--
    return (
        <>
            <SubHeaderContainer
                id="subheader-container"
                ref={subHeaderRef}
                style={{ fontSize: 'smaller', zIndex: 150 }}
            >
                <Box width="100%" marginTop="4px"></Box>
                <Tabs
                    className="bill-item-tabs"
                    value={tabIndex}
                    onChange={(e, v) => setTabIndex(v)}
                >
                    {
                        tabs.map((tab, i) => (
                            <Tab
                                key={i}
                                value={i}
                                // style={{ flex: 1 }}
                                style={{ flex: 1, maxWidth: '33.33%' }}
                                label={
                                    <Typography className="text-mdLabel">
                                        {tab.label}
                                    </Typography>
                                }
                            />
                        ))
                    }
                </Tabs>
            </SubHeaderContainer>
            <Box height={`${rootState?.subHeaderHeight}px`} />
            <Container bottomSpacing="lg" zIndex={1}>
                <Box>{renderTabs(tabIndex)}</Box>
            </Container>

        </>
    )
}

export default StatutoryDocumentsListing