import { RouteObject } from 'react-router'
import { lazy } from 'react'

const PAIndoDebitNoteDetail = lazy(() => import('./PAIndoDebitNoteDetail'))
const PAIndoDebitNoteForm = lazy(() => import('./PAIndoDebitNoteForm'))
const PAIndoDebitNoteListing = lazy(() => import('./PAIndoDebitNoteListing'))

//PA = purchaser's accounts
const PAIndoDebitNoteRoutes: RouteObject[] = [
  {
    path: 'debit-note',
    children: [
      {
        path: '',
        element: (
          <PAIndoDebitNoteListing tableRef="debit_note" path="debit-note" />
        ),
      },
      {
        path: ':id/view',
        element: <PAIndoDebitNoteDetail tableRef="debit_note" path="debit-note" />,
      },
      {
        path: 'add',
        element: <PAIndoDebitNoteForm mode="add" tableRef="debit_note" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoDebitNoteForm mode="edit" tableRef="debit_note" />,
      },
    ],
  },
]

export default PAIndoDebitNoteRoutes
//
