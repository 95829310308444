import { lazy } from 'react'
import { RouteObject } from 'react-router'
const UnitStatutoryTable = lazy(() => import('./UnitStatutoryTable'))
const UnitStatutoryEdit = lazy(() => import('./UnitStatutoryEdit'))

const unitStatutoryRoutes: RouteObject[] = [
  {
    path: 'unit-statutory-document-requirements',
    element: <UnitStatutoryTable />,
  },
  {
    path: 'unit-statutory-document-requirements/edit',
    element: <UnitStatutoryEdit />,
  },
]

export default unitStatutoryRoutes
