import { RouteObject } from 'react-router'
import { lazy } from 'react'

const PAIndoPaymentDetail = lazy(() => import('./PAIndoPaymentDetail'))
const PAIndoPaymentForm = lazy(() => import('./PAIndoPaymentForm'))
const PAIndoPaymentListing = lazy(() => import('./PAIndoPaymentListing'))
const PAIndoPaymentApprovedAllocation = lazy(
  () => import('./PAIndoPaymentApprovedAllocation')
)
const PAIndoPaymentAllocationForm = lazy(
  () => import('./PAIndoPaymentAllocationForm')
)

//PA = purchaser's accounts
const PAIndoPaymentRoutes: RouteObject[] = [
  {
    path: 'payment',
    children: [
      {
        path: '',
        element: (
          // <PAIndoPaymentListing tableRef="payment" path="payment" />
          <PAIndoPaymentListing />
        ),
      },
      {
        path: ':id/view',
        element: <PAIndoPaymentDetail tableRef="payment" path="payment" />,
      },
      {
        path: 'add',
        element: <PAIndoPaymentForm mode="add" tableRef="payment" />,
      },
      {
        path: ':id/edit',
        element: <PAIndoPaymentForm mode="edit" tableRef="payment" />,
      },
      {
        path: ':id/allocation',
        children: [
          {
            path: '',
            element: <PAIndoPaymentApprovedAllocation tableRef="payment" />,
          },
          {
            path: 'add',
            element: <PAIndoPaymentAllocationForm mode="add" />,
          },
          {
            path: ':debitId/edit',
            element: <PAIndoPaymentAllocationForm mode="edit" />,
          },
        ],
      },
    ],
  },
]

export default PAIndoPaymentRoutes
//
