import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const PromotionalPackageListing = lazy(
  () => import('./PromotionalPackageListing')
)
const PromotionalPackageForm = lazy(() => import('./PromotionalPackageForm'))
const PromotionalPackageDetailsListing = lazy(
  () => import('./PromotionalPackageDetailsListing')
)

export const PROMOTIONALPACKAGES_PATH = {
  PROMOTIONAL_PACKAGES_LISTING: '/project/:projectID/promotional-packages',
  PROMOTIONAL_PACKAGES_ADD: '/project/:projectID/promotional-packages/add',
  PROMOTIONAL_PACKAGES_DETAILS_LISTING:
    '/project/:projectID/promotional-packages/detailsListing',
  PROMOTIONAL_PACKAGES_EDIT:
    '/project/:projectID/promotional-packages/:id/edit',
}

const promotionalPackageRoutes: RouteObject[] = [
  {
    path: 'promotional-packages',
    children: [
      {
        path: '',
        element: <PromotionalPackageListing />,
      },
      {
        path: 'add',
        children: [
          {
            path: '',
            element: <PromotionalPackageForm mode="add" />,
          },
        ],
      },
      {
        path: ':id/edit',
        children: [
          {
            path: '',
            element: <PromotionalPackageForm mode="edit" />,
          },
        ],
      },
      {
        path: ':id/view',
        children: [
          {
            path: '',
            element: <PromotionalPackageDetailsListing />,
          },
        ],
      },
    ],
  },
]

export default promotionalPackageRoutes
