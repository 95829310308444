import React, { lazy } from 'react'
import { RouteObject } from 'react-router'
// import ProjectClusterListing from './ProjectClusterListing'

const ProjectClusterListing = lazy(() => import('./ProjectClusterListing'))

export const PROJECTCLUSTER_PATH = {
  PROJECTCLUSTER_LISTING: '/project/:projectID/project-cluster',
}

const projectClusterRoutes: RouteObject[] = [
  {
    path: 'project-cluster',
    element: <ProjectClusterListing />,
  },
]

export default projectClusterRoutes
