import { lazy } from 'react'
import { RouteObject } from 'react-router'

const PAIndoLedgerContraListing = lazy(
  () => import('./PAIndoLedgerContraListing')
)
const PAIndoLedgerContraDetails = lazy(
  () => import('./PAIndoLedgerContraDetails')
)
const PAIndoLedgerContraAllocation = lazy(
  () => import('./PAIndoLedgerCotraAllocation')
)
const PaIndoLedgerContraAllocationForm = lazy(
  () => import('./AllocationForm/PaIndoLedgerContraAllocationForm')
)

const ContraRoutes: RouteObject[] = [
  {
    path: ':saleID/contra',
    children: [
      {
        path: '',
        element: <PAIndoLedgerContraListing />,
      },
      {
        path: ':id/view',
        element: <PAIndoLedgerContraDetails />,
      },
      {
        path: ':id/allocation',
        children: [
          {
            path: '',
            element: <PAIndoLedgerContraAllocation />,
          },
          {
            path: 'add',
            element: <PaIndoLedgerContraAllocationForm mode="add" />,
          },
        ]
      },
    ],
  },
]

export default ContraRoutes
