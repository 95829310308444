import { GridX } from '../GridX'
import {
  AlignItemsEnum,
  JustifyContentEnum,
  OverflowEnum,
  verticalAlignEnum,
} from '../LayoutXenum'

type ColumnGridProps = {
  children?: any
  className?: string
  crossAxisAlignment?: AlignItemsEnum
  fullWidth?: boolean
  gap?: string
  gridTemplateRows: string
  height?: string
  mainAxisAlignment?: JustifyContentEnum
  overflow?: OverflowEnum
  verticalAlign?: verticalAlignEnum
}

export const ColumnGrid = ({
  children,
  className,
  crossAxisAlignment,
  fullWidth,
  gap,
  gridTemplateRows,
  height,
  mainAxisAlignment,
  overflow,
  verticalAlign,
}: ColumnGridProps) => {
  return (
    <GridX
      children={children}
      className={className}
      crossAxisAlignment={crossAxisAlignment}
      direction="column"
      fullWidth={fullWidth}
      gap={gap}
      gridTemplate={gridTemplateRows}
      height={height}
      mainAxisAlignment={mainAxisAlignment}
      overflow={overflow}
      verticalAlign={verticalAlign}
    />
  )
}
