import {
  Container,
  EmptyState,
  List,
  ListItem,
  Menu,
  SearchBar,
  SubHeaderContainer,
} from '@ifca-ui/core'
import { KeyboardArrowRight } from '@mui/icons-material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import ColumnFlex from 'components/Layout/ColumnLayout'
import { RowFlex } from 'components/Layout/RowLayout'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useGetCollectionActivityIndoQuery,
  useProjectDetailQuery,
} from 'generated/graphql'

import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/formatDate'
import React, { useContext, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import PersonIcon from '@mui/icons-material/Person'
import useNav from 'components/RouteService/useNav'
import NumberFormat from 'react-number-format'
import EventIcon from '@mui/icons-material/Event'
interface data {
  date: any
}

const CollectionActivityListing = (props: any) => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const { navBack, navTo } = useNav()

  const { projectID, saleID }: any = useParams()
  const subHeaderRef = useRef(null)
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const [search, setSearchInput] = React.useState('')
  const [filter, setFilter] = React.useState([])
  const [selectedDate, setSelectedDate] = React.useState(null)
  const [originalList, setOriginalList] = React.useState([])
  const datePickerRef = useRef(null)
  const [open, setOpen] = React.useState(false)

  const handleIconClick = () => {
    setOpen(true)
  }

  useEffect(() => {
    if (open == true) {
      setSelectedDate(null)
      filterListing(null)
    }
  }, [open])

  const handleDateChange = date => {
    setSelectedDate(date)
    filterListing(date)
    // setOpen(false)
  }

  const filterListing = date => {
    setOriginalListing(originalList)
    let filtered = []
    if (date) {
      setOriginalListing([])
      if (!isNaN(date)) {
        originalList.forEach(x => {
          if (
            new Date(x?.assignedDate).toLocaleString().slice(0, 10) ==
            new Date(date).toLocaleString().slice(0, 10)
          )
            filtered.push(x)
        })
        setOriginalListing(filtered)
      }
    }
  }

  //#region
  const {
    loading,
    error,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })

  const {
    refetch,
    loading: collectionLoading,
    error: collectionError,
    data: { getCollectionActivityIndo } = {
      getCollectionActivityIndo: [],
    },
  } = useGetCollectionActivityIndoQuery({
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setFilter(data?.getCollectionActivityIndo)
      setOriginalList(data?.getCollectionActivityIndo)
    },
  })
  //#endregiongetCollectionActivityBySaleId

  useEffect(() => {
    setOriginalListing(filter)
  }, [filter])

  //#region header
  useEffect(() => {
    if (subHeaderRef.current?.clientHeight) {
      rootDispatch({
        type: 'subHeaderHeight',
        payload: subHeaderRef.current?.clientHeight,
      })
    }
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navTo(`/${projectID}`),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 3,
            current: 'Collection Activity',
          },
        },
      },
    })
  }, [projectDetail])
  //#endregion
  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors, isSubmitted },
  } = useForm<data>({
    defaultValues: {
      date: new Date(),
    },
    mode: 'onSubmit',
  })
  const [value, setValue] = React.useState(null)

  // useEffect(() => {}, [filterDate])

  return (
    <>
      <SubHeaderContainer id="subheader-container" ref={subHeaderRef}>
        <RowFlex width="100%" mainAxisAlignment="SPACE_BETWEEN">
          <RowFlex width="100%">
            <SearchBar
              isOption={true}
              option={{
                props: {
                  onClick: () => setOpen(true),
                  children: <EventIcon style={{ alignItems: 'center' }} />,
                },
              }}
              title={
                <Typography
                  component="span"
                  className="text-mdLabel"
                  variant="inherit"
                >
                  {`Collection Activities`} (
                  <Typography
                    component="span"
                    variant="inherit"
                    className="search-bar-count-f"
                  >
                    {filteredList?.length ?? 0}
                  </Typography>
                  )
                </Typography>
              }
              searchInput={{
                value: search,
                onChange: e => {
                  setSearchInput(e.target.value)
                  handleSearch(e.target.value, [
                    'fullName',
                    'unitNo',
                    'assignName',
                    'assignedDate',
                    'totalDocumentAmount',
                    'totalOutstanding',
                  ])
                },
                onChangeClearInput: () => {
                  setSearchInput('')
                  handleSearch('', [
                    'fullName',
                    'unitNo',
                    'assignName',
                    'assignedDate',
                    'totalDocumentAmount',
                    'totalOutstanding',
                  ])
                },
              }}
            />
          </RowFlex>
          {/* <RowFlex mainAxisAlignment='FLEX_END' >
          <Box
            style={{
              width:'100%',
              zIndex: 100,
              background: '#fff',
              boxShadow:
                '0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1)',
            }}
          > */}
          {/* <IconButton onClick={() => setOpen(true)}>
              <EventIcon style={{alignItems:'center'}}/>
            </IconButton> */}
          <MobileDatePicker
            value={selectedDate}
            onChange={handleDateChange}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            renderInput={params => <div />}
          />
          {/* </Box></RowFlex> */}
        </RowFlex>
      </SubHeaderContainer>
      <Box height={`${rootState?.subHeaderHeight}px`} />
      <>
        <Container bottomSpacing="lg">
          <List>
            {filteredList === undefined || filteredList?.length === 0 ? (
              <EmptyState subTitle="Click add button to insert new records" />
            ) : (
              filteredList?.map((x, i) => (
                <ListItem
                  secondaryAction={
                    <IconButton
                      onClick={() => {
                        navTo(
                          `/${projectID}/credit-control-indo/collection-activity/${x.saleId}/${x.overduedPurchaserAccountId}/detail`
                        )
                      }}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  }
                  onClick={() => {
                    navTo(
                      `/${projectID}/credit-control-indo/collection-activity/${x.saleId}/${x.overduedPurchaserAccountId}/detail`
                    )
                  }}
                >
                  <ColumnFlex width="100%" gap="2px">
                    <RowFlex width="100%" mainAxisAlignment="SPACE_BETWEEN">
                      <Typography className="text-xsTitle">
                        {x.fullName}
                      </Typography>
                      <Typography className="text-xxTitle" color="primary">
                        <NumberFormat
                          displayType="text"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          thousandSeparator={true}
                          value={x.totalDocumentAmount}
                        />
                      </Typography>
                    </RowFlex>
                    <RowFlex width="100%" mainAxisAlignment="SPACE_BETWEEN">
                      <Typography className="text-xsTitle">
                        {x.unitNo}
                      </Typography>
                      <Typography className="text-xxTitle" color="secondary">
                        <NumberFormat
                          displayType="text"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          thousandSeparator={true}
                          value={x.totalOutstanding}
                        />
                      </Typography>
                    </RowFlex>
                    <RowFlex width="100%" gap="5px">
                      <Typography color="#00e6ac" className="text-xxTitle">
                        {/* {formatDate(x.dueDate)} */}
                        {x?.activityDate
                          ? formatDate(x.activityDate)
                          : formatDate(x.assignedDate)}
                      </Typography>
                      <Typography color="#00e6ac" className="text-xxTitle">
                        {x?.activityDate
                          ? formatTime(x.activityDate)
                          : formatTime(x.assignedDate)}
                      </Typography>
                      {/* <Typography color="#00e6ac" className="text-xxTitle">
                    {x.time}
                  </Typography>
                  <Typography color="#00e6ac" className="text-xxTitle">
                    {x.desc}
                  </Typography> */}
                    </RowFlex>
                    <RowFlex width="100%" gap="5px">
                      <PersonIcon style={{ fontSize: '12px' }} />
                      <Typography className="text-xxTitle">
                        {x.assignName}
                      </Typography>
                    </RowFlex>
                  </ColumnFlex>
                </ListItem>

                //   <ListItem
                //     secondaryAction={
                //       <IconButton
                //         onClick={() => {
                //           navTo(
                //             `/${projectID}/credit-control-indo/collection-activity/${x.saleId}/detail`,
                //             saleID
                //           )
                //         }}
                //       >
                //         <KeyboardArrowRight />
                //       </IconButton>
                //     }
                //     onClick={() => {
                //       navTo(
                //         `/${projectID}/credit-control-indo/collection-activity/${x.saleId}/detail`
                //       )
                //     }}
                //   >
                //     <ColumnFlex width="100%">
                //       <RowFlex mainAxisAlignment="SPACE_BETWEEN">
                //         <RowFlex gap="30px">
                //           <Typography
                //             className="text-xsTitle"
                //             variant="inherit"
                //             color="common.black"
                //           >
                //             {formatTime(x?.assignedDate)}
                //           </Typography>
                //           <Typography
                //             className="text-xsTitle"
                //             variant="inherit"
                //             color="common.black"
                //           >
                //             {formatDate(x?.assignedDate)}
                //           </Typography>
                //         </RowFlex>
                //         <Typography
                //           className="text-xsTitle"
                //           variant="inherit"
                //           color="#ff3455"
                //         >
                //           <NumberFormat
                //             value={x?.totalOutstanding}
                //             displayType="text"
                //             thousandSeparator={true}
                //             fixedDecimalScale={true}
                //             decimalScale={2}
                //           />
                //         </Typography>
                //       </RowFlex>
                //       <RowFlex gap="10px">
                //         <PersonIcon style={{ fontSize: '14px' }} />
                //         <Typography
                //           className="text-desc"
                //           variant="inherit"
                //           color="common.black"
                //         >
                //           {x?.clusterName}/{x?.unitNo}
                //         </Typography>
                //         <Typography
                //           className="text-desc"
                //           variant="inherit"
                //           color="common.black"
                //         >
                //           {x?.purchaserName}
                //         </Typography>
                //       </RowFlex>

                //       <RowFlex gap="4px">
                //         <Typography
                //           className="text-desc"
                //           variant="inherit"
                //           color="#ff3455"
                //         >
                //           {x?.remark}
                //         </Typography>
                //       </RowFlex>
                //       <RowFlex gap="10px">
                //         <Typography
                //           className="text-desc"
                //           variant="inherit"
                //           color="#FF9800"
                //         >
                //           {'Last Receipt '}
                //         </Typography>
                //         <Typography
                //           className="text-xsTitle"
                //           variant="inherit"
                //           color="#FF9800"
                //         >
                //           {formatDate(x?.receiptDate)}
                //         </Typography>
                //         <Typography
                //           className="text-xsTitle"
                //           variant="inherit"
                //           color="#FF9800"
                //         >
                //           <NumberFormat
                //             value={x?.receiptAmount}
                //             displayType="text"
                //             thousandSeparator={true}
                //             fixedDecimalScale={true}
                //             decimalScale={2}
                //           />
                //         </Typography>
                //       </RowFlex>
                //     </ColumnFlex>
                //   </ListItem>
              ))
            )}
          </List>
        </Container>
      </>
    </>
  )
}

export default CollectionActivityListing
