import { ColumnFlex, Expansion, ExpansionSummary } from '@ifca-ui/core'
import { Typography } from '@mui/material'
import { useConfirmDialog } from 'components/Dialogs/useConfirmDialog'
import { CardListHeader } from 'components/General/CardListItem'
import useAppData from 'components/General/useAppData'
import { useState } from 'react'
import { useReversalPB } from './PAprogressBillingBloc'
import PBexpansionDetails from './PBexpansionDetails'
import PBexpansionSummary from './PBexpansionSummary'

interface Props {
  isPA: boolean
  pbListData: any
  refetchData: any
}

export const PBsaleProductList = ({ isPA, pbListData, refetchData }: Props) => {
  const { saleID, navTo, projectID } = useAppData()
  const { reversalData } = useReversalPB(saleID, refetchData)
  const { showConfirmDialog, ConfirmDialogX } = useConfirmDialog()
  const [reversalId, setReversalId] = useState([])
  const filteredData = pbListData.filter(item => !item.isReversal)
  const lastIndex = filteredData.length - 1
  return (
    <>
      <ConfirmDialogX
        title={'Progress Billing'}
        bodyText={'Are you sure want to reversal?'}
        rightTopTitle={'Reversal'}
        // id={reversalId}
        // deleteAction={reversalData}
        action={() => {
          reversalData(reversalId)
        }}
      />
      {pbListData.length >= 1 && (
        <ColumnFlex>
          <CardListHeader
            title={
              <Typography
                className="text-xsTitle"
                color="#FF9800"
                component="span"
              >
                Sales Product
              </Typography>
            }
          />
          <ColumnFlex gap={'5px'}>
            {pbListData.map((item, index) => {
              const haveSubData = item.details?.length > 1

              const handleClick = () => {
                if (!haveSubData) {
                  const path = isPA
                    ? `/${projectID}/purchaser-accounts/${saleID}/progress-billing/${item.details[0].progressBillingId}/view`
                    : `/${projectID}/sales-contracts/${saleID}/submenu/progress-billing/${item.details[0].progressBillingId}/view`

                  navTo(path, item.details[0])
                }
              }

              const handleDelete = e => {
                e.stopPropagation()
                if (item.allow_reversal && !item.isReversal && isPA) {
                  const reversalIds = item.details
                    .map(data => data.progressBillingId)
                    .filter(Boolean)
                  setReversalId(reversalIds)
                  showConfirmDialog()
                }
              }

              return (
                <Expansion key={index} disabled={item.isReversal}>
                  <ExpansionSummary
                    className="clickable-item"
                    style={{ width: '100%' }}
                    onClick={handleClick}
                  >
                    <PBexpansionSummary
                      item={item}
                      haveSubData={haveSubData}
                      isPA={isPA}
                      handleDelete={handleDelete}
                      lastIndex={lastIndex}
                      index={index}
                    />
                  </ExpansionSummary>
                  {haveSubData && <PBexpansionDetails item={item} />}
                </Expansion>
              )
            })}
          </ColumnFlex>
        </ColumnFlex>
      )}
    </>
  )
}
