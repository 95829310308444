import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const RescheduledInstallmentApprovalSubmittedListing = lazy(
  () => import('./RescheduledInstallmentApprovalSubmittedListing')
)
const RescheduledInstallmentApprovalSubmittedDetails = lazy(
  () => import('./RescheduledInstallmentApprovalSubmittedDetails')
)
const RescheduledInstallmentApprovalApprovedListing = lazy(
  () => import('./RescheduledInstallmentApprovalApprovedListing')
)
const RescheduledInstallmentApprovalApprovedDetails = lazy(
  () => import('./RescheduledInstallmentApprovalApprovedDetails')
)

export const RESCHEDULED_INSTALLMENT_APPROVAL_PATH = {
  RESCHEDULED_INSTALLMENT_APPROVAL_SUBMITTED_LISTING:
    '/:projectID/rescheduled-installment-approval-submitted-listing',
  RESCHEDULED_INSTALLMENT_APPROVAL_SUBMITTED_DETAILS:
    '/:projectID/rescheduled-installment-approval-submitted-listing/details',
  RESCHEDULED_INSTALLMENT_APPROVAL_APPROVED_LISTING:
    '/:projectID/rescheduled-installment-approval-approved-listing',
  RESCHEDULED_INSTALLMENT_APPROVAL_APPROVED_DETAILS:
    '/:projectID/rescheduled-installment-approval-approved-listing/details',
}

const RescheduledInstallmentApprovalRoutes: RouteObject[] = [
  {
    path: '',
    children: [
      {
        path: 'rescheduled-installment-approval-submitted-listing',
        element: <RescheduledInstallmentApprovalSubmittedListing />,
      },
      {
        path: 'rescheduled-installment-approval-submitted-listing/details',
        element: <RescheduledInstallmentApprovalSubmittedDetails />,
      },
      {
        path: 'rescheduled-installment-approval-approved-listing',
        element: <RescheduledInstallmentApprovalApprovedListing />,
      },
      {
        path: 'rescheduled-installment-approval-approved-listing/details',
        element: <RescheduledInstallmentApprovalApprovedDetails />,
      },
    ],
  },
]

export default RescheduledInstallmentApprovalRoutes
