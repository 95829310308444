import React from 'react'

enum JustifyContentEnum {
  CENTER = 'center',
  FLEX_END = 'flex-end',
  FLEX_START = 'flex-start',
  SPACE_AROUND = 'space-around',
  SPACE_BETWEEN = 'space-between',
  SPACE_EVENLY = 'space-evenly',
}

enum AlignItemsEnum {
  BASELINE = 'baseline',
  CENTER = 'center',
  FLEX_END = 'flex-end',
  FLEX_START = 'flex-start',
  NORMAL = 'normal',
  STRETCH = 'stretch',
}

interface RowProps {
  children?: React.ReactNode
  className?: string
  crossAxisAlignment?: keyof typeof AlignItemsEnum
  gap?: string
  gridTemplateColumns: string
  id?: string
  mainAxisAlignment?: keyof typeof JustifyContentEnum
  height?: string
  width?: string
}

/**
 * A grid layout component for arranging child elements in a row.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.id - The unique identifier for the component.
 * @param {string} props.className - The CSS class name for the component.
 * @param {string} props.gridTemplateColumns - The CSS grid template columns for the component.
 * @param {string} props.gap - The gap between grid cells for the component.
 * @param {string} props.mainAxisAlignment - The main axis alignment for the component.
 * @param {string} props.crossAxisAlignment - The cross axis alignment for the component.
 * @param {string} props.height - The height of the component.
 * @param {string} props.width - The width of the component.
 * @param {React.ReactNode} props.children - The child elements to be rendered within the component.
 * @returns {React.ReactNode} The rendered grid layout component.
 */
export const RowGrid: React.FC<RowProps> = React.memo(
  ({
    id,
    className,
    gridTemplateColumns,
    gap,
    mainAxisAlignment,
    crossAxisAlignment,
    height,
    width,
    children,
  }) => {
    return (
      <div
        className={className}
        id={id}
        key={id}
        style={{
          alignItems: AlignItemsEnum[crossAxisAlignment || ''],
          display: 'grid',
          gap,
          gridTemplateColumns,
          height,
          justifyContent: JustifyContentEnum[mainAxisAlignment || ''],
          width,
        }}
      >
        {children}
      </div>
    )
  }
)

// RowFlex
interface RowFlexProps {
  children?: React.ReactNode
  className?: string
  crossAxisAlignment?: keyof typeof AlignItemsEnum // Specifies the vertical alignment of flex items
  gap?: string // Specifies the gap between flex items
  height?: string
  id?: string
  mainAxisAlignment?: keyof typeof JustifyContentEnum // Specifies the horizontal alignment of flex items
  width?: string
}

/**
 * RowFlex component represents a flex container that arranges its children in a row.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.id - The unique identifier for the component.
 * @param {string} props.className - The CSS class name for the component.
 * @param {number} props.gap - The gap between the children elements.
 * @param {string} props.mainAxisAlignment - The alignment of the children along the main axis.
 * @param {string} props.crossAxisAlignment - The alignment of the children along the cross axis.
 * @param {string} props.height - The height of the component.
 * @param {string} props.width - The width of the component.
 * @param {ReactNode} props.children - The children elements to be rendered inside the component.
 * @returns {JSX.Element} The rendered RowFlex component.
 */
export const RowFlex: React.FC<RowFlexProps> = React.memo(
  ({
    id,
    className,
    gap,
    mainAxisAlignment,
    crossAxisAlignment,
    height,
    width,
    children,
  }) => {
    return (
      <div
        id={id}
        key={id}
        className={className}
        style={{
          alignItems: AlignItemsEnum[crossAxisAlignment || ''],
          display: 'flex',
          flexDirection: 'row',
          gap,
          height,
          justifyContent: JustifyContentEnum[mainAxisAlignment || ''],
          width,
        }}
      >
        {children}
      </div>
    )
  }
)
