// import LetterTemplateForm from 'containers/modules/CommonSetting/StandardELettering/LetterTemplate/LetterTemplateForm'
// import LTreportDesign from 'containers/modules/CommonSetting/StandardELettering/LetterTemplate/LTreportDesign'
import { lazy } from 'react'
import { RouteObject } from 'react-router'

const StandardLetterCategory = lazy(() => import('./StandardLetterCategory'))
const LetterTemplateListing = lazy(
  () => import('./LetterTemplate/ProjectLetterTemplateListing')
)
const ProjectLetterTemplateForm = lazy(
  () => import('./LetterTemplate/ProjectLetterTemplateForm')
)
const LetterTemplateForm = lazy(
  () =>
    import(
      'containers/modules/CommonSetting/StandardELettering/LetterTemplate/LetterTemplateForm'
    )
)
const LTreportDesign = lazy(
  () =>
    import(
      'containers/modules/CommonSetting/StandardELettering/LetterTemplate/LTreportDesign'
    )
)

const standardLetterRoutes: RouteObject[] = [
  {
    path: 'standard-letter',
    children: [
      {
        path: '',
        element: <StandardLetterCategory />,
      },
      {
        path: ':standardLetterId',
        children: [
          {
            path: '',
            element: <LetterTemplateListing />,
          },
          // {
          //   path: ':mode',
          //   element: <ProjectLetterTemplateForm />,
          // },
          {
            path: 'html-editor',
            element: <ProjectLetterTemplateForm />,
          },
          {
            path: 'report-designer',
            element: <LTreportDesign isCommonSetting={false} />,
          },
        ],
      },
    ],
  },
]

export default standardLetterRoutes
