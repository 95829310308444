import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const AdditionalPackagesInstallmentListing = lazy(
  () => import('./AdditionalPackagesInstallmentListing')
)
const AdditionalPackagesInstallmentDetail = lazy(
  () => import('./AdditionalPackagesInstallmentDetail')
)

const AdditionalPackagesInstallmentRoutes: RouteObject[] = [
  {
    path: ':saleID/additional-packages',
    children: [
      {
        path: '',
        element: <AdditionalPackagesInstallmentListing />,
      },
      {
        path: 'detail/:packageId',
        element: <AdditionalPackagesInstallmentDetail />,
      },
    ],
  },
]

export default AdditionalPackagesInstallmentRoutes
