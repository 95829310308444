import { Tab as MuiTab, type TabProps } from '@mui/material'

export interface tabProps extends TabProps {}

export const Tab = ({ ...props }: tabProps): JSX.Element => {
  return (
    <MuiTab
      {...props}
      sx={{
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 700,
        color: '#8a8a8a',
      }}
    />
  )
}
