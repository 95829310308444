import { Clear } from '@mui/icons-material'
import { MobileDatePicker, MobileDatePickerProps } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { useState } from 'react'
import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form'
import moment from 'moment'
import CalendarIcon from '@mui/icons-material/Event'

interface mobileDatePickerProps {
  name: string
  label?: string
  control: Control<any, any>
  required?: boolean
  error?: boolean
  helperText?: string
  showDelete?: boolean
  showToolbar?: boolean // show edit pen
  variant?: 'standard' | 'outlined' | 'filled'
  margin?: 'normal' | 'dense' | 'none'
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  style?: React.CSSProperties
  textFieldClassName?: string
  placeholder?: string
  onChange?: (date: Date) => void
}

interface DatePickerXProps<T extends FieldValues>
  extends Omit<MobileDatePickerProps<string, string>, 'onChange' | 'value' | 'renderInput'> {
  control: Control<T, any>
  name: FieldPath<T>
  label?: string
  required?: boolean
  renderedInputsProps?: TextFieldProps
  onDateChange?: (date: any) => void
}

export const DatePickerX = <T extends FieldValues = FieldValues>({
  control,
  name,
  label,
  required = false,
  renderedInputsProps,
  onDateChange,
  error = false,
  helperText = '',
  showDelete = false,
  showToolbar = true,
  variant = 'standard',
  margin = 'normal',
  minDate,
  maxDate,
  disabled = false,
  style,
  textFieldClassName,
  placeholder,
  onChange: customOnchange,
  ...datePickerProps
}: DatePickerXProps<T> & mobileDatePickerProps) => {

  const [num, setNum] = useState('0')
  const [tempDate, setTempDate] = useState<unknown>()
  const [open, setOpen] = useState(false)
  const openCalendar = () => setOpen(true)
  const closeCalendar = () => setOpen(false)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error: fieldError },
        }) => (
          <MobileDatePicker
            open={open}
            label={label}
            key={num}
            value={tempDate as string|| value} 
            inputFormat="dd/MM/yyyy"
            toolbarFormat="dd/MM/yyyy"
            // onChange={value => setTempDate(value)}
            onChange={date => {
              const formattedDate = moment(date, 'dd/MM/yyyy')
                .utcOffset('+0800')
                .format('MM/DD/yyyy')
              // console.log(formattedDate, 'dates')

              onChange(formattedDate)
              onDateChange && onDateChange(formattedDate)
              setTempDate(new Date(date))
            }}
            onAccept={value => {
              onChange(value)
              setTempDate(null)
              if (customOnchange) customOnchange(new Date(value))
            }}
            minDate={minDate}
            maxDate={maxDate}
            showToolbar={showToolbar}
            disabled={disabled}
            onClose={() => {
              setTempDate(null)
              closeCalendar()
            }}
            onOpen={() => {
              setTempDate(value)
              openCalendar()
            }}
            {...datePickerProps}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  autoComplete="off"
                  name={name}
                  className={textFieldClassName}
                  // onChange={_ => { }}
                  onBlur={onBlur}
                  value={value}
                  fullWidth
                  margin={margin}
                  variant={variant}
                  placeholder={placeholder}
                  required={required}
                  error={error} // can get fieldError without passing error
                  helperText={helperText}
                  disabled={disabled}
                  style={style}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {value != null && showDelete && (
                          <IconButton>
                            <Clear
                              onClick={e => {
                                e.stopPropagation()
                                setNum(Math.random().toString())
                                onChange(null)
                              }}
                            />
                          </IconButton>
                        )}
                        <IconButton onClick={openCalendar}>
                          <CalendarIcon sx={{width: 20}} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...renderedInputsProps}
                />
              )
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}