import { Card } from '@ifca-ui/core'
import { Box, Typography } from '@mui/material'
import {
  SalesDiscountDetailOutput,
  useGetSalesRegistrationDiscountByIdQuery,
  useProjectDetailQuery,
  useDiscardPendingDataMutation,
} from 'generated/graphql'
import NumberFormat from 'react-number-format'
import { useLocation, useParams } from 'react-router'
import { useContext, useEffect, useRef, useState } from 'react'
import AppContext, { AppContextProps } from 'containers/context/Context'
import useNav from 'components/RouteService/useNav'
import { TopSectionHeader } from 'components/Header/HeaderSection'

export const DiscountDetail = () => {
  let location = useLocation()
  const { projectID, saleId }: any = useParams()
  
  const data: any = location?.state
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const [discountDetail, setDiscountDetail] =
    useState<SalesDiscountDetailOutput>(null)

  const discountMethodList = [
    {
      id: 'DISCOUNT_ON_LISTPRICE',
      name: 'Discount on List Price',
    },
    {
      id: 'DISCOUNT_ON_DISCOUNTED_PRICE',
      name: 'Discount on Discounted Price',
    },
  ]

  //#region project API
  const { data: { projectDetail } = { projectDetail: null } } =
    useProjectDetailQuery({
      variables: {
        id: projectID,
      },
    })
  //#endregion

  const subHeaderRef = useRef(null)
  const { navBack, navTo } = useNav()
  // -- setup header start
  useEffect(() => {
    if (subHeaderRef.current?.clientHeight) {
      rootDispatch({
        type: 'subHeaderHeight',
        payload: subHeaderRef.current?.clientHeight,
      })
    }
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'close',
          props: {
            onClick: () => 
              navTo(
              `/${projectID}/sales-registrations/form/${saleId}/discount`,
              {
                ...location?.state,
                moduleChanges: data?.moduleChanges
              }
            ),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 4,
            current: 'Discount',
          },
          // rightText: mode === 'add' ? 'New' : 'Edit',
          current: true,
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])
  // -- setup header end

  useGetSalesRegistrationDiscountByIdQuery({
    variables: {
      id: data?.id,
    },
    onCompleted: data => {
      setDiscountDetail(data?.getSalesRegistrationDiscountById)
    },
  })

  const [discardChanges] = useDiscardPendingDataMutation()

  useEffect(() => {
    if (data?.moduleChanges?.length > 0) {
        const handleBeforeUnload = event => {
            discardChanges({
                variables: {
                    input: {
                        saleId,
                        changes: data?.moduleChanges,
                    },
                },
            });
            window.sessionStorage.setItem('isDiscard', 'true');
        };

        const handleOffline = () => {
            const discardData = {
                saleId,
                moduleChanges: data?.moduleChanges
            };
            window.localStorage.setItem('discardData', JSON.stringify(discardData));
        };

        const handleOnline = () => {
            window.localStorage.removeItem('discardData');
        };

        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }
  }, [data?.moduleChanges]);

  useEffect(() => {
    if (data?.moduleChanges?.length > 0 && window.sessionStorage.getItem('isDiscard')) {
      data?.moduleChanges.splice(0, data?.moduleChanges.length);
      window.sessionStorage.removeItem('isDiscard');
    }
  }, []);  

  return (
    <>
      {discountDetail && (
        <>
          <Box height={`${90}px`} />
          <Card className="card-container-detail">
            <Box width="100%" boxSizing="border-box" marginBottom="14px">
              <Typography className="label-f">Discount Name</Typography>
              <Typography className="value-f">
                {discountDetail?.title}
              </Typography>
            </Box>
            {discountDetail?.prefix !== '{FIXED_AMOUNT}' && (
              <Box width="100%" boxSizing="border-box" marginBottom="14px">
                <Typography className="label-f">Discount Method</Typography>
                <Typography className="value-f">
                  {
                    discountMethodList?.find(
                      el => el?.id === discountDetail?.method
                    )?.name
                  }
                </Typography>
              </Box>
            )}

            <Box width="100%" boxSizing="border-box" marginBottom="14px">
              <Typography className="label-f">Discount Amount</Typography>
              <Typography className="value-f">
                <NumberFormat
                  value={discountDetail?.amount}
                  displayType={'text'}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
              </Typography>
            </Box>
          </Card>
        </>
      )}
    </>
  )
}
